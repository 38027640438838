<form class="mat-card mat-elevation-z4 p-24 " fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form"
	[formGroup]="userFormGroup" #userForm="ngForm" (ngSubmit)="onSubmit(userForm)">
	<input type="hidden" formControlName="id">
	<input type="hidden" formControlName="companyId">
	<div class="h2 mb-24">{{title}}</div>
	<div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

		<mat-form-field appearance="outline" fxFlex="50" class="pr-4">
			<mat-label>{{ 'Headers.Name' | translate}}</mat-label>
			<input matInput formControlName="name" required>
			<mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
			<mat-error>{{ 'Headers.Name' | translate}} {{ 'Errors.Required' | translate}}</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" fxFlex="50" class="pl-4">
			<mat-label>{{ 'Headers.UserName' | translate}}</mat-label>
			<input matInput formControlName="userName" required>
			<mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
			<mat-error *ngIf="userFormGroup.hasError('notUnique','userName')">
				{{ 'Errors.UserNameTaken' | translate}}
			</mat-error>
			<mat-error *ngIf="userFormGroup.hasError('required','userName')">
				{{ 'Headers.UserName' | translate}} {{ 'Errors.Required' | translate}}
			</mat-error>
		</mat-form-field>

	</div>
	<div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
		<mat-form-field appearance="outline" fxFlex="50">
			<mat-label>{{ 'Headers.Phone' | translate}}</mat-label>
			<input matInput formControlName="phone" customTelNumber>
			<mat-icon matSuffix class="secondary-text">location_phone</mat-icon>
			<mat-error>{{ 'Errors.PhoneInvalid' | translate}}</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline" fxFlex="50" class="px-8">
			<mat-label>{{ 'Headers.Email' | translate}}</mat-label>
			<input matInput formControlName="email" required>
			<mat-icon matSuffix class="secondary-text">location_mail</mat-icon>
			<mat-error *ngIf="userFormGroup.hasError('notUnique','email')">
				{{ 'Errors.EmailTaken' | translate}}
			</mat-error>
			<mat-error *ngIf="userFormGroup.hasError('required','email')">
				{{ 'Headers.Email' | translate}} {{ 'Errors.Required' | translate}}
			</mat-error>
			<mat-error *ngIf="userFormGroup.hasError('notValid','email')">
				{{ 'Errors.EmailInvalid' | translate}}
			</mat-error>

		</mat-form-field>
	</div>
	<div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
		<mat-form-field appearance="outline" fxFlex="50">
			<mat-label>{{ 'Headers.Type' | translate}}</mat-label>
			<mat-select formControlName="type" required>
				<mat-option [value]="'User'">
					User
				</mat-option>
				<mat-option [value]="'Admin'">
					Admin
				</mat-option>
			</mat-select>
			<mat-icon matSuffix class="secondary-text">accessibility_new</mat-icon>
			<mat-error>{{ 'Headers.Type' | translate}} {{ 'Errors.Required' | translate}}</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" fxFlex="50" *ngIf='companies!=null' class="px-8">
			<mat-label>{{ 'Headers.Company' | translate}}</mat-label>
			<mat-select formControlName="companyId" required>
				<mat-option *ngFor="let company of companies; let i = index" [value]="company.id">
					{{company.name}}
				</mat-option>
			</mat-select>
			<mat-icon matSuffix class="secondary-text">accessibility_new</mat-icon>
			<mat-error>{{ 'Headers.Company' | translate}} {{ 'Errors.Required' | translate}}</mat-error>
		</mat-form-field>
	</div>
	<div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

	</div>
	<div fxLayout="row" fxLayoutAlign="center center">
		<a mat-raised-button color="primary" [routerLink]="'/user'" class="mx-8">
			{{ 'Buttons.Back' | translate}}
		</a>
		<button mat-raised-button type="button" color="secondary" class="mx-8" [disabled]="userFormGroup.invalid"
			(click)="validateUser()">
			{{ 'Buttons.Validate' | translate}}
		</button>
		<button mat-raised-button type="submit" color="primary" class="mx-8" [disabled]="userFormGroup.invalid">
			{{ 'Buttons.Save' | translate}}
		</button>
	</div>
</form>