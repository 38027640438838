import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import ExportInboxDataResponse from 'app/models/form-builder/export-inbox-data-response.model';
import ExportFormsDataRequest from 'app/models/form-submission/export-forms-data-request.model';
import { SecureUploadInboxService } from 'app/services/secure-upload-inbox.service';
import ExcelUtility from 'app/utilities/excel-utility';
import { environment } from 'environments/environment';

@Component({
	selector: 'app-export-input-data',
	templateUrl: './export-inbox-data.component.html',
	styleUrls: ['./export-inbox-data.component.scss']
})
export class ExportInboxDataComponent implements OnInit {

	exportFormGroup: UntypedFormGroup;
	isLoading = false;

	constructor(
		public matDialogRef: MatDialogRef<ExportInboxDataComponent>,
		private secureUploadInboxService: SecureUploadInboxService,
		private formBuilder: UntypedFormBuilder
	) { }

	ngOnInit(): void {
		this.exportFormGroup = this.formBuilder.group({
			startDate: ['', [Validators.required]],
			endDate: ['', [Validators.required]]
		});
	}

	export() {
		this.isLoading = true;
		const exportFormsDataRequest = new ExportFormsDataRequest(this.exportFormGroup.value);
		this.secureUploadInboxService.exportData(exportFormsDataRequest).subscribe(response => {
			this.isLoading = false;
			const exportFormsDataResponse = response.Result.map(item => {
				item.secureUploadLink = `${environment.generatorUrl}/SecureUpload/Download/${item.submissionID}`;
				return item;
			});
			ExcelUtility.WriteObjectToExcel(exportFormsDataResponse, 'forms data', 'forms export.xlsx');
		});
	}

}
