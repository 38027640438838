import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldItem } from 'app/models/form-builder/field-item.model';
import { Dependency } from 'app/models/form-builder/dependency.model';
import { CopyUtility } from 'app/utilities/copy-utility';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';


@Component({
	selector: 'edit-dependency',
	templateUrl: './edit-dependency.component.html',
	styleUrls: ['./edit-dependency.component.scss'],
})
export class EditDependencyComponent implements OnInit {
	@Input() hasOptions: boolean;
	@Input() dependencyForm: UntypedFormGroup;
	@Input() dependency: Dependency;
	@Input() item: FieldItem;
	@Input() edit: boolean;

	@Input() allFieldsNames: (name: string) => string[];

	@Output() save = new EventEmitter<Dependency>();
	constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService,) {
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
	}

	ngOnInit(): void {
		this.onChanges();
	}

	onChanges(): void {
		this.dependencyForm.valueChanges.subscribe(val => {
			Object.assign(this.dependency, val);
			this.save.emit(this.dependency);
		});
	}
}
