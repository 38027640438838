export const locale = {
	lang: 'en',
	data: {
		NAV: {
			APPLICATIONS: 'Applications',
			USER: {
				TITLE: 'User',
			},
			COMPANY: {
				TITLE: 'Company',
				MyCOMPANY: {
					TITLE: 'My company',
				},
				COMPANIES: {
					TITLE: 'Companies',
				},
			},
			LOGOUT: 'Logout',
			FORMS: 'Forms',
			ORDERS: 'Orders',
			Dashboard: 'Dashboards',
			SECUREUPLOAD: 'Secure upload',
		},
	},
};
