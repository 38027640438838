<div>
	<mat-toolbar matDialogTitle class="mat-accent m-0">
		<mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
			<span class="title dialog-title">{{dialogTitle}}</span>
			<button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
				<mat-icon>close</mat-icon>
			</button>
		</mat-toolbar-row>
	</mat-toolbar>
	<div mat-dialog-content class="p-24 pb-0 m-0">
		<mat-form-field class="w-6">
			<input matInput ngModel #title="ngModel" [(ngModel)]="option.title" (change)="updateTitle(title.value)"
				required placeholder="{{ 'EditField.EditOption.Title' | translate }}">
			<mat-error *ngIf="title.touched && title.hasError('required')">
				{{ 'EditField.EditOption.OptionTitleRequired' | translate }}
			</mat-error>
		</mat-form-field>
		<mat-form-field class="w-6">
			<input matInput ngModel #value="ngModel" [(ngModel)]="option.value" required
				placeholder="{{ 'EditField.EditOption.Value' | translate }}">
			<mat-error *ngIf="value.touched && value.hasError('required')">
				{{ 'EditField.EditOption.OptionValueRequired' | translate }}
			</mat-error>
		</mat-form-field>
		<mat-form-field class="w-6">
			<input matInput ngModel #description="ngModel" [(ngModel)]="option.description" required
				placeholder="{{ 'EditField.EditOption.Description' | translate }}">
			<mat-error *ngIf="description.touched && description.hasError('required')">
				{{ 'EditField.EditOption.OptionDescriptionRequired' | translate }}
			</mat-error>
		</mat-form-field>

		<div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
			<button mat-button class="mr-8" (click)="matDialogRef.close()" aria-label="Cancel">
				{{ 'EditField.EditOption.Cancel' | translate }}
			</button>
			<button mat-raised-button color="primary" (click)="matDialogRef.close(option)" [disabled]="optionInvalid"
				aria-label="SAVE">
				{{ 'EditField.EditOption.Save' | translate }}
			</button>
		</div>
	</div>
</div>