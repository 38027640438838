export class ResultModel<T> {
	public Success: boolean;
	public ResponseCode: number;
	public Result: T;
	public errors: any;
	public error: any;

	constructor(
		result?: T,
		success: boolean = true,
		responseCode: number = 200,
	) {
		this.Success = success;
		this.ResponseCode = responseCode;
		this.Result = result;
	}
}
