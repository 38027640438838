import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { DashboardService } from 'app/services/dashboard.service';
import * as shape from 'd3-shape';
import { fuseAnimations } from '@fuse/animations';
import DashboardModel, { WidgetModel } from 'app/models/dashboard/dashboardModel';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';
import { Company } from '../../../models/company.model';

@Component({
	selector: 'dashboard-index',
	templateUrl: './index.component.html',
	styleUrls: ['./index.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations
})
export class IndexComponent implements OnInit {
	widget5: any = {}; //used in graph widget
	companies: Company[] = [];
	dashboards: DashboardModel[] = [];
	selectedDashboard: DashboardModel;
	widgets: Array<WidgetModel>;
	graphWidgets: Array<any>;

	constructor(
		private _fuseSidebarService: FuseSidebarService,
		private _projectDashboardService: DashboardService,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService
	) {
		this._fuseTranslationLoaderService.loadTranslations(english, danish);

		this.widget5 = {
			currentRange: 'TM', xAxis: true, yAxis: true, gradient: false, legend: false, showXAxisLabel: false,
			xAxisLabel: 'Dates',
			showYAxisLabel: false,
			yAxisLabel: 'orders',
			scheme: { domain: ['#42BFF7', '#C6ECFD', '#C7B42C', '#AAAAAA'] },
			onSelect: (ev: any) => {
				console.log(ev);
			},
			supporting: {
				currentRange: 'TM',
				xAxis: false,
				yAxis: false,
				gradient: false,
				legend: false,
				showXAxisLabel: false,
				xAxisLabel: 'Dates',
				showYAxisLabel: false,
				yAxisLabel: 'orders',
				scheme: {
					domain: ['#42BFF7', '#C6ECFD', '#C7B42C', '#AAAAAA']
				},
				curve: shape.curveBasis
			}
		};
	}

	ngOnInit(): void {
		this.fetchDashboard();
		this.fetchCompanies();
	}

	fetchDashboard(id?: string) {
		this.selectedDashboard = null;
		this._projectDashboardService
			.getDashboard(id)
			.then(response => {
				this.setDashboard(response);
				this.dashboards.push(response);
			});
	}

	fetchCompanies() {
		this._projectDashboardService
			.getCompanies()
			.then(response => {
				this.companies = response;
			});
	}

	toggleSidebar(name: any): void {
		this._fuseSidebarService.getSidebar(name).toggleOpen();
	}

	setDashboard(dashboard: DashboardModel): void {
		this.selectedDashboard = dashboard;
		this.widgets = this.selectedDashboard.widgets;
		this.graphWidgets = this.selectedDashboard.graphWidgets;
	}

	changeCompany(id: string) {
		const companyName = this.companies.find(c => c.id == id)?.name;
		const dashboard = this.dashboards.find(d => d.companyName == companyName);
		if (dashboard) {
			this.setDashboard(dashboard);
		} else {
			this.fetchDashboard(id);
		}
	}
}
