import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { FuseSharedModule } from '@fuse/shared.module';

import { LogoutComponent } from '../logout/logout.component';

const routes = [
	{
		path: 'auth/logout',
		component: LogoutComponent,
	},
];

@NgModule({
	declarations: [LogoutComponent],
	imports: [
		RouterModule.forChild(routes),

		MatButtonModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,

		FuseSharedModule,
	],
})
export class LogoutModule {}
