import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxQuillWrapperComponent } from './ngx-quill-wrapper/ngx-quill-wrapper.component';
import { QuillModule } from 'ngx-quill'

@NgModule({
    declarations: [NgxQuillWrapperComponent],
    imports: [
        CommonModule,
        QuillModule.forRoot(),
    ],
    exports: [
        NgxQuillWrapperComponent
    ]
})
export class WyswygEditorModule { }
