import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

	constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
	) {
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
	}

	ngOnInit(): void {
	}

}
