import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { FuseSharedModule } from '@fuse/shared.module';

import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { MailConfirmComponent } from '../mail-confirm/mail-confirm.component';
import { MailConfirmModule } from '../mail-confirm/mail-confirm.module';
import { TranslateModule } from '@ngx-translate/core';
import { LuftbornModule } from 'app/shared-modules/luftborn/luftborn.module';

const routes = [
	{
		path: 'auth/forgot-password',
		component: ForgotPasswordComponent,
	},
];

@NgModule({
	declarations: [ForgotPasswordComponent],
	imports: [
		RouterModule.forChild(routes),

		MatButtonModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,

		FuseSharedModule,
		MailConfirmModule,
		TranslateModule,
		LuftbornModule,
	],
})
export class ForgotPasswordModule {}
