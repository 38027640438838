import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from 'app/services/app/auth.service';
import { LoginModel } from 'app/models/login.model';
import { Router } from '@angular/router';
import { SnackBarHelper } from '../../../services/helpers/snack-bar.helper';
import { ResultModel } from 'app/models/result-model';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from 'app/app.settings';

@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations,
})
export class LoginComponent implements OnInit {
	loginForm: UntypedFormGroup;
	formSubmitted: boolean;

	get appSettings() {
		return AppSettings;
	}

	constructor(
		private authService: AuthService,
		private _fuseConfigService: FuseConfigService,
		private _formBuilder: UntypedFormBuilder,
		public snackBarHelper: SnackBarHelper,
		private router: Router,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
		private translate: TranslateService,
	) {
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
		this.translate.setTranslation('en',english, true);
		this.translate.setTranslation('da',danish, true);
		
		this._fuseConfigService.config = {
			layout: {
				navbar: {
					hidden: true,
				},
				toolbar: {
					hidden: true,
				},
				footer: {
					hidden: true,
				},
				sidepanel: {
					hidden: true,
				},
			},
		};
	}

	ngOnInit(): void {
		if (this.authService.isLoggedIn()) {
			this.router.navigate(['/']);
		}
		this.loginForm = this._formBuilder.group({
			userName: ['', [Validators.required]],
			password: ['', Validators.required],
		});
	}

	onSubmit(): void {
		this.formSubmitted = true;
		const callback = (res: ResultModel<any>) => {
			this.formSubmitted = false;
			if (res.ResponseCode === 401) {
				this.snackBarHelper.openSnackBar({
					message: this.translate.instant(`Login.InvalidUsernameOrPassword`),
				});
			}
		};
		this.authService.Login(this.loginForm.value, callback);
	}
}
