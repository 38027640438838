import { UntypedFormGroup } from '@angular/forms';

export const validationContext = {
	passwordPattern: '(?=.*[a-z])(?=.*[0-9]).{8,}',
};
export class ValidationHelper {
	constructor() { }

	public static setFormErrors(errors: any, formGroup: UntypedFormGroup): void {
		for (const fields in errors) {
			if (errors.hasOwnProperty(fields)) {
				const rule = errors[fields][0];
				for (const field of fields.split(',')) {
					const validationError = { [rule]: true };
					const controlName = ValidationHelper.convertToCamelCase(field);
					if (formGroup.controls.hasOwnProperty(controlName)) {
						formGroup.controls[controlName].setErrors(validationError, {
							emitEvent: true,
						});
					} else {
						formGroup.setErrors(validationError, {
							emitEvent: true,
						});
					}
				}
			}
		}
	}

	private static convertToCamelCase(str: string): string {
		return str && str.replace(/./, str[0].toLowerCase());
	}
}
