import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { UserComponent } from './user.component';
import { AuthGuard } from 'app/guards/auth.guard';
import { ListComponent } from './list/list.component';
import { EditComponent } from './edit/edit.component';
import { MatSharedModule } from 'app/shared-modules/mat-shared/mat-shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LuftbornModule } from 'app/shared-modules/luftborn/luftborn.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DirectiveModule } from 'app/directive/directive.module';
const routes = [
	{
		path: 'user',
		component: UserComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: 'add', component: EditComponent },
			{ path: ':id', component: EditComponent },
			{ path: '**', component: ListComponent },
		],
	},
];

@NgModule({
	declarations: [
		UserComponent,
		ListComponent,
		EditComponent,
	],
	imports: [
		RouterModule.forChild(routes),
		TranslateModule,
		FuseSharedModule,
		MatSharedModule,
		BrowserAnimationsModule,
		LuftbornModule,
		FlexLayoutModule,
		DirectiveModule,
	],
	exports: [UserComponent],
})
export class UserModule {}
