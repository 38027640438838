<mat-card>
	<mat-card-header>
		<p>
			<a mat-raised-button color="primary" [routerLink]="['add']">
				{{ 'List.AddCompany' | translate }}!
			</a>
		</p>
	</mat-card-header>
	<mat-card-content>
		<div class="mat-elevation-z8">
			<table mat-table matSort [dataSource]="dataSource">
				<ng-container matColumnDef="position">
					<th mat-header-cell *matHeaderCellDef> No. </th>
					<td mat-cell *matCellDef="let element"> {{element.position}} </td>
				</ng-container>

				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef>{{ 'List.Name' | translate }}</th>
					<td mat-cell *matCellDef="let element"> {{element.name}} </td>
				</ng-container>

				<ng-container matColumnDef="website">
					<th mat-header-cell *matHeaderCellDef>{{ 'List.Website' | translate }}</th>
					<td mat-cell *matCellDef="let element"> {{element.website}} </td>
				</ng-container>

				<ng-container matColumnDef="email">
					<th mat-header-cell *matHeaderCellDef>{{ 'List.Email' | translate }}</th>
					<td mat-cell *matCellDef="let element"> {{element.email}} </td>
				</ng-container>

				<ng-container matColumnDef="phone">
					<th mat-header-cell *matHeaderCellDef>{{ 'List.Phone' | translate }}</th>
					<td mat-cell *matCellDef="let element"> {{element.phone}} </td>
				</ng-container>

				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef>{{ 'List.Actions' | translate }}</th>
					<td mat-cell *matCellDef="let element">
						<a mat-button [routerLink]="[element.id]">
							<mat-icon>edit</mat-icon>
						</a>
						<button mat-button (click)="Delete(element)">
							<mat-icon>delete</mat-icon>
						</button>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="editCompany(row)"></tr>
			</table>
			<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
		</div>
	</mat-card-content>
</mat-card>