import { Injectable } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import {
	FieldGroupRow,
	FieldType,
	FieldItem,
	FieldGroup,
	Dependency
} from 'app/models/form-builder/form-definition-types-module';
import { SignerFields } from 'app/models/form-builder/signer-fields.model';
import { uniqueValueValidator, noMongoRestrictedCharactersValidator } from 'app/services/helpers/custom-validators';

import {
	TypesWithOptions,
	TypesWithCustomValues,
	TypesWithOptionsWithDescription,
} from 'app/models/form-builder/supported-types.const';

import SecureUploadFormDefinition from 'app/models/form-builder/secure-upload-form-definition.model';

@Injectable({
	providedIn: 'root',
})
export class SecureUploadFormFactory {
	readonly typesWithOptions: FieldType[] = TypesWithOptions;
	readonly typesWithOptionsWithDescription: FieldType[] = TypesWithOptionsWithDescription;
	readonly typesWithCustomValues: FieldType[] = TypesWithCustomValues;

	formDefinitionForm: UntypedFormGroup;
	formDefinition: SecureUploadFormDefinition;

	constructor(private _formBuilder: UntypedFormBuilder) { }

	get allFieldItems(): FieldItem[] {
		if (
			this.formDefinition.fieldGroups &&
			this.formDefinition.fieldGroups.length
		) {
			const rows = this.formDefinition.fieldGroups
				.map(g => {
					return g.rows;
				})
				.reduce((a, b) => {
					return a.concat(b);
				});
			if (rows && rows.length) {
				const items = rows
					.map(g => {
						return g.items;
					})
					.reduce((a, b) => {
						return a.concat(b);
					});
				return items;
			}
			return [];
		}
		return [];
	}

	public create(formDefinition: SecureUploadFormDefinition): UntypedFormGroup {
		this.formDefinition = formDefinition;
		this.createSecureUploadFormDefinitionForm();
		return this.formDefinitionForm;
	}

	public createSecureUploadFormDefinitionForm(): UntypedFormGroup {
		this.setTempFieldsIds();
		this.formDefinitionForm = this._formBuilder.group({
			id: [this.formDefinition.id],
			createdAt: [this.formDefinition.createdAt],
			modifiedAt: [this.formDefinition.modifiedAt],
			author: [this.formDefinition.author, Validators.required],
			companyUrl: [this.formDefinition.companyUrl, Validators.pattern(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/)],
			language: [this.formDefinition.language, Validators.required],
			name: [this.formDefinition.name, Validators.required],
			documentName: [this.formDefinition.documentName, Validators.required],
			active: [this.formDefinition.active, Validators.required],
			imageDataUrl: [this.formDefinition.imageDataUrl],
			color: this.formDefinition.color,
			formNameTextColor: this.formDefinition.formNameTextColor,
			hideFormName: this.formDefinition.hideFormName,
			imagePosition: this.formDefinition.imagePosition,
			header: [this.formDefinition.header],
			footer: [this.formDefinition.footer],
			companyName: [this.formDefinition.companyName],
			companyId: [this.formDefinition.companyId],
			fieldGroups: this.createGroupsFormArray(this.formDefinition.fieldGroups),
			senderFields: this.createSenderFieldsFormGroup(this.formDefinition.senderFields),
			notificationEmails: [this.formDefinition.notificationEmails],
			published: [this.formDefinition.published],
		});
		return this.formDefinitionForm;
	}

	public createSenderFieldsFormGroup(signersFields: SignerFields): UntypedFormGroup {
		return this._formBuilder.group({
			name: [signersFields.name, Validators.required],
			email: [signersFields.email, Validators.required]
		});
	}

	public createGroupsFormArray(groups: FieldGroup[]): UntypedFormArray {
		const groupsArray = groups.map(group =>
			this._formBuilder.group(
				{
					title: [group.title, Validators.required],
					dependentOn: [group.dependentOn],
					dependentValue: [group.dependentValue],
					description: [group.description],
					groupFooter: [group.groupFooter],
					rows: this.createRowsFormArray(group.rows),
				},
				{
					validators: this.validateFormGroup.bind(this),
				},
			),
		);
		return this._formBuilder.array(groupsArray);
	}

	public createRowsFormArray(rows: FieldGroupRow[]): UntypedFormArray {
		const rowsArray = rows.map(row =>
			this._formBuilder.group({
				items: this.createItemsFormArray(row.items),
			}),
		);
		return this._formBuilder.array(rowsArray);
	}

	public createItemsFormArray(items: FieldItem[]): UntypedFormArray {
		const itemsArray = items.map(item =>
			this.createFieldItemFormGroup(item),
		);
		return this._formBuilder.array(itemsArray);
	}

	public createFieldItemFormGroup(item: FieldItem): UntypedFormGroup {
		return this._formBuilder.group(
			{
				tempId: [item.tempId],
				label: [item.label, Validators.required],
				name: [item.name, Validators.required],
				placeholder: [item.placeholder],
				value: [item.value],
				type: [item.type, Validators.required],
				required: [item.required, Validators.required],
				multi: [item.multi],
				allDependenciesMustBeMet: [item.allDependenciesMustBeMet],
				min: [item.min],
				max: [item.max],
				step: [item.step],
				dateFormat: [item.dateFormat],
				options: [[...item.options]],
				optionsWithDescriptions: item.optionsWithDescriptions ? [[...item.optionsWithDescriptions]] : [],
				addressAutoComplete: [item.addressAutoComplete],
				noValueDependency: this.createDependencyFormGroup(
					item.noValueDependency,
				),
				hasValueDependency: this.createDependencyFormGroup(
					item.hasValueDependency,
				),
				dependencies: this._formBuilder.array(
					item.dependencies.map(d =>
						this.createDependencyFormGroup(
							d,
							item.dependencies.filter(
								dependency => dependency !== d,
							),
						),
					),
				),
			},
			{
				validators: this.validateFieldItem.bind(this),
			},
		);
	}

	public createDependencyFormGroup(
		dependency: Dependency,
		other?: Dependency[],
	): UntypedFormGroup {
		dependency = dependency || new Dependency();
		const valueValidator =
			other && other.length > 0
				? uniqueValueValidator(other.map(d => d.value))
				: Validators.nullValidator;
		return this._formBuilder.group(
			{
				value: [dependency.value, [valueValidator]],
				criteria: [dependency.criteria],
				items: [[...dependency.items]],
			},
			{
				validator: this.validateDependency,
			},
		);
	}

	parseValue(type: string, value: string): any {
		if (type === 'number') {
			return Number(value);
		}
		if (type === 'date') {
			return new Date(value);
		}
		return value;
	}

	validateFieldItem(
		group: UntypedFormGroup,
	): {
		// nameNotUnique: boolean;
		optionsRequired: boolean;
		optionsWithDescriptionsRequired: boolean,
		valueRequired: boolean;
		maxValid: boolean;
	} {
		const tempId = group.controls.tempId.value;
		const name = group.controls.name.value;
		const type = group.controls.type.value;
		const options = group.controls.options.value;
		const optionsWithDescriptions = group.controls.optionsWithDescriptions.value;
		const value = group.controls.value.value;
		const min = group.controls.min.value;
		const max = group.controls.max.value;

		const mustHaveOptions = this.typesWithOptions.includes(type);
		const mustHaveOptionsWithDescription = this.typesWithOptionsWithDescription.includes(type);
		const mustHaveValue = this.typesWithCustomValues.includes(type);
		const allItems = this.allFieldItems;

		const fieldsWithSameName = allItems.filter(
			item => item.tempId !== tempId && item.name === name,
		);

		const nameValid = fieldsWithSameName.length === 0 && !!name;
		const optionsValid =
			!mustHaveOptions || (mustHaveOptions && options.length > 0);
		const optionsWithDescriptionsValid = !mustHaveOptionsWithDescription || (mustHaveOptionsWithDescription && optionsWithDescriptions.length > 0);
		const valueValid = !mustHaveValue || (mustHaveValue && !!value);
		const maxParsed = this.parseValue(type, max);
		const minParsed = this.parseValue(type, min);
		const maxValid = !min || !max || maxParsed > minParsed;

		const nameControlError = nameValid ? null : { notUnique: true };
		const optionsControlError = optionsValid ? null : { required: true };
		const optionsWithDescriptionControlError = optionsWithDescriptionsValid ? null : { required: true };
		const valueControlError = valueValid ? null : { required: true };
		const maxControlError = maxValid ? null : { invalid: true };

		if (
			group.controls.name.hasError('notUnique') &&
			nameControlError == null
		) {
			group.controls.options.setErrors(optionsControlError);
		}

		const restrictedCharactersError = noMongoRestrictedCharactersValidator(name);

		group.controls.name.setErrors(nameControlError);
		group.controls.name.setErrors(restrictedCharactersError);
		group.controls.value.setErrors(valueControlError);
		group.controls.max.setErrors(maxControlError);
		group.controls.optionsWithDescriptions.setErrors(optionsWithDescriptionControlError);

		if (optionsValid && nameValid && valueValid && maxValid && optionsWithDescriptionsValid) {
			return null;
		}

		return {
			// nameNotUnique: nameValid ? null : true,
			optionsRequired: optionsValid ? null : true,
			optionsWithDescriptionsRequired: optionsWithDescriptionsValid ? null : true,
			valueRequired: valueValid ? null : true,
			maxValid: maxValid ? null : true,
		};
	}

	validateDependency(group: UntypedFormGroup): { itemsRequired: boolean } {
		const value = group.controls.value.value;
		const items = group.controls.items.value;
		const valid = !value || (!!items && items.length > 0);
		if (valid) {
			return null;
		}
		group.controls.items.setErrors({
			required: true,
		});
		return { itemsRequired: true };
	}
	validateFormGroup(group: UntypedFormGroup): { valueRequired: boolean } {
		const dependentOn = group.controls.dependentOn.value;
		const dependentValue = group.controls.dependentValue.value;
		const valid = !dependentOn || (!!dependentOn && !!dependentValue);

		if (valid) {
			group.controls.dependentValue.setErrors(null);
			return null;
		}
		group.controls.dependentValue.setErrors({
			required: true,
		});
		return { valueRequired: true };
	}
	setTempFieldsIds(): void {
		let counter = 1;
		this.allFieldItems.map(item => (item.tempId = counter++));
	}
}
