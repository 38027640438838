import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomTelNumberDirective } from './custom-tel-number.directive';
import { DomChangeEventDebouncerDirective } from './dom-change-event-debouncer.directive';



@NgModule({
	declarations: [
		CustomTelNumberDirective,
		DomChangeEventDebouncerDirective
	],
	imports: [
		CommonModule
	],
	exports: [CustomTelNumberDirective, DomChangeEventDebouncerDirective]
})
export class DirectiveModule { }
