import DocumentModel from './document.model';

export default class OrderModel {
	public id: string;
	public signerName: string;
	public signerEmail: string;
	public signerIdentification: string;
	public signedDate: Date;
	public referenceID: string;
	public relatedFormId: string;
	public relatedFormName: string;
	public documents: DocumentModel[];
	public formSubmissionId: string;

	constructor() {
	}
}
