import { FormControl, AbstractControl, ValidatorFn } from '@angular/forms';

export function noMongoRestrictedCharactersValidator(
	value: string,
): { hasRestrictedCharacters: boolean } {
	const hasRestrictedCharacters = /[/\. "$*<>:|?]/.test(value || '');
	const isValid = !hasRestrictedCharacters;
	return isValid ? null : { hasRestrictedCharacters: true };
}

export function uniqueValueValidator(otherValues: string[]): ValidatorFn {
	return (control: AbstractControl): { [key: string]: boolean } => {
		const matchedValue = control.value;
		if (
			otherValues.filter(
				d => d.toLowerCase() === matchedValue.toLowerCase(),
			).length > 0
		) {
			return { valueNotUnique: true };
		}
		return null;
	};
}
