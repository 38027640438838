<div fxLayout="row" fxLayoutAlign="space-between center" style="display: flex; justify-content:space-between;
    align-items: center;">
	<span>
		{{data.message}}
	</span>
	<a *ngIf='data.link' mat-button [routerLink]="[data.link.href]">
		{{data.link.text}}
	</a>
	<button mat-raised-button color="accent" (click)="snackBarRef.dismiss()">
		{{ 'SnackBar.Dismiss' | translate }}
	</button>
</div>