import { Languages } from './Languages'

export class Company {
	public id: string;
	public name: string;
	public email: string;
	public phone: string;
	public website: string;
	public address: string;
	public langCode: Languages;
	public apiKey: string;
	public secureUploadEnabled: boolean;

	public constructor(init?: Partial<Company>) {
		Object.assign(this, init);
	}
}
