import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../../shared-modules/mat-shared/snack-bar/snack-bar.component';

@Injectable({
	providedIn: 'root',
})
export class SnackBarHelper {
	configSuccess: MatSnackBarConfig = {
		panelClass: 'style-success',
		duration: 3000,
		horizontalPosition: 'center',
		verticalPosition: 'top',
	};
	constructor(public snackBar: MatSnackBar) { }

	public openSnackBar(data: {
		message: string;
		link?: { text: string; href: string };
	}): void {
		this.snackBar.openFromComponent(SnackBarComponent, {
			data,
			...this.configSuccess,
		});
	}
}
