import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'app/services/user.service';
import { User } from 'app/models/user.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/shared-modules/mat-shared/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';

import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import IGroup from 'app/models/IGroup';
import SearchParameters from 'app/models/search-parameters.model';


@Component({
	selector: 'user-list',
	styleUrls: ['list.component.css'],
	templateUrl: 'list.component.html',
})
export class ListComponent implements OnInit {
	public title: 'List';
	public displayedColumns: string[] = [
		'select',
		'name',
		'userName',
		'type',
		'email',
		'phone',
		'companyName',
		'actions',
	];
	public allUsers: User[];
	public selectedUsers: User[];
	public userGroups: IGroup<User>[];
	public loadingIndicator: boolean;
	public reOrderable: boolean;
	public searchParameters: SearchParameters = new SearchParameters();
	public totalCount: number;
	public curPage: number;
	public pageSize: number;

	@ViewChild('usersTable', { static: false }) table: any;

	constructor(private userService: UserService, public dialog: MatDialog, private router: Router, private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
		this.loadingIndicator = true;
		this.reOrderable = true;
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
		this.searchParameters.size = 5;
	}

	private GetAll(searchParameters: SearchParameters): void {
		this.userService.getAll(searchParameters).subscribe(res => {
			this.allUsers = res.Result.list;
			this.selectedUsers = res.Result.list;
			this.loadingIndicator = false;
			this.totalCount = res.Result.total;
			this.curPage = res.Result.pageNumber;
			this.pageSize = res.Result.pageSize;
		});
	}

	onPageChange(event: any): void {
		this.searchParameters.page = event.page;
		this.GetAll(this.searchParameters);
	}
	
	onSort(event: any): void {
		const sort = event.sorts[0];
		this.searchParameters.sortValue = sort.prop;
		this.searchParameters.page = 1;
		this.searchParameters.sortAscending = sort.dir === 'asc';
		this.GetAll(this.searchParameters);
	}

	public onRowActivate(event: any): void {
		if (event.type === 'dblclick') {
			this.router.navigate([`user/${event.row.id}`]);
		}
	}

	public applyFilter(event: any): void {
		this.searchParameters.page = 1;
		this.searchParameters.searchValue = event.target.value;
		this.GetAll(this.searchParameters);
	}

	public Delete(user: User): void {
		const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { confirmMessage: `Are you sure, you want to delete ${user.name}?` } });
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.userService.Delete(user.id).subscribe(res => { this.GetAll(this.searchParameters); });
			}
		});
	}

	public ngOnInit(): void {
		this.GetAll(this.searchParameters);
	}

	get pageCountFooter(): string {
		const startItem = (this.curPage - 1) * this.pageSize + 1;
		const endItem = this.curPage * this.pageSize > this.totalCount ? this.totalCount : this.curPage * this.pageSize;
		return `Showing ${startItem} - ${endItem} of ${this.totalCount}`;
	}
}
