import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';


//https://stackoverflow.com/questions/47469568/angular-4-paginators-items-per-page-translation?rq=1
//In the module that uses it we must: add this class as a provider {provide: MatPaginatorIntl, useClass: forwardRef(() => PaginatorTranslation)}
// and add the translation (in the module _i18n) under object named "Paginator" with properties "ItemsPerPage"

@Injectable()
export class PaginatorTranslation extends MatPaginatorIntl {
	constructor(private translate: TranslateService) {
		super();
	}

	itemsPerPageLabel: string = this.translate.instant('Paginator.ItemsPerPage');
}