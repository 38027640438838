import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyService } from 'app/services/company.service';
import { Company } from 'app/models/company.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/shared-modules/mat-shared/confirm-dialog/confirm-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';
import { TranslateService } from '@ngx-translate/core';

/**
 * @title Table with pagination
 */
@Component({
	selector: 'company-list',
	styleUrls: ['list.component.css'],
	templateUrl: 'list.component.html',
})
export class ListComponent implements OnInit {
	title: 'List';
	displayedColumns: string[] = [
		'name',
		'website',
		'email',
		'phone',
		'actions',
	];

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	dataSource = new MatTableDataSource<Company>(new Array<Company>());

	constructor(
		private companyService: CompanyService,
		public dialog: MatDialog,
		private router: Router,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
		private translate: TranslateService,
	) {
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
	}

	public editCompany(company: any): void {
		this.router.navigate([`companies/${company.id}`]);
	}

	public Delete(company: Company): void {
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: {
				confirmMessage: `${this.translate.instant('List.ConfirmDelete')} ${company.name}\n` +
								`${this.translate.instant('List.ThatHaveAPIKey')}: ${company.apiKey}\n` +
								`${this.translate.instant('List.WithAllRelatedData')}?`,
			},
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.companyService.Delete(company.id).subscribe(res => {
					this.GetAll();
				});
			}
		});
	}

	public ngOnInit(): void {
		this.dataSource.paginator = this.paginator;
		this.GetAll();
	}

	private GetAll(): void {
		this.companyService.getAll().subscribe(res => {
			this.dataSource.data = res.Result;
		});
	}
}
