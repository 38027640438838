import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../_i18n/en';
import { locale as danish } from '../../_i18n/da';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import DownloadUtility from 'app/utilities/download-utility';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import SecureUploadFormSubmission from 'app/models/form-builder/secure-upload-form-submission.model';
import { SecureUploadInboxService } from 'app/services/secure-upload-inbox.service';
import FormSubmissionFileModel from 'app/models/form-submission/form-submission-file';

@Component({
	selector: 'secure-upload-submission-details',
	templateUrl: './secure-upload-submission-details.component.html',
	styleUrls: ['./secure-upload-submission-details.component.scss']
})
export class SecureUploadSubmissionDetailsComponent implements OnInit, OnDestroy {
	private _unsubscribeAll: Subject<any>;

	public numberOfPDFDownloads = 0;
	public numberOfAttachmentsDownloads = 0;
	public attachmentsNamesLoading = true;
	public attachmentsNames: { [key: string]: string };

	constructor(
		@Inject(MAT_DIALOG_DATA) public submission: SecureUploadFormSubmission,
		public dialogRef: MatDialogRef<SecureUploadSubmissionDetailsComponent, SecureUploadFormSubmission>,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
		private translate: TranslateService,
		private secureUploadInboxService: SecureUploadInboxService,
		private _matSnackBar: MatSnackBar,
	) {
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void {
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	getTranslation(key: string): string {
		return this.translate.instant(key);
	}

	downloadFile(doc: FormSubmissionFileModel, $event: Event, download: boolean, downloadSubmissionDocument: boolean = false): void {
		this.numberOfPDFDownloads += 1;
		this.secureUploadInboxService.downloadFile(this.submission.id, doc.fileName, downloadSubmissionDocument)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((response) => {
				this.numberOfPDFDownloads -= 1;
				DownloadUtility.downloadBlob(response, doc.fileName, download);
			}, (error) => {
				this.displayErrorMessage();
				this.numberOfPDFDownloads -= 1;
			});
	}

	private displayErrorMessage(): void {
		this._matSnackBar.open(this.getTranslation('TSvariables.AnErrorOccurred'), this.getTranslation('TSvariables.OK'), {
			verticalPosition: 'top',
			duration: 2000,
		});
	}
}
