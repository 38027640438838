<div class="preview-container">
	<header class="header">
		<div class="head-logo-wrapper" [ngClass]="imageAlignClasses()">
			<img class="head-logo" *ngIf="formDefinition.imageDataUrl"
			[src]="sanitizer.bypassSecurityTrustResourceUrl(formDefinition.imageDataUrl)" [alt]="formDefinition.name">
		</div>
		<span *ngIf="!formDefinition.hideFormName" class="head-title" [style.background]="formDefinition.color" [style.color]="formDefinition.formNameTextColor ? formDefinition.formNameTextColor : '#2D556F'">{{formDefinition.name}}</span>
	</header>
	<main>
		<section>
			<div class="quill-default-font-size" [innerHtml]='formDefinition.header | safeHtml'></div>
		</section>
		<section class="form">
			<div>
				<div *ngFor='let group of formDefinition.fieldGroups'>
					<div class="form-group-description quill-default-font-size" *ngIf='group.description' [innerHtml]='group.description | safeHtml'>
					</div>
					<div class="field-group-preview">
						<div class="field-group-title">
							{{group.title}}
						</div>
						<div class="field-group-content">
							<div class="group-row" *ngFor='let row of group.rows'>
								<div class="custom-validation-target" *ngFor='let item of row.items'>
									<div class="field-item">
										<div class="field-label">{{item.label}}:</div>
										<div class="field-input" [innerHtml]='item | renderItem | safeHtml'></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group-groupFooter quill-default-font-size" *ngIf='group.groupFooter' [innerHtml]='group.groupFooter | safeHtml'>
					</div>
				</div>
			</div>
		</section>
		<section class="form-footer">
			<div class="quill-default-font-size" [innerHtml]='formDefinition.footer | safeHtml'>
			</div>
		</section>
	</main>
</div>