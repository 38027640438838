export enum FieldType {
	address,
	text,
	email,
	date,
	number,
	tel,
	radio,
	checkbox,
	select,
	textarea,
	customRegEx,
	file,
	cpr,
	cvr,
	bank,
	identification,
	typeAhead
}
