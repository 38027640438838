export const locale = {
	lang: 'da',
	data: {
		'Account': {
			'Account': 'Konto',
		},
		'Profile': {
			'UserName': 'Brugernavn',
			'UserNameRequired': 'Brugernavn kræves',
			'UserNameTaken': 'Dette brugernavn er taget af en anden bruger',
			'Email': 'E-mail',
			'EmailRequired': 'E-mail er påkrævet',
			'EmailTaken': 'Denne e-mail er taget af en anden bruger',
			'FirstName': 'Fornavn',
			'FirstNameRequired': 'Fornavn kræves',
			'LastName': 'Efternavn',
			'Phone': 'Telefon',
			'PhoneRequired': 'Telefon kræves',
			'OldPassword': 'Gammelt kodeord',
			'OldPasswordRequired': 'gammelt kodeord skal udfyldes for at ændre adgangskoded',
			'InvalidPassword': 'Ugyldigt kodeord',
			'NewPassword': 'Ny adgangskode',
			'NewPasswordErr': 'Mindst 8 tegn i længden, små bogstaver, tal',
			'ConfirmPassword': 'Bekræft nyt kodeord',
			'ConfirmPasswordErr': 'Adgangskoder stemmer ikke overens',
			'Save': 'Gem ændringer',
			'ProfileUpdatedSuccessfully': 'Profilen blev opdateret',
			'UpdateProfileFailed': 'Opdateringsprofil mislykkedes',
		},
		'Activate': {
			'AccountInactive': 'DIN KONTO ER INAKTIV',
			'ActivateYourAccount': 'Du skal indstille din adgangskode for at aktivere din konto',
			'Password': 'Password',
			'PasswordRequired': 'Indtast din nye adgangskode',
			'PasswordValidation': 'Mindst 8 tegn i længden, små bogstaver, tal',
			'ConfirmPassword': 'Bekræft adgangskode',
			'ConfirmPasswordErr': 'Adgangskoder stemmer ikke overens',
			'Save': 'Gem adgangskode',
			'InvalidActivationToken': 'Ugyldig aktiveringstoken',
		}
	}
};
