import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/app/auth.service';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
	constructor(private authRequest: AuthService) {}

	ngOnInit(): void {
		this.logOut();
	}
	logOut(): void {
		this.authRequest.Logout();
	}
}
