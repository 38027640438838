import FormDefinition from 'app/models/form-builder/form-definition.model';
import { environment } from 'environments/environment';
import UrlUtility from '../url-utility';

export default class FormsUtilities {
	static getFormUrl(formDefinition: FormDefinition): string {
		const companyName = UrlUtility.beautifyUrlComponent(formDefinition.companyName);
		const formUrl = `${environment.generatorUrl}/form/${companyName}/${formDefinition.id}`;
		return formUrl;
	}

	static validateImportedForm(formDefinition: FormDefinition): boolean {
		if (formDefinition.fieldGroups && formDefinition.signersFields) {
			return true;
		}
		return false;
	}

	static createCleanFormForExportAndImport(formDefinition: FormDefinition) {
		delete formDefinition.id;
		delete formDefinition.createdAt;
		delete formDefinition.modifiedAt;
		delete formDefinition.creatorId;
		delete formDefinition.companyId;
		delete formDefinition.companyName;
		delete formDefinition.senderEmail;
		formDefinition.published = false;
	}

	static downloadForm(formDefinition: FormDefinition): void {
		FormsUtilities.createCleanFormForExportAndImport(formDefinition);
		const blob = new Blob([JSON.stringify(formDefinition)], { type: 'application/json' });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = `${formDefinition.name}.json`;
		link.click();
	}
}
