import {
	Component,
	ElementRef,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { takeUntil } from 'rxjs/operators';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';
import { SnackBarHelper } from 'app/services/helpers/snack-bar.helper';
import { CompanyService } from 'app/services/company.service';
import { Company } from 'app/models/company.model';
import { AuthService } from 'app/services/app/auth.service';
import { SecureUploadInboxService } from 'app/services/secure-upload-inbox.service';
import SecureUploadFormSubmission from 'app/models/form-builder/secure-upload-form-submission.model';
import { SecureUploadSubmissionDetailsComponent } from './secure-upload-submission-details/secure-upload-submission-details.component';
import { ExportInboxDataComponent } from './export-inbox-data/export-inbox-data.component';

@Component({
	selector: 'secure-upload-inbox',
	templateUrl: './secure-upload-inbox.component.html',
	styleUrls: ['./secure-upload-inbox.component.scss'],
	animations: fuseAnimations,
	encapsulation: ViewEncapsulation.None,
})
export class SecureUploadInboxComponent implements OnInit {
	dataSource: InboxDataSource | null;
	displayedColumns = ['senderName', 'senderEmail', 'sentDate', 'relatedFormName', 'submissionID', 'actions'];
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	isSuperAdmin: boolean;
	isLoading: boolean = false;
	companies: Company[];
	companiesLoaded: boolean;
	currentOperatedCompany: Company;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	@ViewChild('filter', { static: true })
	filter: ElementRef;

	// Private
	private _unsubscribeAll: Subject<any>;

	constructor(
		private secureUploadInboxService: SecureUploadInboxService,
		private companyService: CompanyService,
		private dialog: MatDialog,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
		public snackBarHelper: SnackBarHelper,
		private authService: AuthService,
	) {
		// Set the private defaults
		this._unsubscribeAll = new Subject();
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
	}
	ngOnInit(): void {
		this.isSuperAdmin = this.authService.isCurrentUserSuperAdmin();
		if (this.isSuperAdmin) {
			this.initCompanies();
		}
		
		this.dataSource = new InboxDataSource(
			this.secureUploadInboxService,
			this.paginator,
			this.sort,
		);

		this.secureUploadInboxService.dataIsLoading.subscribe(isLoading => {
			this.isLoading = isLoading;
		});

		fromEvent(this.filter.nativeElement, 'keyup')
			.pipe(
				takeUntil(this._unsubscribeAll),
				debounceTime(150),
				distinctUntilChanged(),
			)
			.subscribe(() => {
				if (!this.dataSource) {
					return;
				}
				this.secureUploadInboxService.searchParameters.searchValue = this.filter.nativeElement.value;
				this.secureUploadInboxService.searchParameters.page = 1;
				this.paginator.pageIndex = 0;
				this.secureUploadInboxService.searchParametersChanged.next(this.secureUploadInboxService.searchParameters);
			});
	}
	
	initCompanies(): void {
		this.currentOperatedCompany = this.companyService.currentOperatedCompany;

		this.companyService.getAll()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(res => {
				this.companies = res.Result;
				const currentOperatedCompanyExists = this.companies.findIndex(
					company => company.id === this.currentOperatedCompany.id
				) !== -1;
				if (!currentOperatedCompanyExists) {
					this.currentOperatedCompany = this.authService.getCurrentUserCompany();
				}
				this.companiesLoaded = true;
			});
	}

	openDialog(submission: SecureUploadFormSubmission): void {
		const dialogRef: MatDialogRef<SecureUploadSubmissionDetailsComponent, SecureUploadFormSubmission> = this.dialog.open(SecureUploadSubmissionDetailsComponent, {
			minWidth: '700px',
			minHeight: '500px',
			data: submission
		});
		
		if (!submission.modifiedAt) {
			submission.modifiedAt = new Date();
			this.readInboxItem(submission.id);
		}
	}

	refresh() {
		this.secureUploadInboxService.searchParameters.searchValue = this.filter.nativeElement.value;
		this.secureUploadInboxService.searchParametersChanged.next(this.secureUploadInboxService.searchParameters);
	}

	openExportDataDialogue() {
		this.dialog.open(ExportInboxDataComponent);
	}

	selectedCompanyChange(companyId: string): void {
		const filteredCompanies = this.companies.filter(company => company.id === companyId);
		if (!filteredCompanies || !filteredCompanies[0]) {
			return;
		}
		const newlySelectedCompany = filteredCompanies[0];
		this.companyService.currentOperatedCompany = newlySelectedCompany;
		this.currentOperatedCompany = newlySelectedCompany;
		this.secureUploadInboxService.searchParameters.page = 1;
		this.secureUploadInboxService.searchParametersChanged.next(this.secureUploadInboxService.searchParameters);
	}

	private readInboxItem(submissionId: string){
		this.secureUploadInboxService.readInboxItem(submissionId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(() => {
				this.secureUploadInboxService.onUnreadCountChanged.next(this.secureUploadInboxService.unreadCount - 1);
			});
	}
}

export class InboxDataSource extends DataSource<any> {
	private _filterChange = new BehaviorSubject('');
	private _filteredDataChange = new BehaviorSubject<SecureUploadFormSubmission[]>([]);
	public totalDataCount: number = 0;
	public isLoading: boolean = false;

	constructor(
		private secureUploadInboxService: SecureUploadInboxService,
		private _matPaginator: MatPaginator,
		private _matSort: MatSort,
	) {
		super();
		this.filteredData = this.secureUploadInboxService.inbox;
		this.totalDataCount = this.secureUploadInboxService.totalInboxItemsCount;
		this._matPaginator.pageIndex = this.secureUploadInboxService.searchParameters.page - 1;
		this._matPaginator.pageSize = this.secureUploadInboxService.searchParameters.size;
		this.secureUploadInboxService.searchParametersChanged.next(this.secureUploadInboxService.searchParameters);
	}

	connect(): Observable<any[]> {
		const displayDataChanges = [
			this.secureUploadInboxService.onInboxChanged,
			this._matPaginator.page,
			this._filterChange,
			this._matSort.sortChange,
		];

		this._matPaginator.page.subscribe(() => {
			this.secureUploadInboxService.searchParameters.page = this._matPaginator.pageIndex + 1;
			this.secureUploadInboxService.searchParameters.size = this._matPaginator.pageSize;
			this.secureUploadInboxService.searchParametersChanged.next(this.secureUploadInboxService.searchParameters);
		});

		this._matSort.sortChange.subscribe(() => {
			this.secureUploadInboxService.searchParameters.sortValue = this._matSort.active;
			this.secureUploadInboxService.searchParameters.sortAscending = this._matSort.direction === 'asc';
			this.secureUploadInboxService.searchParametersChanged.next(this.secureUploadInboxService.searchParameters);
		});

		return merge(...displayDataChanges).pipe(
			map(() => {
				// The data should already be filtered and sorted by the backend, no need to do it again here
				this.filteredData = this.secureUploadInboxService.inbox;
				this.totalDataCount = this.secureUploadInboxService.totalInboxItemsCount;
				return this.filteredData;
			}),
		);
	}

	get filteredData(): SecureUploadFormSubmission[] {
		return this._filteredDataChange.value;
	}

	set filteredData(value: SecureUploadFormSubmission[]) {
		this._filteredDataChange.next(value);
	}

	// Filter
	get filter(): string {
		return this._filterChange.value;
	}

	set filter(filter: string) {
		this._filterChange.next(filter);
	}

	disconnect(): void { }
}
