<div class="tab-content p-24" fxLayout='column' [formGroup]='dependencyForm'>
	<mat-form-field>
		<mat-label>{{ 'EditField.DependentFields' | translate }}</mat-label>
		<mat-select multiple formControlName="items">
			<mat-option *ngFor="let field of allFieldsNames(item.name)" [value]="field">
				{{field}}</mat-option>
		</mat-select>
	</mat-form-field>
	<div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
		<button mat-button class="primary w-20-p" (click)="addDependency(item)" *ngIf='!edit'
			[disabled]="dependencyForm.invalid || dependencyForm.pristine">
			{{ 'Form.Add' | translate }}
		</button>
	</div>
</div>