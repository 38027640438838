import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import '@luftborn/custom-elements';
import {
	FieldItem,
	FieldType,
} from 'app/models/form-builder/form-definition-types-module';
import { LanguageService } from 'app/services/language.service';

@Pipe({
	name: 'renderItem',
})

// source: https://stackoverflow.com/a/34585513
export class RenderFieldItem implements PipeTransform {
	constructor(private languageService: LanguageService) { }
	transform(value: FieldItem): string {
		return this.renderInput(value);
	}
	renderInput(item: FieldItem): string {
		let str;
		const required = item.required ? 'required' : '';

		const scriptString = `
			<script type="application/json">
				${JSON.stringify(item)}
			</script>
		`;
		const language = this.languageService.getLanguage();

		switch (item.type) {
			case FieldType.text:
				str = `<text-element>${scriptString}</text-element>`;
				break;
			case FieldType.email:
				str = `<email-element>${scriptString}</email-element>`;
				break;
			case FieldType.date:
				str = `<date-element>${scriptString}</date-element>`;
				break;
			case FieldType.number:
				str = `<numeric-element>${scriptString}</numeric-element>`;
				break;
			case FieldType.tel:
				str = `<int-phone-element>${scriptString}</int-phone-element>`;
				break;
			case FieldType.radio:
				str = `<radio-group-element>${scriptString}</radio-group-element>`;
				break;
			case FieldType.checkbox:
				str = `<checkbox-element>${scriptString}</checkbox-element>`;
				break;
			case FieldType.select:
				str = `<drop-down-element>${scriptString}</drop-down-element>`;
				break;
			case FieldType.textarea:
				str = `<text-area-element>${scriptString}</text-area-element>`;
				break;
			case FieldType.customRegEx:
				str = `<regex-element>${scriptString}</regex-element>`;
				break;
			case FieldType.file:
				str = `<file-element>${scriptString}</file-element>`;
				break;
			case FieldType.address:
				str = `<address-element language="${language}">${scriptString}</address-element>`;
				break;
			case FieldType.bank:
				str = `<bank-element language="${language}">${scriptString}</bank-element>`;
				break;
			case FieldType.cpr:
				str = `<cpr-element>${scriptString}</cpr-element>`;
				break;
			case FieldType.cvr:
				str = `<cvr-element>${scriptString}</cvr-element>`;
				break;
			case FieldType.identification:
				str = `<id-element>${scriptString}</id-element>`;
				break;
			case FieldType.typeAhead:
				str = `<type-ahead-element>${scriptString}</type-ahead-element>`;
				break;
			default:
				str = `<text-element>${scriptString}</text-element>`;
				break;
		}
		return str;
	}
}
