<div id="reset-password" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">

    <div id="reset-password-intro" fxFlex fxHide fxShow.gt-xs>

        <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <img src="{{appSettings.ESIGNATUR_LOGO}}">
        </div>

        <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
            {{ 'Common.WelcomeToEsignatur' | translate }}
        </div>

        <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
        </div>

    </div>

    <div id="reset-password-form-wrapper" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div id="reset-password-form">

            <div class="logo" fxHide.gt-xs>
                <img src="{{appSettings.ESIGNATUR_LOGO}}">
            </div>

            <div class="title">{{ 'Common.ResetYourPassword' | translate }}</div>

            <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'Common.Email' | translate }}</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('email').hasError('required')">{{ 'Common.EmailRequired' | translate }}</mat-error>
                    <mat-error *ngIf="resetPasswordForm.get('email').hasError('email')">{{ 'Common.EmailInvalid' | translate }}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'Common.Password' | translate }}</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>{{ 'Common.PasswordRequired' | translate }}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'Common.PasswordConfirm' | translate }}</mat-label>
                    <input matInput type="password" formControlName="passwordConfirm">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').hasError('required')">
                        {{ 'Common.PasswordConfirmRequired' | translate }}
                    </mat-error>
                    <mat-error *ngIf="!resetPasswordForm.get('passwordConfirm').hasError('required') &&
                                       resetPasswordForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                        {{ 'Common.PasswordsMustMatch' | translate }}
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button class="submit-button" color="accent" aria-label="RESET MY PASSWORD"
                    [disabled]="resetPasswordForm.invalid">
                    {{ 'Common.ResetMyPassword' | translate }}
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/pages/auth/login-2'">{{ 'Common.BackToLogin' | translate }}</a>
            </div>

        </div>
    </div>
</div>