import { FieldItem } from './field-item.model';
import { FieldType } from './field-type.enum';

export const FormFields: Array<FieldItem> = [
	new FieldItem(FieldType.address),
	new FieldItem(FieldType.text),
	new FieldItem(FieldType.email),
	new FieldItem(FieldType.date),
	new FieldItem(FieldType.number),
	new FieldItem(FieldType.tel),
	new FieldItem(FieldType.radio),
	new FieldItem(FieldType.checkbox),
	new FieldItem(FieldType.select),
	new FieldItem(FieldType.textarea),
	new FieldItem(FieldType.customRegEx),
	new FieldItem(FieldType.file),
	new FieldItem(FieldType.cpr),
	new FieldItem(FieldType.cvr),
	new FieldItem(FieldType.bank),
	new FieldItem(FieldType.identification),
	new FieldItem(FieldType.typeAhead),
];
export const TypesWithOptions: FieldType[] = [
	FieldType.checkbox,
	FieldType.radio,
	FieldType.select,
];

export const TypesWithOptionsWithDescription: FieldType[] = [
	FieldType.typeAhead
];

export const TypesWithMultipleOptions: FieldType[] = [
	FieldType.file,
	FieldType.select,
];
export const TypesWithRanges: FieldType[] = [FieldType.number, FieldType.date];
export const TypesWithCustomValues: FieldType[] = [FieldType.customRegEx];
export const TypesWithSteps: FieldType[] = [FieldType.number];

export const FieldTypeInputTypeMap = {
	[FieldType.text]: 'text',
	[FieldType.email]: 'email',
	[FieldType.date]: 'date',
	[FieldType.number]: 'number',
	[FieldType.tel]: 'tel',
	[FieldType.textarea]: 'text',
	[FieldType.customRegEx]: 'text',
	[FieldType.typeAhead]: 'text',
};

export const FieldTypeIconMap = {
	[FieldType.address]: 'address-icon.svg',
	[FieldType.text]: 'text-icon.svg',
	[FieldType.email]: 'email-icon.svg',
	[FieldType.date]: 'date-icon.svg',
	[FieldType.number]: 'number-icon.svg',
	[FieldType.tel]: 'tel-icon.svg',
	[FieldType.radio]: 'radio-icon.svg',
	[FieldType.checkbox]: 'checkbox-icon.svg',
	[FieldType.select]: 'select-icon.svg',
	[FieldType.textarea]: 'text-icon.svg',
	[FieldType.customRegEx]: 'regex-icon.svg',
	[FieldType.file]: 'file-icon.svg',
	[FieldType.cvr]: 'cvr-icon.svg',
	[FieldType.cpr]: 'text-icon.svg',
	[FieldType.bank]: 'bank-icon.svg',
	[FieldType.identification]: 'id-icon.svg',
	[FieldType.typeAhead]: 'text-icon.svg',
};
