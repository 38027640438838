import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute } from '@angular/router';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';

@Component({
	selector: 'mail-confirm',
	templateUrl: './mail-confirm.component.html',
	styleUrls: ['./mail-confirm.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations,
})
export class MailConfirmComponent implements OnInit {
	email: string;

	constructor(
		private _fuseConfigService: FuseConfigService,
		private route: ActivatedRoute,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
	) {
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
		
		// Configure the layout
		this._fuseConfigService.config = {
			layout: {
				navbar: {
					hidden: true,
				},
				toolbar: {
					hidden: true,
				},
				footer: {
					hidden: true,
				},
				sidepanel: {
					hidden: true,
				},
			},
		};
	}
	ngOnInit(): void {
		this.email = this.route.snapshot.paramMap.get('email');
	}
}
