import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import OrderModel from 'app/models/order.model';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';
import { OrderService } from 'app/services/order.service';
import { FormSubmissionService } from 'app/services/form-submission.service';
import DocumentModel from 'app/models/document.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BOMUtility } from 'app/utilities/BOMUtility';
import DownloadUtility from 'app/utilities/download-utility';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-order-details',
	templateUrl: './order-details.component.html',
	styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
	private _unsubscribeAll: Subject<any>;

	public numberOfPDFDownloads = 0;
	public numberOfAttachmentsDownloads = 0;
	public attachmentsNamesLoading = true;
	public attachmentsNames: { [key: string]: string };

	constructor(
		@Inject(MAT_DIALOG_DATA) public order: OrderModel,
		public dialogRef: MatDialogRef<OrderDetailsComponent, OrderModel>,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
		private translate: TranslateService,
		private ordersService: OrderService,
		private formSubmissionService: FormSubmissionService,
		private _matSnackBar: MatSnackBar,
	) {
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void {
		this.getAttachementFileNames();
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private getAttachementFileNames(): void {
		this.formSubmissionService.getFormSubmissionFileNames(this.order.formSubmissionId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(response => {
				this.attachmentsNamesLoading = false;
				this.attachmentsNames = response.Result;
			});
	}

	getTranslation(key: string): string {
		return this.translate.instant(key);
	}


	downloadPDF(doc: DocumentModel, $event: Event, download: boolean): void {
		this.numberOfPDFDownloads += 1;
		this.ordersService.downloadFile(this.order.id, doc.filename)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((response) => {
				this.numberOfPDFDownloads -= 1;
				DownloadUtility.downloadBlob(response, doc.filename, download);
			}, (error) => {
				this.displayErrorMessage();
				this.numberOfPDFDownloads -= 1;
			}
			);
	}
	downloadAttachment(fileIndex: number, fileName: string): void {
		this.numberOfAttachmentsDownloads += 1;
		this.formSubmissionService.downloadFile(this.order.formSubmissionId, fileIndex)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((response) => {
				this.numberOfAttachmentsDownloads -= 1;
				DownloadUtility.downloadBlob(response, fileName);
			}, (error) => {
				this.displayErrorMessage();
				this.numberOfAttachmentsDownloads -= 1;
			});
	}

	private displayErrorMessage(): void {
		this._matSnackBar.open(this.getTranslation('TSvariables.AnErrorOccurred'), this.getTranslation('TSvariables.OK'), {
			verticalPosition: 'top',
			duration: 2000,
		});
	}
}
