export const locale = {
	lang: 'da',
	data: {
		'Company': {
			'Company': 'Virksomhed',
		},
		'MyCompany': {
			'EditCompanyInfo': 'Rediger virksomhedsinfo',
			'Name': 'Navn',
			'NameRequired': 'Navn kræves',
			'NameTaken': 'Navn ikke unikt',
			'Website': 'Websted',
			'WebsiteRequired': 'Websted kræves',
			'Phone': 'Telefon',
			'PhoneRequired': 'Telefon kræves',
			'Email': 'E-mail',
			'EmailTaken': 'E-mail ikke unik',
			'ApiKey': 'Virksomhedsnøgle',
			'ApiKeyInvalid': 'Virksomhedsnøgle er ikke gyldig',
			'ApiKeyRequired': 'Virksomhedsnøgle kræves',
			'Language': 'Standardsprog',
			'English': 'Engelsk',
			'Danish': 'Dansk',
			'Address': 'Adresse',
			'AddressRequired': 'Adresse er påkrævet',
			'SaveChanges': 'Gem ændringer',
			'EnableSecureUpload': 'Aktivér sikker upload',
		},
		'List': {
			'AddCompany': 'Tilføj ny virksomhed',
			'Name': 'Navn',
			'Website': 'Websted',
			'Email': 'E-mail',
			'Phone': 'Telefon',
			'Actions': 'Handlinger',
			'ConfirmDelete': 'Er du sikker på, at du vil slette',
			'ThatHaveAPIKey': 'som har en virksomhedsnøgle',
			'WithAllRelatedData': 'med alle relaterede data'
		},
		'Companies': {
			'Companies': 'Virksomheder',
		},
		'Paginator': {
			'ItemsPerPage': 'Virksomheder pr. Side',
		},
		'TSvariables': {
			'Company': 'Selskab',
			'AddNewCompany': 'Tilføj ny virksomhed',
			'EditCompany': 'Rediger virksomhed',
			'Info': 'Info',
			'EditedSuccessfully': 'Redigeret med succes',
			'SomethingWentWrong': 'Der gik noget galt, prøv venligst igen',
			'AddedSuccessfully': 'Tilføjet med succes',
		}
	}
};
