import { MatchingCriteria } from './matching-criteria.enum';

export class Dependency {
	public value = '';
	public items: Array<string>;
	public criteria: MatchingCriteria = MatchingCriteria.exact;

	constructor() {
		this.items = new Array<string>();
	}
}
