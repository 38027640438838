import { environment } from 'environments/environment';
import SecureUploadFormDefinition from 'app/models/form-builder/secure-upload-form-definition.model';

export default class SecureUploadUtilities {
	static getSecureUploadUrl(formId: string): string {
		const formUrl = `${environment.generatorUrl}/secureupload/${formId}`;
		return formUrl;
	}

	static validateImportedForm(formDefinition: SecureUploadFormDefinition): boolean {
		if (formDefinition.fieldGroups && formDefinition.senderFields) {
			return true;
		}
		return false;
	}

	static createCleanFormForExportAndImport(formDefinition: SecureUploadFormDefinition) {
		delete formDefinition.id;
		delete formDefinition.createdAt;
		delete formDefinition.modifiedAt;
		delete formDefinition.author;
		delete formDefinition.companyId;
		delete formDefinition.companyName;
		formDefinition.published = false;
	}

	static downloadForm(formDefinition: SecureUploadFormDefinition): void {
		SecureUploadUtilities.createCleanFormForExportAndImport(formDefinition);
		const blob = new Blob([JSON.stringify(formDefinition)], { type: 'application/json' });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = `${formDefinition.name}.json`;
		link.click();
	}
}
