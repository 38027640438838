<div id="login" fxLayout="column">

	<div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

		<div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

			<div class="logo">
				<img src="{{appSettings.ESIGNATUR_LOGO}}">
			</div>

			<div class="title">{{ 'Login.LoginToYourAccount' | translate }}</div>

			<form name="loginForm" [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit()">

				<mat-form-field appearance="outline">
					<mat-label>{{ 'Login.Username' | translate }}</mat-label>
					<input matInput formControlName="userName">
					<mat-icon matSuffix class="secondary-text">email</mat-icon>
					<mat-error *ngIf="loginForm.get('userName').hasError('required')">
						{{ 'Login.UsernameRequired' | translate }}
					</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>{{ 'Common.Password' | translate }}</mat-label>
					<input matInput type="password" formControlName="password">
					<mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
					<mat-error>
						{{ 'Common.PasswordRequired' | translate }}
					</mat-error>
				</mat-form-field>

				<div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
					fxLayoutAlign="space-between center">
					<mat-checkbox class="remember-me" aria-label="Remember Me">
						{{ 'Login.RememberMe' | translate }}
					</mat-checkbox>

					<a class="forgot-password" [routerLink]="'/auth/forgot-password'">
						{{ 'Login.ForgotPassword' | translate }}?
					</a>
				</div>

				<button mat-raised-button color="accent" class="submit-button" aria-label="LOG IN" type="submit"
					[disabled]="loginForm.invalid || formSubmitted">
					{{ 'Common.Login' | translate }}
				</button>

			</form>
			<div class="text-center">
				<br>
				<app-switch-language></app-switch-language>
			</div>

			<!-- <div class="separator">
				<span class="text">OR</span>
			</div>

			<button mat-raised-button class="google">
				Log in with Google
			</button>

			<button mat-raised-button class="facebook">
				Log in with Facebook
			</button>

			<div class="register" fxLayout="column" fxLayoutAlign="center center">
				<span class="text">Don't have an account?</span>
				<a class="link" [routerLink]="'/pages/auth/register'">Create an account</a>
			</div> -->

		</div>

	</div>
</div>