export const locale = {
	lang: 'en',
	data: {
		'Dialog': {
			'Confirm': 'Confirm',
			'Cancel': 'Cancel',
		},
		'SnackBar':{
			'Dismiss': 'Dismiss'
		}
	}
};
