import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';

@Component({
	selector: 'lock',
	templateUrl: './lock.component.html',
	styleUrls: ['./lock.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations
})
export class LockComponent implements OnInit {
	lockForm: UntypedFormGroup;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
	constructor(
		private _fuseConfigService: FuseConfigService,
		private _formBuilder: UntypedFormBuilder,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService
	) {
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
		
		// Configure the layout
		this._fuseConfigService.config = {
			layout: {
				navbar: {
					hidden: true
				},
				toolbar: {
					hidden: true
				},
				footer: {
					hidden: true
				},
				sidepanel: {
					hidden: true
				}
			}
		};
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
	ngOnInit(): void {
		this.lockForm = this._formBuilder.group({
			username: [
				{
					value: 'Katherine',
					disabled: true
				}, Validators.required
			],
			password: ['', Validators.required]
		});
	}
}
