import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { OptionWithDescription } from 'app/models/form-builder/option-with-description.model';

@Component({
	selector: 'app-edit-option',
	templateUrl: './edit-option.component.html',
	styleUrls: ['./edit-option.component.scss']
})
export class EditOptionComponent implements OnInit {

	dialogTitle: string;
	option: OptionWithDescription;

	constructor(
		public matDialogRef: MatDialogRef<EditOptionComponent>,
		@Inject(MAT_DIALOG_DATA) _data,
		private translate: TranslateService,
	) {
		this.dialogTitle = _data.isAdd ?
			this.translate.instant('EditField.EditOption.AddOptionWithDescription') :
			this.translate.instant('EditField.EditOption.EditOptionWithDescription');
		this.option = new OptionWithDescription({ ..._data.option });
	}

	ngOnInit(): void {
	}

	get optionInvalid() {
		return !this.option.isValid();
	}

	updateTitle(title: string) {
		if (!this.option.value || this.option.value.trim() === '' || this.option.value === this.option.title) {
			this.option.value = title;
		}
		this.option.title = title;
	}

}
