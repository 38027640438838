<div class="page-layout blank p-24" fusePerfectScrollbar>
	<!-- <table-pagination-example></table-pagination-example> -->
	<!-- HEADER -->
	<div class="header accent p-24 h-160" fxLayout="row" fxLayoutAlign="start center">
		<div fxLayout="column" fxLayoutAlign="center start">
			<div fxLayout="row" fxLayoutAlign="start center">
				<mat-icon class="secondary-text s-18">home</mat-icon>
				<mat-icon class="secondary-text s-16">chevron_right</mat-icon>
				<span class="secondary-text">{{ 'Company.Company' | translate }}</span>
			</div>
			<div class="h1 mt-16"></div>
		</div>
	</div>
	<!-- / HEADER -->
	<router-outlet></router-outlet>
</div>