<div id="forgot-password" fxLayout="column">

	<div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

		<div id="forgot-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

			<div class="logo">
				<img src="{{appSettings.ESIGNATUR_LOGO}}">
			</div>

			<div class="title">{{ 'ForgotPassword.RecoverYourPassword' | translate }}</div>

			<form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" novalidate (ngSubmit)="onSubmit()">

				<mat-form-field appearance="outline">
					<mat-label>{{ 'Common.Email' | translate }}</mat-label>
					<input matInput formControlName="email">
					<mat-icon matSuffix class="secondary-text">mail</mat-icon>
					<mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
						{{ 'Common.EmailRequired' | translate }}
					</mat-error>
					<mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
						{{ 'Common.EmailRequired' | translate }}
					</mat-error>
				</mat-form-field>

				<button mat-raised-button class="submit-button" color="accent" aria-label="SEND RESET LINK"
					[disabled]="forgotPasswordForm.invalid">
					{{ 'ForgotPassword.SendResetLink' | translate }}
				</button>
			</form>

			<div class="login" fxLayout="row" fxLayoutAlign="center center">
				<a class="link" [routerLink]="'/auth/login'">{{ 'Common.BackToLogin' | translate }}</a>
			</div>
			<div class="login" fxLayout="row" fxLayoutAlign="center center">
				<app-switch-language></app-switch-language>
			</div>

		</div>

	</div>

</div>