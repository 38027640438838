export class OptionWithDescription {
	title: string;
	value: string;
	description: string;

	constructor(option: Partial<OptionWithDescription>) {
		Object.assign(this, option);
	}

	isValid() {
		if (!this.title || this.title.trim() === '') {
			return false;
		}
		if (!this.value || this.value.trim() === '') {
			return false;
		}
		if (!this.description || this.description.trim() === '') {
			return false;
		}
		return true;
	}
}