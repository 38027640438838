<div class="dialog-content-wrapper">
	<mat-toolbar matDialogTitle class="mat-accent m-0">
		<mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
			<span class="title dialog-title">{{dialogTitle}}</span>
			<button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
				<mat-icon>close</mat-icon>
			</button>
		</mat-toolbar-row>
	</mat-toolbar>

	<div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
		<form [formGroup]="itemForm" fxLayout='column'>
			<mat-form-field class="w-10">
				<input matInput name="label" (change)="updateItemFromItemForm()" formControlName="label"
					placeholder="{{ 'EditField.Label' | translate }}">
				<mat-error *ngIf="itemForm.hasError('required','label')">{{ 'EditField.LabelRequired' | translate }}
				</mat-error>
			</mat-form-field>
			<mat-form-field class="w-10">
				<input matInput name="name" formControlName="name" (change)="updateItemFromItemForm()"
					placeholder="{{ 'EditField.Name' | translate }}">
				<mat-error *ngIf="itemForm.hasError('required','name')">{{ 'EditField.NameRequired' | translate }}
				</mat-error>
				<mat-error *ngIf="itemForm.hasError('notUnique','name')">{{ 'EditField.NameNotUnique' | translate }}
				</mat-error>
				<mat-error *ngIf="itemForm.hasError('hasRestrictedCharacters','name')">{{ 'EditField.NoRestrictedCharacters' | translate }}
				</mat-error>
			</mat-form-field>
			<mat-form-field class="w-10" *ngIf='hasPlaceholder'>
				<input matInput name="placeholder" formControlName="placeholder" (change)="updateItemFromItemForm()"
					placeholder="{{ 'EditField.Placeholder' | translate }}">
			</mat-form-field>
			<mat-form-field class="w-10" *ngIf='hasCustomValue'>
				<input matInput [type]="inputType" formControlName="value" name="Value"
					(change)="updateItemFromItemForm()" placeholder="{{ 'EditField.Value' | translate }}">
				<mat-error *ngIf="itemForm.hasError('required','value')">{{ 'EditField.ValueRequired' | translate }}
				</mat-error>
			</mat-form-field>

			<mat-form-field *ngIf='hasOptions'>
				<mat-chip-list #optionList aria-label="Options selection" cdkDropListGroup>
					<div class="chip-container" cdkDropList *ngFor="let option of item.options; let i = index" [cdkDropListData]="[i]"
						(cdkDropListDropped)="optionDrop($event)" cdkDropListOrientation="horizontal">
						<mat-chip [selectable]="true" [removable]="true"
							(removed)="removeFromList(option, item.options)" cdkDrag>
							<span class="mat-chip-text" (click)="showMatChipEditInput($event)">{{option}}</span>
							<input matInput name="option" value="{{option}}" class="edit-chip-input" 
							(change)="editItemInList($event, option, item.options)" 
							(input)="adjustMatChipInputWidth($event)"
							(keydown.backspace)="$event.stopPropagation()">
							<mat-icon matChipRemove *ngIf="true">cancel
							</mat-icon>
						</mat-chip>
					</div>
					<input placeholder="{{ 'EditField.Options' | translate }}" [matChipInputFor]="optionList"
						[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
						(matChipInputTokenEnd)="addOption($event, item.options)"
						matTooltip="{{ 'EditField.OptionsTooltip' | translate }}">
					<mat-error *ngIf="itemForm.hasError('required','options')">
						{{ 'EditField.OptionsRequired' | translate }}</mat-error>
				</mat-chip-list>
			</mat-form-field>

			<mat-form-field *ngIf='hasOptionsWithDescriptions'>
				<div class="excel-actions">
					<div class="excel-action" (click)="uploadOptionsViaExcel()">
						{{ 'EditField.UploadViaExcel' | translate }}</div>
					<div class=" excel-action" (click)="downloadOptionsExcelTemplate()">
						{{ 'EditField.DownloadExcelTemplate' | translate }}</div>
				</div>
				<mat-chip-list #optionList aria-label="Options selection" cdkDropListGroup>
					<button class="add-typeahead-option" mat-mini-fab color="primary"
						aria-label="add option with description"
						matTooltip="{{ 'EditField.AddOptionWithDescriptionTooltip' | translate }}"
						(click)="addOptionWithDescription($event)">
						<mat-icon>add</mat-icon>
					</button>
					<div class="chip-container" cdkDropList *ngFor="let option of item.optionsWithDescriptions; let i = index" [cdkDropListData]="[i]"
						(cdkDropListDropped)="optionWithDescriptionDrop($event)" cdkDropListOrientation="horizontal">
						<mat-chip [selectable]="true" [removable]="true"
							(removed)="removeFromList(option, item.optionsWithDescriptions)"
							(dblclick)="editOptionWithDescription(option)" cdkDrag>
							{{ option.title }}
							<mat-icon matChipRemove *ngIf="true">cancel
							</mat-icon>
						</mat-chip>
					</div>
					<mat-error *ngIf="optionsWithDescriptionInvalid">
						{{ 'EditField.OptionsRequired' | translate }}</mat-error>
				</mat-chip-list>
			</mat-form-field>

			<mat-slide-toggle *ngIf='item.type === FieldType.address' formControlName="addressAutoComplete" name="addressAutoComplete" (change)="updateItemFromItemForm()">
				{{ 'EditField.EnableAutoComplete' | translate }}
			</mat-slide-toggle>

			<mat-slide-toggle formControlName="required" name="required" (change)="updateItemFromItemForm()">
				{{ 'EditField.Required' | translate }}
			</mat-slide-toggle>
			<mat-slide-toggle formControlName="allDependenciesMustBeMet" name="allDependenciesMustBeMet"
				(change)="updateItemFromItemForm()">
				{{ 'EditField.DependenciesMet' | translate }}</mat-slide-toggle>
			<mat-slide-toggle formControlName="multi" name="multi" (change)="updateItemFromItemForm()"
				*ngIf='hasMultipleValues'>
				{{ 'EditField.Multi' | translate }}</mat-slide-toggle>
			<mat-form-field *ngIf='hasRanges'>
				<input matInput [type]="inputType" name="min" formControlName="min" (change)="updateItemFromItemForm()"
					placeholder="{{ 'EditField.MinValue' | translate }}">
			</mat-form-field>
			<mat-form-field *ngIf='hasRanges'>
				<input matInput [type]="inputType" name="max" formControlName="max" (change)="updateItemFromItemForm()"
					placeholder="{{ 'EditField.MaxValue' | translate }}">
				<mat-error *ngIf="itemForm.hasError('invalid','max')">{{ 'EditField.MaxMinError' | translate }}
				</mat-error>
			</mat-form-field>
			<mat-form-field *ngIf='hasSteps'>
				<input matInput [type]="inputType" name="step" formControlName="step"
					(change)="updateItemFromItemForm()" placeholder="{{ 'EditField.Step' | translate }}">
			</mat-form-field>
		</form>
		<div class="dependencies-container" [formGroup]='dependencyForm'>
			<span class="mat-subheading-1">{{ 'EditField.DependencyList' | translate }}</span>
			<mat-tab-group mat-align-tabs="center">
				<mat-tab label="{{ 'EditField.CustomValue' | translate }}">
					<div class="tab-content p-24" fxLayout='column'>
						<mat-chip-list>
							<mat-chip *ngFor="let d of item.dependencies" (click)="editDependency(d)" color="accent"
								(removed)="removeFromList(d, item.dependencies)">
								{{d.value}}
								<mat-icon matChipRemove *ngIf="true">cancel
								</mat-icon>
							</mat-chip>
						</mat-chip-list>
						<br>
						<mat-radio-group aria-label="Criteria" formControlName="criteria" *ngIf='hasRanges'>
							<mat-radio-button value="{{ 'EditField.Exact' | translate }}">Exact</mat-radio-button>
							<mat-radio-button value="{{ 'EditField.LessThan' | translate }}">Less than
							</mat-radio-button>
							<mat-radio-button value="{{ 'EditField.MoreThan' | translate }}">More than
							</mat-radio-button>
						</mat-radio-group>
						<mat-form-field *ngIf='hasOptions'>
							<mat-label>{{ 'EditField.ChooseValue' | translate }}</mat-label>
							<mat-select formControlName="value">
								<mat-option *ngFor="let option of item.options" [value]="option">{{option}}</mat-option>
							</mat-select>
							<mat-error *ngIf="itemForm.hasError('valueNotUnique','value')">
								{{ 'EditField.ValueNotUnique' | translate }}</mat-error>
						</mat-form-field>
						<mat-form-field class="w-10" *ngIf='!hasOptions'>
							<input matInput [type]="inputType" name="MatchedValue"
								placeholder="{{ 'EditField.MatchedValue' | translate }}" formControlName="value">
							<mat-error *ngIf="dependencyForm.hasError('valueNotUnique','value')">
								{{ 'EditField.ValueNotUnique' | translate }}</mat-error>
						</mat-form-field>
						<mat-form-field>
							<mat-label>{{ 'EditField.DependentFields' | translate }}</mat-label>
							<mat-select multiple formControlName="items">
								<mat-option *ngFor="let field of allFieldsNames(item.name)" [value]="field">
									{{field}}</mat-option>
							</mat-select>
						</mat-form-field>
						<div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
							<button mat-button class="primary w-20-p" (click)="addDependency(item)"
								*ngIf='item.dependencies.indexOf(dependency) === -1'
								[disabled]="dependencyFormInvalid">
								{{ 'Form.Add' | translate }}
							</button>
							<button mat-button class="accent w-20-p" (click)="addDependency(item)"
								*ngIf='item.dependencies.indexOf(dependency) > -1' [disabled]="dependencyFormInvalid">
								{{ 'EditField.Update' | translate }}
							</button>
						</div>
					</div>
				</mat-tab>
				<mat-tab label="{{ 'EditField.HasValue' | translate }}">
					<edit-dependency [item]='item' [hasOptions]='hasOptions' [dependencyForm]='hasValueDependencyForm'
						[allFieldsNames]='allFieldsNames' [edit]='true' [dependency]='item.hasValueDependency'
						(save)='saveHasValueDependency($event)'>
					</edit-dependency>
				</mat-tab>
				<mat-tab label="{{ 'EditField.NoValue' | translate }}">
					<edit-dependency [item]='item' [hasOptions]='hasOptions' [dependencyForm]='noValueDependencyForm'
						[allFieldsNames]='allFieldsNames' [edit]='true' [dependency]='item.noValueDependency'
						(save)='saveNoValueDependency($event)'>
					</edit-dependency>
				</mat-tab>
			</mat-tab-group>
		</div>
	</div>

	<div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
		<button mat-button [disabled]="isSignerField" class="mr-8" (click)="matDialogRef.close(['delete',itemForm])" aria-label="Delete"
			matTooltip="Delete">
			{{ 'EditField.Delete' | translate }}
		</button>
		<button mat-button class="save-button" (click)="matDialogRef.close(['save',itemForm])"
			[disabled]="itemForm.invalid || optionsWithDescriptionInvalid" aria-label="SAVE">
			{{ 'Form.Save' | translate }}
		</button>
	</div>
</div>