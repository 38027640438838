import { FieldType } from './field-type.enum';
import { Dependency } from './dependency.model';
import { OptionWithDescription } from './option-with-description.model';

export class FieldItem {
	static count = 0;
	public tempId: number;
	public label: string;
	public name: string;
	public placeholder: string;
	public value: string;
	public type: FieldType;
	public required = false;
	public allDependenciesMustBeMet = false;
	public multi = false;
	public min: string;
	public max: string;
	public step: string;
	public dateFormat: string;

	public options: Array<string>;
	public optionsWithDescriptions: Array<OptionWithDescription>;
	public addressAutoComplete = true;
	public noValueDependency: Dependency;
	public hasValueDependency: Dependency;
	public dependencies: Array<Dependency>;

	public icon: string;

	constructor(type?: FieldType) {
		this.options = new Array<string>();
		this.optionsWithDescriptions = new Array<OptionWithDescription>();
		this.dependencies = new Array<Dependency>();
		this.noValueDependency = new Dependency();
		this.hasValueDependency = new Dependency();
		if (type >= 0) {
			this.type = type;
		}
	}
}
