import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'safeHtml',
})

// source: https://stackoverflow.com/a/34585513
export class SanitizeHtmlPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}
	transform(value: any): any {
		return this.sanitizer.bypassSecurityTrustHtml(value ?? "");
	}
}
