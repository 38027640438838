import {
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { MakeRequestService } from './app/make-request.service';
import { ResultModel } from '../models/result-model';
import OrderModel from 'app/models/order.model';
import PaginatedModel from 'app/models/paginated-order.model';
import SearchParameters from 'app/models/search-parameters.model';
import { AuthService } from './app/auth.service';
import { CompanyService } from './company.service';

@Injectable({
	providedIn: 'root',
})
export class OrderService {
	searchParameters: SearchParameters = new SearchParameters();
	baseModuleUrl = 'Order/';
	onOrdersChanged: BehaviorSubject<OrderModel[]>;
	routeParams: any;
	order: any;
	onOrderChanged: BehaviorSubject<OrderModel> | any;
	public orders: OrderModel[] = [];
	public totalOrdersCount: number = 0;
	public dataIsLoading: Subject<boolean> = new Subject<boolean>();
	searchParametersChanged: Subject<SearchParameters> = new Subject<SearchParameters>();

	constructor(
		private makeRequest: MakeRequestService,
		private authService: AuthService,
		private companyService: CompanyService,
	) {
		this.onOrdersChanged = new BehaviorSubject([]);
		this.onOrderChanged = new BehaviorSubject(new OrderModel());

		this.searchParametersChanged.subscribe((searchParameters: SearchParameters) => {
			this.searchParameters = searchParameters;
			this.dataIsLoading.next(true);
			this.getAll(this.searchParameters).subscribe((response: any) => {
				this.orders = response.Result.list;
				this.totalOrdersCount = response.Result.total;
				this.onOrdersChanged.next(this.orders);
				this.dataIsLoading.next(false);
			});
		});
	}

	public get(id: string): Observable<ResultModel<OrderModel>> {
		return this.makeRequest.get<OrderModel>(
			`${this.baseModuleUrl}${id}`,
		);
	}

	public getAll(SearchParameters: SearchParameters): Observable<ResultModel<PaginatedModel<OrderModel>>> {
		const { page, size, searchValue, sortValue, sortAscending } = SearchParameters;

		if (this.authService.isCurrentUserSuperAdmin()) {
			const currentOperatedCompamyId = this.companyService.currentOperatedCompany?.id;
			return this.makeRequest.get<PaginatedModel<OrderModel>>(
				`${this.baseModuleUrl}?companyId=${currentOperatedCompamyId}&Page=${page}&Size=${size}&SearchValue=${searchValue}&SortValue=${sortValue}&SortAscending=${sortAscending}`,
				null,
				() => this.dataIsLoading.next(false)
			);
		}

		return this.makeRequest.get<PaginatedModel<OrderModel>>(
			`${this.baseModuleUrl}?Page=${page}&Size=${size}&SearchValue=${searchValue}&SortValue=${sortValue}&SortAscending=${sortAscending}`,
			null,
			() => this.dataIsLoading.next(false),
		);
	}

	public Delete(id: string): Observable<ResultModel<OrderModel>> {
		return this.makeRequest.delete<OrderModel>(
			`${this.baseModuleUrl}${id}`,
		);
	}

	public downloadFile(orderId: string, fileName: string): Observable<Blob>{
		orderId = encodeURIComponent(orderId);
		fileName = encodeURIComponent(fileName);
		return this.makeRequest.getBlob(`${this.baseModuleUrl}DownloadDocument?orderId=${orderId}&fileName=${fileName}`);
	}

	public resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<any> | Promise<any> | any {
		this.routeParams = route.params;

		return new Promise((resolve, reject) => {
			if (this.routeParams.id) {
				this.get(this.routeParams.id).subscribe((response: any) => {
					this.order = response.Result;
					this.onOrderChanged.next(this.order);
					resolve(response);
				}, reject);
			} else {
				this.dataIsLoading.next(true);
				this.getAll(this.searchParameters).subscribe((response: any) => {
					this.orders = response.Result.list;
					this.totalOrdersCount = response.Result.total;
					this.onOrdersChanged.next(this.orders);
					this.dataIsLoading.next(false);
					resolve(response);
				}, reject);
			}
		});
	}
}
