import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from 'app/services/app/auth.service';
import { SnackBarHelper } from '../../../services/helpers/snack-bar.helper';
import { Router } from '@angular/router';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from 'app/app.settings';

@Component({
	selector: 'forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations,
})
export class ForgotPasswordComponent implements OnInit {
	forgotPasswordForm: UntypedFormGroup;

	get appSettings() {
		return AppSettings;
	}

	constructor(
		private _fuseConfigService: FuseConfigService,
		private _formBuilder: UntypedFormBuilder,
		private authService: AuthService,
		public snackBarHelper: SnackBarHelper,
		private router: Router,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
		private translate: TranslateService,
	) {
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
		this.translate.setTranslation('en',english, true);
		this.translate.setTranslation('da',danish, true);
		
		// Configure the layout
		this._fuseConfigService.config = {
			layout: {
				navbar: {
					hidden: true,
				},
				toolbar: {
					hidden: true,
				},
				footer: {
					hidden: true,
				},
				sidepanel: {
					hidden: true,
				},
			},
		};
	}

	ngOnInit(): void {
		this.forgotPasswordForm = this._formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
		});
	}
	onSubmit(): void {
		if (!this.forgotPasswordForm.valid) {
			return;
		}
		const email = this.forgotPasswordForm.controls['email'].value;
		this.authService.ForgetPassword(email).subscribe(res => {
			if (res.Success) {
				this.router.navigate([`auth/mail-confirm/${email}`]);
				return;
			}
			if (res.ResponseCode === 404) {
				this.snackBarHelper.openSnackBar({
					message: `${this.translate.instant(`ForgotPassword.EmailNotFound`)}!`,
				});
				return;
			}
			if (res.ResponseCode === 400) {
				this.snackBarHelper.openSnackBar({
					message: this.translate.instant(`ForgotPassword.EmailCanNotbeEmpty`),
				});
				return;
			}
		});
	}
}
