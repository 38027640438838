import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import DashboardModel from 'app/models/dashboard/dashboardModel';
import { MakeRequestService } from './app/make-request.service';
import { Company } from '../models/company.model';

@Injectable()
export class DashboardService implements Resolve<any> {
	baseModuleUrl = 'dashboard/';

	constructor(private makeRequest: MakeRequestService) {
	}

	public resolve(): Observable<any> | Promise<any> | any {
		return new Promise((resolve, reject) => {
			Promise.all([this.getDashboard()]).then(resolve, reject);
		});
	}

	public getDashboard(companyId?: string): Promise<any> {
		return new Promise((resolve, reject) => {
			companyId = companyId ? `?companyId=${companyId}` : "";
			this.makeRequest.get<DashboardModel>(`${this.baseModuleUrl}${companyId}`)
				.subscribe(res => {
					resolve(res.Result);
				});
		});
	}

	public getCompanies(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.makeRequest.get<Company[]>(`${this.baseModuleUrl}companies`)
				.subscribe(res => {
					resolve(res.Result);
				});
		});
	}
}
