export const locale = {
	lang: 'en',
	data: {
		'Common': {
			'Name': 'Name',
			'NameRequired': 'Name is required',
			'Email': 'Email',
			'EmailRequired': 'Email is required',
			'EmailInvalid': 'Please enter a valid email address',
			'Password': 'Password',
			'PasswordRequired': 'Password is required',
			'PasswordInvalid': 'At least 8 characters in length, Lowercase letters, Numbers',
			'PasswordConfirm': 'Password (Confirm)',
			'PasswordConfirmRequired': 'Password confirmation is required',
			'PasswordsMustMatch': 'Passwords must match',
			
			'ResetMyPassword': 'RESET MY PASSWORD',
			'BackToLogin': 'Go back to login',
			'ResetYourPassword': 'RESET YOUR PASSWORD',
			'WelcomeToEsignatur': 'Welcome To eSignatur',

			'CreateAccount': 'CREATE AN ACCOUNT',
			'IReadAndAccept': 'I read and accept',
			'TermsAndConditions': 'terms and conditions',
			'AlreadyHaveAnAccount': 'Already have an account',
			'Login': 'Login',
		},
		'Mail': {
			'PasswordReset': 'Password reset',
			'MailSentTo': 'An e-mail has been sent to',
			'CheckInbox': 'Check your inbox to reset your password',
			'BackToLogin': 'Go back to login page',
		},
		'Login': {
			'LoginToYourAccount': 'LOGIN TO YOUR ACCOUNT',
			'Username': 'Username',
			'UsernameRequired': 'Username is required',
			'RememberMe': 'Remember Me',
			'ForgotPassword': 'Forgot Password',
			'InvalidUsernameOrPassword': 'Invalid Username or Password',
		},
		'Lock': {
			'SessionLocked': 'YOUR SESSION IS LOCKED',
			'LockMessage': 'Due to inactivity, your session is locked. Enter your password to continue.',
			'UNLOCK': 'UNLOCK',
			'AreYouNot': 'Are you not',
		},
		'ForgotPassword': {
			'RecoverYourPassword': 'RECOVER YOUR PASSWORD',
			'SendResetLink': 'SEND RESET LINK',
			'EmailNotFound': 'email not found',
			'EmailCanNotbeEmpty': 'email can\'t be empty',
		}
	}
};
