export const locale = {
	lang: 'da',
	data: {
		'Common': {
			'Name': 'Navn',
			'NameRequired': 'Navn kræves',
			'Email': 'E-mail',
			'EmailRequired': 'E-mail er påkrævet',
			'EmailInvalid': 'Indtast en gyldig e-mail-adresse',
			'Password': 'Password',
			'PasswordRequired': 'Adgangskode kræves',
			'PasswordInvalid': 'Mindst 8 tegn i længden, små bogstaver, tal',
			'PasswordConfirm': 'Bekræft adgangskode',
			'PasswordConfirmRequired': 'Password-bekræftelse er påkrævet',
			'PasswordsMustMatch': 'Adgangskoder skal matche',
			
			'ResetMyPassword': 'NULSTIL MITT PASSWORD',
			'BackToLogin': 'Gå tilbage til login',
			'ResetYourPassword': 'NULSTIL DIN PASSORD',
			'WelcomeToEsignatur': 'Velkommen til eSignatur',

			'CreateAccount': 'OPRET EN KONTO',
			'IReadAndAccept': 'Jeg læser og accepterer',
			'TermsAndConditions': 'vilkår og betingelser',
			'AlreadyHaveAnAccount': 'Har du allerede en konto',
			'Login': 'Log på',
		},
		'Mail': {
			'PasswordReset': 'Nulstil kodeord',
			'MailSentTo': 'En e-mail er blevet sendt til',
			'CheckInbox': 'Kontroller din indbakke for at nulstille din adgangskode',
			'BackToLogin': 'Gå tilbage til login-siden',
		},
		'Login': {
			'LoginToYourAccount': 'log ind på din konto',
			'Username': 'Brugernavn',
			'UsernameRequired': 'Brugernavn kræves',
			'RememberMe': 'Husk mig',
			'ForgotPassword': 'Glemt adgangskode',
			'InvalidUsernameOrPassword': 'Ugyldigt brugernavn eller kodeord',
		},
		'Lock': {
			'SessionLocked': 'DIN session er låst',
			'LockMessage': 'På grund af inaktivitet er din session låst. Indtast din adgangskode for at fortsætte.',
			'UNLOCK': 'FN-LÅS',
			'AreYouNot': 'Er du ikke',
		},
		'ForgotPassword': {
			'RecoverYourPassword': 'GENTAG DIT PASSORD',
			'SendResetLink': 'SEND ET NULSTILLINGSLINK',
			'EmailNotFound': 'e-mail ikke fundet',
			'EmailCanNotbeEmpty': 'e-mail kan ikke være tom',
		}
	}
};
