<div id="mail-confirm" fxLayout="column">

	<div id="mail-confirm-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

		<div id="mail-confirm-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

			<div class="logo">
				<mat-icon class="mat-accent s-96">email</mat-icon>
			</div>

			<div class="title">{{ 'Mail.PasswordReset' | translate }}!</div>

			<div class="subtitle">
				<p>
						{{ 'Mail.MailSentTo' | translate }} <b>{{email}}</b>.
				</p>
				<p>
					{{ 'Mail.CheckInbox' | translate }}
				</p>
			</div>

			<div class="message">
				<a class="link" [routerLink]="'/auth/login'">{{ 'Mail.BackToLogin' | translate }}</a>
			</div>
			<div class="text-center">
				<br>
				<app-switch-language></app-switch-language>
			</div>

		</div>

	</div>

</div>