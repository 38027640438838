import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBoxDirective } from './search-box/search-box.directive';
import { ClickStopPropagation } from 'app/directive/stopEventPropagation';
import { SwitchLanguageComponent } from './switch-language/switch-language.component';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
	declarations: [
		SearchBoxDirective,
		ClickStopPropagation,
		SwitchLanguageComponent,
	],
	exports: [
		SearchBoxDirective,
		ClickStopPropagation,
		SwitchLanguageComponent,
	],
	imports: [
		CommonModule,
		TranslateModule,
	],
})
export class LuftbornModule { }
