import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';
import { AppSettings } from 'app/app.settings';

@Component({
	selector: 'reset-password-2',
	templateUrl: './reset-password-2.component.html',
	styleUrls: ['./reset-password-2.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations
})
export class ResetPassword2Component implements OnInit, OnDestroy {
	resetPasswordForm: UntypedFormGroup;

	get appSettings () {
		return AppSettings;
	}

	// Private
	private _unsubscribeAll: Subject<any>;

	constructor(
		private _fuseConfigService: FuseConfigService,
		private _formBuilder: UntypedFormBuilder,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService
	) {
		this._fuseTranslationLoaderService.loadTranslations(english, danish);

		// Configure the layout
		this._fuseConfigService.config = {
			layout: {
				navbar: {
					hidden: true
				},
				toolbar: {
					hidden: true
				},
				footer: {
					hidden: true
				},
				sidepanel: {
					hidden: true
				}
			}
		};

		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
	ngOnInit(): void {
		this.resetPasswordForm = this._formBuilder.group({
			name: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
			passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
		});

		// Update the validity of the 'passwordConfirm' field
		// when the 'password' field changes
		this.resetPasswordForm.get('password').valueChanges
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(() => {
				this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
			});
	}

    /**
     * On destroy
     */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

	if (!control.parent || !control) {
		return null;
	}

	const password = control.parent.get('password');
	const passwordConfirm = control.parent.get('passwordConfirm');

	if (!password || !passwordConfirm) {
		return null;
	}

	if (passwordConfirm.value === '') {
		return null;
	}

	if (password.value === passwordConfirm.value) {
		return null;
	}

	return { passwordsNotMatching: true };
};
