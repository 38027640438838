// https://stackoverflow.com/questions/33036487/one-liner-to-flatten-nested-object

export default class ObjectUtility {

	public static flattenObject(obj: any): any {
		const flattened = {};

		for (const key of Object.keys(obj)) {
			if (obj[key] === null || obj[key] === undefined) {
				continue;
			}

			if (typeof obj[key].getMonth === 'function') {
				flattened[key] = (obj[key] as Date).toISOString();
			} else if (typeof obj[key] === 'object' && obj[key] !== null) {
				Object.assign(flattened, this.flattenObject(obj[key]));
			} else {
				flattened[key] = obj[key];
			}
		}
		return flattened;
	}
}
