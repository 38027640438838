export const locale = {
	lang: 'en',
	data: {
		'SAMPLE': {
			'HELLO': 'Hello, World!'
		},
		'Account': {
			'Account': 'Account',
		},
		'Profile': {
			'UserName': 'User Name',
			'UserNameRequired': 'User Name is required',
			'UserNameTaken':'this username is taken by another user',
			'Email': 'Email',
			'EmailRequired': 'Email is required',
			'EmailTaken': 'this email is taken by another user',
			'FirstName': 'First Name',
			'FirstNameRequired': 'First Name is required',
			'LastName': 'Last Name',
			'Phone': 'Phone',
			'PhoneRequired': 'Phone is required',
			'OldPassword': 'Old Password',
			'OldPasswordRequired': 'old password should be filled to change password',
			'InvalidPassword': 'Invalid Password',
			'NewPassword': 'New Password',
			'NewPasswordErr': 'At least 8 characters in length, Lowercase letters, Numbers',
			'ConfirmPassword': 'Confirm New Password',
			'ConfirmPasswordErr': 'Passwords do not match',
			'Save': 'Save changes',
			'ProfileUpdatedSuccessfully': 'profile updated successfully',
			'UpdateProfileFailed': 'Update profile failed',
		},
		'Activate': {
			'AccountInactive': 'YOUR ACCOUNT IS INACTIVE',
			'ActivateYourAccount': 'You need to set your password to activate your account',
			'Password': 'Password',
			'PasswordRequired': 'Please enter your new password',
			'PasswordValidation': 'At least 8 characters in length, Lowercase letters, Numbers',
			'ConfirmPassword': 'Confirm Password',
			'ConfirmPasswordErr': 'Passwords do not match',
			'Save': 'Save password',
			'InvalidActivationToken': 'Invalid activation token',
		}
	}
};
