import { NgModule } from '@angular/core';
import { LoginModule } from './login/login.module';
import { ExtLoginModule } from './ext-login/ext-login.module';
import { RegisterModule } from './register/register.module';
import { Register2Module } from './register-2/register-2.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { MailConfirmModule } from './mail-confirm/mail-confirm.module';
import { LockModule } from './lock/lock.module';
import { ResetPassword2Module } from './reset-password-2/reset-password-2.module';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { ForgotPassword2Module } from './forgot-password-2/forgot-password-2.module';
import { LogoutModule } from './logout/logout.module';

@NgModule({
	imports: [
		LogoutModule,
		LoginModule,
		ExtLoginModule,
		RegisterModule,
		// Register2Module,
		ForgotPasswordModule,
		// ForgotPassword2Module,
		ResetPasswordModule,
		ResetPassword2Module,
		LockModule,
		MailConfirmModule,
	],
})
export class AuthModule { }
