import { Component, OnInit } from '@angular/core';
import {
	Validators,
	UntypedFormBuilder,
	UntypedFormGroup,
	FormGroupDirective,
} from '@angular/forms';
import { CompanyService } from 'app/services/company.service';
import { Company } from 'app/models/company.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ResultModel } from 'app/models/result-model';
import { ValidationHelper } from 'app/services/helpers/validation.helper';
import { SnackBarHelper } from '../../../services/helpers/snack-bar.helper';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';
import { Languages } from 'app/models/Languages';
import { StaticValidation } from 'app/utilities/static-validation';

@Component({
	selector: 'app-edit-company',
	templateUrl: './edit.component.html',
	styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit {
	companyFormGroup: UntypedFormGroup;
	title = '';
	public LanguagesEnum = Languages;
	constructor(
		private companyService: CompanyService,
		private formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		public snackBarHelper: SnackBarHelper,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
		private translate: TranslateService,
	) {
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
		this.translate.setTranslation('en', english, true);
		this.translate.setTranslation('da', danish, true);
	}

	public ngOnInit(): void {
		const id = this.route.snapshot.paramMap.get('id');
		this.companyFormGroup = this.formBuilder.group({
			id: [''],
			name: ['', [Validators.required]],
			website: [''],
			email: ['', [Validators.email]],
			phone: ['', Validators.pattern(new StaticValidation().phoneRegex)],
			address: [''],
			langCode: [Languages.da],
			apiKey: ['', Validators.required],
			secureUploadEnabled: [false],
		});

		this.setTitle(id);
		//This is a workaround as without it, when changing the language, the variables doesn't get updated with the new translation
		this.translate.onLangChange.subscribe((event) => {
			this.setTitle(id);
		});
	}

	private setTitle(id: string): void {
		if (id) {
			this.companyService.get(id).subscribe(res => {
				this.title = `${this.translate.instant('TSvariables.EditCompany')} (${
					res.Result.name
					}) ${this.translate.instant('TSvariables.Info')}`;
				this.companyFormGroup.patchValue(res.Result);
			});
			return;
		}
		this.title = this.translate.instant('TSvariables.AddNewCompany');
		this.companyFormGroup.patchValue({});
	}

	public onSubmit(form: FormGroupDirective): void {
		if (!this.companyFormGroup.valid) {
			return;
		}

		const updatedCompany = new Company(this.companyFormGroup.value);
		if (!updatedCompany.id) {
			this.companyService.Add(updatedCompany).subscribe(res => {
				this.HandleAddResponse(res, form);
			});
		} else {
			this.companyService.Update(updatedCompany).subscribe(res => {
				this.HandleEditResponse(res);
			});
		}
	}
	private HandleEditResponse(res: ResultModel<any>): void {
		if (res.Success) {
			this.snackBarHelper.openSnackBar({
				message: `${this.translate.instant('TSvariables.Company')} (${
					this.companyFormGroup.value['name']
					}) ${this.translate.instant('TSvariables.EditedSuccessfully')}!`,
			});

			this.router.navigate(['companies']);
			return;
		}
		if (res.ResponseCode === 400) {
			ValidationHelper.setFormErrors(res.errors, this.companyFormGroup);
			this.snackBarHelper.openSnackBar({
				message: `${this.translate.instant('TSvariables.SomethingWentWrong')}!`,
			});
			return;
		}
	}

	private HandleAddResponse(
		res: ResultModel<any>,
		form: FormGroupDirective,
	): void {
		if (res.Success) {
			this.snackBarHelper.openSnackBar({
				message: `${this.translate.instant('TSvariables.Company')} (${
					this.companyFormGroup.value['name']
					}) ${this.translate.instant('TSvariables.AddedSuccessfully')}!`,
			});
			this.companyFormGroup.reset();
			form.resetForm();
			return;
		}
		if (res.ResponseCode === 400) {
			ValidationHelper.setFormErrors(res.errors, this.companyFormGroup);
			this.snackBarHelper.openSnackBar({
				message: `${this.translate.instant('TSvariables.SomethingWentWrong')}!`,
			});
			return;
		}
	}
}
