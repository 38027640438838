import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
	providedIn: 'root'
})
export class LanguageService {

	constructor(
		private _translateService: TranslateService,
		private cookieService: CookieService
	) { }

	setLanguage(lang: { id: string }) {
		this._translateService.setDefaultLang(lang.id);
		this._translateService.use(lang.id);
		this.cookieService.set("language", lang.id);
	}

	getLanguage() {
		const language = this.cookieService.get("language");
		return language;
	}
}
