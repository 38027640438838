export const locale = {
	lang: 'en',
	data: {
		'Headers': {
			'Name': 'Name',
			'UserName': 'UserName',
			'Email': 'Email',
			'Phone': 'Phone',
			'CompanyName': 'Company Name',
			'Actions': 'Actions',
			'Add': 'Add new user',
			'Type': 'Type',
			'Company': 'Company',
			'Filter': 'Filter',
			'Users': 'Users', 
		},
		'Buttons': {
			'Back': 'Back',
			'Validate': 'Validate',
			'Save': 'Save changes',
		},
		'Errors':{
			'Required': 'is required!',
			'UserNameTaken': 'UserName taken by another user',
			'EmailTaken': 'email taken by another user',
			'EmailInvalid': 'email is not valid!',
			'PhoneInvalid': 'phone is not valid!',
		},
		'TSvariables': {
			'AddnewUser': 'Add new User',
			'EditUser': 'Edit User',
			'Info': 'Info',
			'User': 'User',
			'EditedSuccessfully': 'Edited Successfully',
			'SomethingWentWrong': 'Something went wrong, please try again',
			'AddedSuccessfully': 'Added Successfully',
			'ValidUserData': 'Valid User Data',
			'InvalidUserData': 'Invalid User Data',
		}
	}
};
