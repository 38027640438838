import { NgModule, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {
	FuseProgressBarModule,
	FuseSidebarModule,
	FuseThemeOptionsModule,
} from '@fuse/components';
import { MatTableModule } from '@angular/material/table';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { UserModule } from 'app/main/user/user.module';
import { AuthModule } from './main/authentication/auth.module';
import { Navigation } from './navigation/navigation';
import { CompanyModule } from './main/company/company.module';
import { MatSharedModule } from './shared-modules/mat-shared/mat-shared.module';
import { LuftbornModule } from './shared-modules/luftborn/luftborn.module';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DashboardModule } from './main/dashboard/dashboard.module';
import { UserAccountService } from './main/account/account.module';
import { OrderModule } from './main/orders/orders.module';

import { FormBuilderModule } from './main/form/form.module';
import { WyswygEditorModule } from './shared-modules/wyswyg-editor/wyswyg-editor.module';
const appRoutes: Routes = [
	{
		path: '**',
		redirectTo: 'dashboard',
	},
];

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		RouterModule.forRoot(appRoutes),
		AuthModule,
		TranslateModule.forRoot(),

		// Material moment date module
		MatMomentDateModule,
		MatTableModule,
		// Material
		MatButtonModule,
		MatIconModule,

		// Fuse modules
		FuseModule.forRoot(fuseConfig),
		FuseProgressBarModule,
		FuseSharedModule,
		FuseSidebarModule,
		FuseThemeOptionsModule,
		// Shared
		MatSharedModule,
		LuftbornModule,
		FlexLayoutModule,
		// App modules
		LayoutModule,
		UserModule,
		UserAccountService,
		CompanyModule,
		DashboardModule,
		FormBuilderModule,
		OrderModule,
		WyswygEditorModule,
	],
	bootstrap: [AppComponent],
	providers: [
		{
			provide: STEPPER_GLOBAL_OPTIONS,
			useValue: { displayDefaultIndicatorType: false, showError: true },
		},
	],
})
export class AppModule {
	constructor(public injector: Injector) {
		Navigation.injector = injector;
	}
}
