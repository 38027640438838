<div id="activate" fxLayout="column">

	<div id="activate-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

		<div id="activate-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

			<div class="activate-form-header" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
				fxLayoutAlign.gt-xs="start center">

				<div class="avatar-container">
					<img class="avatar big" src="assets/images/avatars/profile.jpg">
					<mat-icon class="s-32">Activate</mat-icon>
				</div>

				<div>
					<div class="title">{{ 'Activate.AccountInactive' | translate }}</div>
					<div class="subtitle">
							{{ 'Activate.ActivateYourAccount' | translate }}.
					</div>
				</div>

			</div>

			<form name="activateUser" [formGroup]="activateFormGroup" novalidate (ngSubmit)="onSubmit()">
				<input type="hidden" formControlName="userId">
				<input type="hidden" formControlName="activationToken">
				<mat-form-field appearance="outline">
					<mat-label>{{ 'Activate.Password' | translate }}</mat-label>
					<input matInput formControlName="password" type="password">
					<mat-icon matSuffix class="disabled-text">vpn_key</mat-icon>
					<mat-error *ngIf="activateFormGroup.hasError('required','password')">
						{{ 'Activate.PasswordRequired' | translate }}
					</mat-error>
					<mat-error *ngIf="activateFormGroup.hasError('pattern','password')">
						{{ 'Activate.PasswordValidation' | translate }}
					</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>{{ 'Activate.ConfirmPassword' | translate }}</mat-label>
					<input matInput formControlName="confirmPassword" type="password">
					<mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
					<mat-error *ngIf="activateFormGroup.hasError('notSame')">
						{{ 'Activate.ConfirmPasswordErr' | translate }}
					</mat-error>
				</mat-form-field>
				<button mat-raised-button class="submit-button" color="accent" aria-label="Save password"
					[disabled]="activateFormGroup.invalid">
					{{ 'Activate.Save' | translate }}
				</button>

			</form>
		</div>
	</div>
</div>