import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FieldItem } from 'app/models/form-builder/field-item.model';
import { FieldType } from 'app/models/form-builder/field-type.enum';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Dependency } from 'app/models/form-builder/form-definition-types-module';
import {
	TypesWithOptions,
	TypesWithOptionsWithDescription,
	TypesWithRanges,
	FieldTypeInputTypeMap,
	TypesWithCustomValues,
	TypesWithMultipleOptions,
	TypesWithSteps,
} from 'app/models/form-builder/supported-types.const';
import { CopyUtility } from 'app/utilities/copy-utility';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';
import { TranslateService } from '@ngx-translate/core';
import { EditOptionComponent } from '../edit-option/edit-option.component';
import { OptionWithDescription } from 'app/models/form-builder/option-with-description.model';
import * as	XLSX from 'xlsx';
import { SnackBarHelper } from 'app/services/helpers/snack-bar.helper';

@Component({
	selector: 'edit-field-item',
	templateUrl: './edit-field-item.component.html',
	styleUrls: ['./edit-field-item.component.scss'],
})
export class EditFieldItemComponent implements OnInit {
	readonly typesWithOptions = TypesWithOptions;
	readonly typesWithOptionsWithDescription = TypesWithOptionsWithDescription;
	readonly typesWithRanges: FieldType[] = TypesWithRanges;
	readonly typesWithMultipleOptions: FieldType[] = TypesWithMultipleOptions;
	readonly typesWithCustomValues: FieldType[] = TypesWithCustomValues;
	readonly typesWithSteps: FieldType[] = TypesWithSteps;
	readonly separatorKeysCodes: number[] = [ENTER, COMMA];
	readonly fieldsWithPlaceholder = [
		FieldType.text,
		FieldType.email,
		FieldType.number,
		FieldType.textarea,
		FieldType.customRegEx,
		FieldType.typeAhead,
		FieldType.select,
		FieldType.tel,
	];
	public FieldType = FieldType;

	item: FieldItem;
	itemForm: UntypedFormGroup;
	dialogTitle: string;
	dependency: Dependency;
	dependencyForm: UntypedFormGroup;
	hasValueDependencyForm: UntypedFormGroup;
	noValueDependencyForm: UntypedFormGroup;
	hasOptions = false;
	hasOptionsWithDescriptions = false;
	hasRanges = false;
	hasCustomValue = false;
	hasMultipleValues = false;
	hasSteps = false;
	isSignerField: boolean;

	allFieldsNames: (name: string) => string[];
	createItemForm: (item: FieldItem) => UntypedFormGroup;
	dependencyFormGroupBuilder: (
		item: Dependency,
		other?: Dependency[],
	) => UntypedFormGroup;

	constructor(
		public matDialogRef: MatDialogRef<EditFieldItemComponent>,
		@Inject(MAT_DIALOG_DATA) _data,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
		private translate: TranslateService,
		public _matDialog: MatDialog,
		public snackBarHelper: SnackBarHelper,
	) {
		this.item = new FieldItem();
		CopyUtility.deepCopy(this.item, _data.item);
		this.dialogTitle = `${this.translate.instant('Form.FormFields.' + FieldType[this.item.type])}: ${this.item.label}`;
		this.allFieldsNames = _data.allOtherFieldsNames;
		this.createItemForm = _data.itemFormGroupBuilder;
		this.dependencyFormGroupBuilder = _data.dependencyFormGroupBuilder;
		this.isSignerField = _data.isSignerField;
		this.dependency = new Dependency();
		this.hasOptions = this.typesWithOptions.includes(this.item.type);
		this.hasOptionsWithDescriptions = this.typesWithOptionsWithDescription.includes(this.item.type);
		this.hasRanges = this.typesWithRanges.includes(this.item.type);
		this.hasCustomValue = this.typesWithCustomValues.includes(
			this.item.type,
		);
		this.hasMultipleValues = this.typesWithMultipleOptions.includes(
			this.item.type,
		);
		this.hasSteps = this.typesWithSteps.includes(this.item.type);
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
	}

	ngOnInit(): void {
		this.item.hasValueDependency = this.item.hasValueDependency || new Dependency();
		this.item.noValueDependency = this.item.noValueDependency || new Dependency();
		this.updateItemForm();
		this.initDependencyForm();
	}

	get inputType(): string {
		return FieldTypeInputTypeMap[this.item.type] || 'text';
	}

	get optionsWithDescriptionInvalid() {
		return this.hasOptionsWithDescriptions && this.item.optionsWithDescriptions.length === 0;
	}

	get dependencyFormInvalid() {
		return this.dependencyForm.invalid
				|| !this.dependencyForm.value?.items?.length
				|| !this.dependencyForm.value?.value.trim();
	}

	get hasPlaceholder(): boolean {
		return this.fieldsWithPlaceholder.includes(this.item.type);
	}

	initDependencyForm(): void {
		this.dependencyForm = this.dependencyFormGroupBuilder(
			this.dependency,
			this.item.dependencies.filter(d => d !== this.dependency),
		);
		this.hasValueDependencyForm = this.dependencyFormGroupBuilder(
			this.item.hasValueDependency,
		);
		this.noValueDependencyForm = this.dependencyFormGroupBuilder(
			this.item.noValueDependency,
		);
		this.onChanges();
	}

	addDependency(): void {
		if (!this.dependencyForm.invalid) {
			CopyUtility.deepCopy(this.dependency, this.dependencyForm.value);

			const index = this.item.dependencies.indexOf(this.dependency);
			if (index === -1) {
				this.item.dependencies.push(this.dependency);
			}
			this.dependency = new Dependency();
		}
		this.initDependencyForm();
		this.updateItemForm();
	}

	editDependency(d: Dependency): void {
		this.dependency = d;
		this.initDependencyForm();
		this.updateItemForm();
	}

	saveHasValueDependency(dependency: Dependency): void {
		Object.assign(this.item.hasValueDependency, dependency);
		this.updateItemForm();
	}

	saveNoValueDependency(dependency: Dependency): void {
		Object.assign(this.item.noValueDependency, dependency);
		this.updateItemForm();
	}

	addOption(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;
		if ((value || '').trim()) {
			this.item.options.push(value.trim());
		}
		if (input) {
			input.value = '';
		}
		this.updateItemForm();
	}

	addOptionWithDescription(event: MatChipInputEvent) {
		const addOptionDialogRef = this._matDialog.open(EditOptionComponent, {
			panelClass: 'item-form-dialog',
			data: {
				isAdd: true,
				option: new OptionWithDescription({}),
			}
		});

		addOptionDialogRef.afterClosed().subscribe(response => {
			if (!response) {
				return;
			}
			this.item.optionsWithDescriptions.push(response as OptionWithDescription);
			this.updateItemForm();
		});
	}

	editOptionWithDescription(option: OptionWithDescription) {
		const editOptionDialogRef = this._matDialog.open(EditOptionComponent, {
			panelClass: 'item-form-dialog',
			data: {
				isAdd: false,
				option: option,
			}
		});

		editOptionDialogRef.afterClosed().subscribe(response => {
			if (!response) {
				return;
			}
			Object.assign(option, response);
			this.updateItemForm();
		});
	}

	removeFromList(item: any, list: any[]): void {
		// this.copyItemFieldFormToObject();
		const index = list.indexOf(item);
		if (index >= 0) {
			list.splice(index, 1);
		}

		this.updateItemForm();
	}

	editItemInList(event: any, item: any, list: any[]): void {
		const index = list.indexOf(item);
		if (index >= 0) {
			list[index] = event.target.value;
		}

		this.updateItemForm();
		const span = event.target.previousElementSibling;
		span.style.display = 'inline-block';
		event.target.style.display = 'none';
	}

	showMatChipEditInput(event: any): void {
		event.target.style.display = 'none';
		const input = event.target.nextElementSibling;
		input.style.display = 'inline-block';
		input.style.width = input.value.length + 'ch';
		input.focus();
	}

	adjustMatChipInputWidth(event: any): void {
		const input = event.target;
		input.style.width = input.value.length + 'ch';
	}

	onChanges(): void {
		this.dependencyForm.valueChanges.subscribe(val => {
			CopyUtility.deepCopy(this.dependency, val);
		});
	}

	updateItemForm(): void {
		this.itemForm = this.createItemForm(this.item);
	}

	updateItemFromItemForm(): void {
		Object.assign(this.item, this.itemForm.value);
	}

	optionDrop(event: CdkDragDrop<string[]>): void {
		const newIndex = event.container.data[0];
		const previousIndex = event.previousContainer.data[0];
		moveItemInArray(this.item.options, parseInt(previousIndex), parseInt(newIndex));
		this.updateItemForm();
	}

	optionWithDescriptionDrop(event: CdkDragDrop<string[]>): void {
		const newIndex = event.container.data[0];
		const previousIndex = event.previousContainer.data[0];
		moveItemInArray(this.item.optionsWithDescriptions, parseInt(previousIndex), parseInt(newIndex));
		this.updateItemForm();
	}

	downloadOptionsExcelTemplate() {
		const link = document.createElement('a');
		link.setAttribute('type', 'hidden');
		link.href = 'assets/Excel Templates/Options.xlsx';
		link.download = 'options';
		document.body.appendChild(link);
		link.click();
		link.remove();
	}

	uploadOptionsViaExcel() {
		const fileInput = document.createElement('input');
		fileInput.setAttribute('hidden', 'true');
		fileInput.setAttribute('type', 'file');
		fileInput.setAttribute('accept', '.xlsx, .xls');
		document.body.appendChild(fileInput);
		fileInput.click();
		fileInput.onchange = () => {
			if (fileInput.files && fileInput.files.length) {
				const file = fileInput.files[0];
				const reader = new FileReader();
				reader.readAsArrayBuffer(file);
				let failureCount = 0;
				reader.onload = () => {
					const workBook = XLSX.read(reader.result, { type: 'buffer', raw: true });
					const content = XLSX.utils.sheet_to_json(workBook.Sheets[workBook.SheetNames[0]]);
					content.forEach(contentItem => {
						const option = new OptionWithDescription(contentItem);
						if (!option.isValid()) {
							failureCount++;
							return;
						}
						this.item.optionsWithDescriptions.push(option);
					});
					this.updateItemForm();
					this.snackBarHelper.openSnackBar({
						message: `${this.translate.instant('EditField.Success')}: ${content.length - failureCount} - ${this.translate.instant('EditField.Failure')}: ${failureCount}`,
					});
					fileInput.remove();
				};
			}
		};
	}
}
