import {
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
	ChangeDetectorRef,
	ApplicationRef,
	ViewChild,
	ElementRef,
} from '@angular/core';
import {
	UntypedFormGroup, UntypedFormControl, NgForm, FormGroupDirective,
} from '@angular/forms';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { ValidationHelper } from 'app/services/helpers/validation.helper';

import {
	CdkDragDrop,
	moveItemInArray,
	CdkDragStart,
	CdkDragEnd,
	transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
	FormFields,
	FieldGroup,
	FieldType,
	FieldGroupRow,
	FieldItem,
	Dependency,
} from 'app/models/form-builder/form-definition-types-module';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { EditFieldItemComponent } from '../edit-field-item/edit-field-item.component';
import { MatDialog } from '@angular/material/dialog';
import {
	TypesWithOptions,
	TypesWithCustomValues,
	FieldTypeInputTypeMap,
	FieldTypeIconMap
} from 'app/models/form-builder/supported-types.const';

import { CopyUtility } from 'app/utilities/copy-utility';
import { DomSanitizer } from '@angular/platform-browser';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';
import { Languages } from 'app/models/Languages';
import { SignatureTypes } from 'app/models/form-builder/SignatureTypes';
import { SignerFields } from 'app/models/form-builder/signer-fields.model';
import * as Jsonpatch from 'fast-json-patch';
import { ErrorStateMatcher } from '@angular/material/core';
import { CompanyService } from 'app/services/company.service';
import { AuthService } from 'app/services/app/auth.service';
import { Company } from 'app/models/company.model';
import { AlignmentPosition } from 'app/models/alignment-position-enum';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'app/shared-modules/mat-shared/confirm-dialog/confirm-dialog.component';
import SecureUploadUtilities from 'app/utilities/Forms/secure-upload-utilities';
import SecureUploadFormDefinition from 'app/models/form-builder/secure-upload-form-definition.model';
import { SecureUploadFormFactory } from 'app/services/secure-upload-form-factory';
import { SecureUploadService } from 'app/services/secure-upload.service';
import { UserService } from 'app/services/user.service';
import { User } from 'app/models/user.model';

@Component({
	selector: 'form-builder',
	templateUrl: './secure-upload-form.component.html',
	styleUrls: ['./secure-upload-form.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations,
})

export class SecureUploadFormComponent implements OnInit, OnDestroy {
	readonly separatorKeysCodes: number[] = [ENTER, COMMA];
	readonly typesWithOptions: FieldType[] = TypesWithOptions;
	readonly typesWithCustomValues: FieldType[] = TypesWithCustomValues;
	readonly fieldTypeIconMap: any = FieldTypeIconMap;

	private _unsubscribeAll: Subject<any>;
	private dialogRef: any;
	private counter = 0;

	public tabIndex = 2;
	public SignatureTypesEnum = SignatureTypes;
	public LanguagesEnum = Languages;
	public alignmentPositionEnum = AlignmentPosition;
	public color: any;
	public pageType: string;
	public formFields = FormFields;
	public formDefinition: SecureUploadFormDefinition;
	public originalFormDefinition: SecureUploadFormDefinition;
	public formDefinitionForm: UntypedFormGroup;
	public loaded = false;
	public FieldType = FieldType;
	public isDragging: boolean;
	public matcher = new MyErrorStateMatcher();

	public companies: Company[];
	public users: User[];
	public currentOperatedCompany: Company;
	public isSuperAdmin: boolean;
	public addNotifiedEmailHasError: boolean;
	public addNotifiedAttachmentEmailHasError: boolean;
	public signers: SignerFields[];
	public addButtonClicked = false;

	@ViewChild("documentNameInput", { read: ElementRef }) documentNameInputRef: ElementRef;
	@ViewChild('imageInput') imageInput: ElementRef;

	constructor(
		private secureUploadService: SecureUploadService,
		private secureUploadFormFactory: SecureUploadFormFactory,
		private _location: Location,
		private _matSnackBar: MatSnackBar,
		public _matDialog: MatDialog,
		private cd: ChangeDetectorRef,
		public applicationRef: ApplicationRef,
		public sanitizer: DomSanitizer,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
		private translate: TranslateService,
		private authService: AuthService,
		private companyService: CompanyService,
		private router: Router,
		private userService: UserService
	) {
		this.formDefinition = new SecureUploadFormDefinition();
		this._unsubscribeAll = new Subject();
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
	}

	getTranslation(key: string): string {
		return this.translate.instant(key);
	}

	isSenderField(fieldItemName: string): boolean {
		const senderFields: SignerFields = this.formDefinitionForm.get('senderFields').value;
		return senderFields.email === fieldItemName || senderFields.name === fieldItemName;
	}

	get allListsNames(): string[] {
		const groups = this.formDefinition.fieldGroups;
		const rows = groups.map(g => {
			return g.rows;
		});
		let length: number;
		if (rows.length === 0) {
			length = 0;
		} else {
			length = rows.reduce((a, b) => {
				return a.concat(b);
			}).length;
		}
		const groupsLength = groups.length;
		const rowList = Array.from({ length }).map((x, i) => 'list-' + i);
		const tempLists = Array.from({ length: groupsLength }).map((x, i) => 'list-' + (i + length));
		return rowList.concat(tempLists);
	}

	get allFieldItems(): FieldItem[] {
		if (
			this.formDefinition.fieldGroups &&
			this.formDefinition.fieldGroups.length
		) {
			const items = this.formDefinition.fieldGroups
				.map(g => {
					return g.rows;
				})
				.reduce((a, b) => {
					return a.concat(b);
				})
				.map(g => {
					return g.items;
				})
				.reduce((a, b) => {
					return a.concat(b);
				});
			return items;
		}
		return [];
	}

	get allFieldsNames(): string[] {
		return this.allFieldItems.map(i => i.name);
	}

	get allFieldNamesValidAsEmail(): string[] {
		return this.allFieldItems.filter(i => i.type === FieldType.email || i.type == FieldType.customRegEx).map(i => i.name);
	}

	get formUrl(): string {
		return SecureUploadUtilities.getSecureUploadUrl(this.formDefinition.id);
	}

	get basicInformationInvalid(): boolean {
		const formFields = ['documentName', 'name', 'language', 'dueDateDays'];
		return this.isAnyFromFieldInvalid(formFields);
	}

	get headerAndFooterInvalid(): boolean {
		const formFields = ['color', 'imageDataUrl', 'formNameTextColor', 'hideFormName', 'header', 'footer'];
		return this.isAnyFromFieldInvalid(formFields);
	}

	get formContentInvalid(): boolean {
		const formFields = ['fieldGroups'];
		return this.isAnyFromFieldInvalid(formFields);
	}

	get senderInformationInvalid(): boolean {
		const formFields = ['senderFields'];
		return this.isAnyFromFieldInvalid(formFields);
	}

	get senderInfoName(): string {
		return this.formDefinitionForm.get('senderFields.name').value;
	}

	get senderInfoEmail(): string {
		return this.formDefinitionForm.get('senderFields.email').value;
	}
	
	private isAnyFromFieldInvalid(fields: string[]): boolean {
		const someFieldsAreInvalid = fields.some(f => this.formDefinitionForm.get(f)?.invalid);
		return this.pageType === 'new' ? someFieldsAreInvalid && this.addButtonClicked : someFieldsAreInvalid;
	}

	private createFormDefinitionForm(): void {
		this.formDefinitionForm = this.secureUploadFormFactory.createSecureUploadFormDefinitionForm();
	}

	ngOnInit(): void {
		this.isSuperAdmin = this.authService.isCurrentUserSuperAdmin();
		if (this.isSuperAdmin) {
			this.initFormAsSuperAdmin();
			return;
		}

		this.initForm();
	}

	initFormAsSuperAdmin(): void {
		this.initForm();
		this.currentOperatedCompany = this.companyService.currentOperatedCompany;
		this.companyService.getAll()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(response => {
				this.companies = response.Result;
				const currentOperatedCompanyExists = this.companies.findIndex(
					company => company.id === this.currentOperatedCompany.id
				) !== -1;
				if (!currentOperatedCompanyExists) {
					this.currentOperatedCompany = this.authService.getCurrentUserCompany();
				}
				this.formDefinition.companyId = this.formDefinition.companyId ? this.formDefinition.companyId : this.currentOperatedCompany.id;
				this.formDefinition.companyName = this.formDefinition.companyName ? this.formDefinition.companyName : this.currentOperatedCompany.name;
				this.createFormDefinitionForm();
			})
	}

	initForm(): void {
		this.userService.getAll()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(response => {
				this.users = response.Result.list;
			});

		this.secureUploadService.onFormChanged
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(form => {
				if (form) {
					this.formDefinition = new SecureUploadFormDefinition(form);
					this.pageType = 'edit';
					this.fillDependenciesProperty();
				} else {
					this.pageType = 'new';
					const importForm = localStorage.getItem('importForm');
					if (importForm) {
						try {
							const importFormDefinition = JSON.parse(importForm as string) as SecureUploadFormDefinition;
							CopyUtility.deepCopy(this.formDefinition, importFormDefinition);
						} catch {
							this._matSnackBar.open(
								this.translate.instant('TSvariables.ImportFormError'), 
								this.translate.instant('TSvariables.OK')
							);
						} finally {
							localStorage.removeItem('importForm');
							this.formDefinition = this.formDefinition ? this.formDefinition : new SecureUploadFormDefinition();
						}
					} else {
						this.formDefinition = new SecureUploadFormDefinition();
					}
				}
				this.currentOperatedCompany = this.authService.getCurrentUserCompany();
				this.formDefinition.companyId = this.formDefinition.companyId ? this.formDefinition.companyId : this.currentOperatedCompany.id;
				this.formDefinition.companyName = this.formDefinition.companyName ? this.formDefinition.companyName : this.currentOperatedCompany.name;
				this.secureUploadFormFactory.create(this.formDefinition);
				this.originalFormDefinition = new SecureUploadFormDefinition();
				CopyUtility.deepCopy(this.originalFormDefinition, this.formDefinition);
				this.createFormDefinitionForm();
				this.loaded = true;
			});
	}

	public objectComparisonFunction = function( option, value ) : boolean {
		return option.id === value.id;
	}

	navigateIfFormIsSaved() {
		const formJsonPatch = Jsonpatch.compare(this.originalFormDefinition, this.formDefinitionForm.value);
		const isFormChanged = formJsonPatch.some(
			p => !p.path.includes('/hidden') 
			&& (!p.path.includes('/senderFields') || p.path.includes('name') || p.path.includes('email'))
		);
		const formHasUnsavedChanges = isFormChanged && !(this.pageType === 'new' && !this.formDefinitionForm.dirty);
		if (formHasUnsavedChanges) {
			const dialogRef = this._matDialog.open(ConfirmDialogComponent, {
				data: {
					confirmMessage: `${this.translate.instant('TSvariables.UnsavedChangesConfirmMessage')}`
				},
			});
			dialogRef.afterClosed().subscribe(confirmed => {
				if (confirmed) {
					this.navigateToSecureUploadForms();
				}
			});
		} else {
			this.navigateToSecureUploadForms();
		}
	}

	private navigateToSecureUploadForms() {
		this.router.navigate(['/secure-upload']);
	}

	amendDocumentName(fieldName: string) {
		const element = this.documentNameInputRef.nativeElement;
		const text = `{${fieldName}}`;
		if (element.selectionStart || element.selectionStart === 0) {
			const startPos = element.selectionStart;
			const endPos = element.selectionEnd;
			const scrollTop = element.scrollTop;
			this.formDefinitionForm.controls['documentName'].setValue(element.value.substring(0, startPos) + text + element.value.substring(endPos, element.value.length));
			element.focus();
			element.selectionStart = startPos + text.length;
			element.selectionEnd = startPos + text.length;
			element.scrollTop = scrollTop;
			return;
		}
		this.formDefinitionForm.controls['documentName'].setValue(element.value + text);
		element.focus();
	}

	onFormNameBlur() {
		if (!this.formDefinition.name || this.formDefinition.name.trim() === '') {
			return;
		}
		if (!this.documentNameInputRef.nativeElement.value || this.documentNameInputRef.nativeElement.value.trim() === '') {
			this.formDefinitionForm.controls['documentName'].setValue(this.formDefinition.name);
		}
	}

	designerDrop(event: CdkDragDrop<any[]>): void {
		this.copyFormGroupToModel();
		if (event.previousContainer.id === event.container.id) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else if (event.previousContainer.id !== 'fields-drop-list') {
			transferArrayItem(event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex);
			if (!event.previousContainer.data.length) {
				this.deleteEmptyRows();
			}
		} else {
			// workaround (event.previousIndex is always 0)// https://stackoverflow.com/questions/57123505/angular-cdk-drap-and-drop-not-working-as-expected-during-search-functionality
			const previousIndex = event.previousContainer.data.findIndex(item => item.type === event.item.data.type);
			const template = event.previousContainer.data[previousIndex];
			const newObject: FieldItem = new FieldItem();
			Object.assign(newObject, template);
			newObject.label = this.getTranslation('Form.FormFields.' + FieldType[newObject.type]);
			newObject.name = newObject.label + new Date().getMilliseconds();
			event.container.data.splice(event.currentIndex, 0, newObject);
		}
		this.createFormDefinitionForm();
	}

	private deleteEmptyRows() {
		this.formDefinition.fieldGroups.forEach(g => {
			for (let i = g.rows.length - 1; i >= 0; i--) {
				const emptyRow = !g.rows[i].items.length;
				if (emptyRow) {
					g.rows.splice(i, 1);
				}
			}
		});
	}

	emptyRowDrop(event: CdkDragDrop<any[]>, groupIndex: number): void {
		this.copyFormGroupToModel();
		const lastRow = this.addNewRow(groupIndex) - 1;
		if (event.previousContainer.id !== 'fields-drop-list') {
			const draggedObject: FieldItem = new FieldItem();
			Object.assign(draggedObject, event.previousContainer.data[event.previousIndex]);

			this.formDefinition.fieldGroups[groupIndex].rows[lastRow].items.push(draggedObject);
			transferArrayItem(event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex);

			if (!event.previousContainer.data.length) {
				this.deleteEmptyRows();
			}

			this.createFormDefinitionForm();
		} else {
			// workaround (event.previousIndex is always 0)// https://stackoverflow.com/questions/57123505/angular-cdk-drap-and-drop-not-working-as-expected-during-search-functionality
			const previousIndex = event.previousContainer.data.findIndex(item => item.type === event.item.data.type);
			const template = event.previousContainer.data[previousIndex];
			const newObject: FieldItem = new FieldItem();
			Object.assign(newObject, template);
			newObject.label = this.getTranslation('Form.FormFields.' + FieldType[newObject.type]);
			newObject.name = newObject.label + FieldItem.count++;
			this.formDefinition.fieldGroups[groupIndex].rows[lastRow].items.splice(0, 0, newObject);
			this.createFormDefinitionForm();
		}

	}

	dragStarted(event: CdkDragStart): void {
		this.isDragging = true;
	}

	dragEnded(event: CdkDragEnd): void {
		this.isDragging = false;
	}

	editFieldItem(groupIndex: number, rowIndex: number, itemIndex: number): void {
		const item = this.formDefinition.fieldGroups[groupIndex].rows[rowIndex].items[itemIndex];
		this.dialogRef = this._matDialog.open(EditFieldItemComponent, {
			panelClass: 'item-form-dialog',
			data: {
				item: item,
				allOtherFieldsNames: this.allOtherFieldsNames.bind(this),
				itemFormGroupBuilder: this.secureUploadFormFactory.createFieldItemFormGroup.bind(
					this.secureUploadFormFactory,
				),
				dependencyFormGroupBuilder: this.secureUploadFormFactory.createDependencyFormGroup.bind(
					this.secureUploadFormFactory,
				),
				isSenderField: this.isSenderField(item.name)
			},
		});
		this.dialogRef.afterClosed().subscribe(response => {
			if (!response) {
				return;
			}
			this.applicationRef.tick();
			const actionType: string = response[0];
			const formData: UntypedFormGroup = response[1];
			let oldName = '';
			let newName = '';
			switch (actionType) {
				case 'save':
					oldName = item.name;
					newName = formData.value.name;
					Object.assign(item, formData.value);
					if (oldName !== newName) {
						this.itemNameUpdated(oldName, newName);
					}
					CopyUtility.deepCopy(
						(this.formDefinitionForm.value as SecureUploadFormDefinition).fieldGroups[groupIndex].rows[rowIndex].items[itemIndex],
						this.formDefinition.fieldGroups[groupIndex].rows[rowIndex].items[itemIndex]
					);
					this.copyFormGroupToModel();
					this.createFormDefinitionForm();
					break;
				case 'delete':
					this.removeItemFromRow(groupIndex, rowIndex, itemIndex);
					break;
			}
		});
	}

	itemNameUpdated(oldName: string, newName: string): void {
		const allFields = this.allFieldItems;
		for (const field of allFields) {
			field.dependencies.forEach(d => {
				const index = d.items.indexOf(oldName);
				if (index !== -1) {
					d.items[index] = newName;
				}
			});
			let i = field.noValueDependency.items.indexOf(oldName);
			if (i !== -1) {
				field.noValueDependency.items[i] = newName;
			}
			i = field.hasValueDependency.items.indexOf(oldName);
			if (i !== -1) {
				field.hasValueDependency.items[i] = newName;
			}
		}

		const groups = this.formDefinition.fieldGroups;
		for (const group of groups) {
			if (group.dependentOn == oldName) {
				group.dependentOn = newName;
			}
		}
		this.modifySenderFieldsName(oldName, newName);
	}

	dependentChange(event: MatSelectChange, groupIndex): void {
		CopyUtility.deepCopy(
			this.formDefinition.fieldGroups[groupIndex],
			(this.formDefinitionForm.value as SecureUploadFormDefinition).fieldGroups[
				groupIndex
			]
		);
		this.formDefinitionForm =
			this.secureUploadFormFactory.createSecureUploadFormDefinitionForm();
	}

	private modifySenderFieldsName(oldName: string, newName: string): void {
		const senderFields = this.formDefinition.senderFields;
		for (const field in senderFields) {
			if (senderFields[field] === oldName) {
				senderFields[field] = newName;
			}
		}
	}

	addNewGroup(): void {
		this.copyFormGroupToModel();
		this.formDefinition.fieldGroups.push(new FieldGroup());
		this.createFormDefinitionForm();
	}

	duplicateGroup(fieldGroupIndex: number): void {
		this.copyFormGroupToModel();
		var clonedGroup = new FieldGroup();
		CopyUtility.deepCopy(clonedGroup, this.formDefinition.fieldGroups[fieldGroupIndex]);
		clonedGroup.rows.forEach(row => row.items.forEach(item => this.generateNameFromLabel(item)));
		clonedGroup.title = clonedGroup.title + "_Copy";

		this.formDefinition.fieldGroups.push(clonedGroup);
		this.createFormDefinitionForm();
	}

	moveGroupUp(fieldGroupIndex: number): void {
		this.copyFormGroupToModel();
		const group = this.formDefinition.fieldGroups[fieldGroupIndex];
		const nextGroup = this.formDefinition.fieldGroups.splice(fieldGroupIndex - 1, 1, group);
		this.formDefinition.fieldGroups.splice(fieldGroupIndex, 1, nextGroup.pop());
		this.createFormDefinitionForm();

	}

	moveGroupDown(fieldGroupIndex: number): void {
		this.copyFormGroupToModel();
		var group = this.formDefinition.fieldGroups[fieldGroupIndex];
		var beforeGroup = this.formDefinition.fieldGroups.splice(fieldGroupIndex + 1, 1, group);
		this.formDefinition.fieldGroups.splice(fieldGroupIndex, 1, beforeGroup.pop());
		this.createFormDefinitionForm();

	}

	generateNameFromLabel(fieldItem: FieldItem): void {
		fieldItem.name = fieldItem.label + new Date().getMilliseconds();
	}

	addNewRow(index: number): number {
		this.copyFormGroupToModel();
		this.formDefinition.fieldGroups[index].rows.push(new FieldGroupRow());
		this.createFormDefinitionForm();
		return this.formDefinition.fieldGroups[index].rows.length;
	}

	addOption(event: MatChipInputEvent, optionList: any[]): void {
		this.copyFormGroupToModel();

		const input = event.input;
		const value = event.value;

		if ((value || '').trim()) {
			optionList.push(value.trim());
		}
		if (input) {
			input.value = '';
		}
	}

	addNotifiedEmail($event) {
		if ($event.input.value.trim() === '') {
			this.addNotifiedEmailHasError = false;
			return;
		}
		if (!$event.input.validity.valid) {
			this.addNotifiedEmailHasError = true;
			return;
		}
		this.addNotifiedEmailHasError = false;
		this.formDefinitionForm.controls['notificationEmails'].value.push($event.value.trim());
		$event.input.value = '';
	}

	removeNotifiedEmail(index: number) {
		this.formDefinitionForm.controls['notificationEmails'].value.splice(index, 1);
	}

	addNotifiedAttachmentEmail($event) {
		if ($event.input.value.trim() === '') {
			this.addNotifiedAttachmentEmailHasError = false;
			return;
		}
		if (!$event.input.validity.valid) {
			this.addNotifiedAttachmentEmailHasError = true;
			return;
		}
		this.addNotifiedAttachmentEmailHasError = false;
		this.formDefinitionForm.controls['notificationEmailsWithDocumentAttached'].value.push($event.value.trim());
		$event.input.value = '';
	}

	removeNotifiedAttachmentEmail(index: number) {
		this.formDefinitionForm.controls['notificationEmailsWithDocumentAttached'].value.splice(index, 1);
	}

	getListName(): string {
		if (this.counter >= this.allListsNames.length) {
			this.counter = 0;
		}
		return `list-${this.counter++}`;
	}

	allOtherFieldsNames(itemName: string): string[] {
		return this.allFieldItems.map(i => i.name).filter(s => s !== itemName);
	}

	removeFromList(item: any, list: any[]): void {
		this.copyFormGroupToModel();
		const index = list.indexOf(item);
		if (index >= 0) {
			list.splice(index, 1);
		}
		this.createFormDefinitionForm();
	}

	removeGroup(groupIndex: number) {
		this.copyFormGroupToModel();
		this.formDefinition.fieldGroups.splice(groupIndex, 1);
		this.createFormDefinitionForm();
	}

	removeItemFromRow(groupIndex: number, rowIndex: number, itemIndex: number) {
		this.copyFormGroupToModel();
		this.formDefinition.fieldGroups[groupIndex].rows[rowIndex].items.splice(itemIndex, 1);
		if (this.formDefinition.fieldGroups[groupIndex].rows[rowIndex].items.length === 0 && this.formDefinition.fieldGroups[groupIndex].rows.length > 1) {
			this.formDefinition.fieldGroups[groupIndex].rows.splice(rowIndex, 1);
		}
		this.createFormDefinitionForm();
	}

	fillDependenciesProperty(): void {
		this.allFieldItems.map(
			x => (x.dependencies = x.dependencies || new Array<Dependency>()),
		);
	}

	copyFormGroupToModel(): void {
		if (!this.formDefinitionForm.pristine) {
			CopyUtility.deepCopy(
				this.formDefinition,
				this.formDefinitionForm.value,
			);
		}
	}

	colorChanged(color: any): void {
		this.formDefinitionForm.patchValue({
			color: color,
		});
	}

	formNameTextColorChanged(formNameTextColor: any): void {
		this.formDefinitionForm.patchValue({
			formNameTextColor: formNameTextColor,
		});
	}

	onFileChange(event, field): void {
		if (event.target.files && event.target.files.length) {

			const [file] = event.target.files;
			const allowedFiles = ["image/png", "image/jpg", "image/jpeg", "image/bmp"];
			if (allowedFiles.includes(file.type)) {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					this.formDefinitionForm.patchValue({
						[field]: reader.result,
					});
					CopyUtility.deepCopy(
						this.formDefinition,
						this.formDefinitionForm.value,
					);
				};
				this.cd.markForCheck();
			}
			return;
		}
	}

	hasOptions(selected: any): boolean {
		if (selected && selected.value) {
			const selectedItemType = this.allFieldItems.filter(
				f => f.name === selected.value,
			)[0].type;
			return TypesWithOptions.includes(selectedItemType);
		}
		return false;
	}

	inputType(selected: any): string {
		if (selected && selected.value) {
			const selectedItemType = this.allFieldItems.filter(
				f => f.name === selected.value,
			)[0].type;
			return FieldTypeInputTypeMap[selectedItemType] || 'text';
		}
		return 'text';
	}

	getOptions(selected: any): string[] {
		if (selected && selected.value) {
			const selectedItem = this.allFieldItems.filter(
				f => f.name === selected.value,
			)[0];
			return selectedItem.options;
		}
		return [];
	}

	copyToClipboard(input: HTMLInputElement): void {
		const value = input.value;
		const newInput = document.createElement('input');
		document.documentElement.append(newInput);
		newInput.value = value;
		newInput.select();
		document.execCommand('copy');
		newInput.blur();
		document.documentElement.removeChild(newInput);
	}

	private getFieldIndexByName(fieldName: string): [number, number, number] {
		const groups = this.formDefinitionForm.value.fieldGroups;
		for (let i = 0; i < groups.length; i++) {
			for (let j = 0; j < groups[i].rows.length; j++) {
				for (let k = 0; k < groups[i].rows[j].items.length; k++) {
					if (groups[i].rows[j].items[k].name === fieldName) {
						return [i, j, k];
					}
				}
			}
		}
	}

	private getFieldItemByName(fieldName: string): FieldItem {
		let i, j, k;
		[i, j, k] = this.getFieldIndexByName(fieldName);
		const item: FieldItem = this.formDefinitionForm.value.fieldGroups[i].rows[j].items[k];
		return item;
	}

	private makeSenderFieldsRequired(): void {
		const senderName = this.getFieldItemByName(this.formDefinitionForm.value.senderFields.name);
		const senderEmail = this.getFieldItemByName(this.formDefinitionForm.value.senderFields.email);
		senderEmail.required = true;
		senderName.required = true;

	}

	// workaround for quill formal removed when changing tabs
	// https://stackoverflow.com/questions/54967663/quilljs-merges-subsequent-p-tags-into-one-with-angular-7-ngx-quill
	changeTabIndex(index) {
		this.tabIndex = index;
	}

	saveForm(): void {
		this.checkFormIsValid();

		this.makeSenderFieldsRequired();
		const data: SecureUploadFormDefinition = this.formDefinitionForm.value;
		const formJsonPatch = Jsonpatch.compare(this.originalFormDefinition, data)
			.filter(patch => !patch.path.includes('/hidden'))
			.filter(patch => !patch.path.includes('/senderFields/') || ['/senderFields/name', '/senderFields/email'].includes(patch.path));

		this.secureUploadService.patchUpdate(this.originalFormDefinition.id, formJsonPatch).subscribe(res => {
			if (res.Success) {
				CopyUtility.deepCopy(this.originalFormDefinition, res.Result);
				this.secureUploadService.onFormChanged.next(res.Result);
				// Show the success message
				this._matSnackBar.open(this.getTranslation('TSvariables.Formsaved'), this.getTranslation('TSvariables.OK'), {
					verticalPosition: 'top',
					duration: 2000,
				});
			} else {
				ValidationHelper.setFormErrors(res.errors, this.formDefinitionForm);
				this._matSnackBar.open(this.getTranslation('TSvariables.AnErrorOccurred'), this.getTranslation('TSvariables.OK'), {
					verticalPosition: 'top',
					duration: 2000,
				});
			}
		});
	}

	private checkFormIsValid(): void {
		if (this.formDefinitionForm.invalid) {
			this.formDefinitionForm.markAllAsTouched();
			this._matSnackBar.open(this.getTranslation('Form.SomethingIsMissing'), this.getTranslation('TSvariables.OK'), {
				verticalPosition: 'top',
				duration: 3000,
			});
			return;
		}
	}

	deleteLogoImage(): void {
		this.formDefinitionForm.controls['imageDataUrl'].setValue(null);
		this.imageInput.nativeElement.value = '';
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		return control.invalid;
	}
}
