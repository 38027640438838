import {
	Component,
	ElementRef,
	Input,
	Renderer2,
	ViewEncapsulation,
	OnInit,
} from '@angular/core';
import { LocalStorageHelper } from 'app/services/helpers/local-storage.helper';
import { User } from 'app/models/user.model';

@Component({
	selector: 'navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit {
	_variant: string;
	CurrentUser: User;

	constructor(
		private localStorageHelper: LocalStorageHelper,
		private _elementRef: ElementRef,
		private _renderer: Renderer2,
	) {
		// Set the private defaults
		this._variant = 'vertical-style-1';
	}
	ngOnInit(): void {
		this.CurrentUser = this.localStorageHelper.Get<User>(
			LocalStorageHelper.currentUserFieldName,
		);
	}
	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Variant
	 */
	get variant(): string {
		return this._variant;
	}

	@Input()
	set variant(value: string) {
		// Remove the old class name
		this._renderer.removeClass(
			this._elementRef.nativeElement,
			this.variant,
		);

		// Store the variant value
		this._variant = value;

		// Add the new class name
		this._renderer.addClass(this._elementRef.nativeElement, value);
	}
}
