<div id="reset-password" fxLayout="column">

	<div id="reset-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

		<div id="reset-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

			<div class="logo">
				<img src="{{appSettings.ESIGNATUR_LOGO}}">
			</div>

			<div class="title">{{ 'Common.ResetYourPassword' | translate }}</div>

			<form name="resetPasswordForm" [formGroup]="resetPasswordForm" (ngSubmit)="submit()" novalidate>
				<input type="hidden" formControlName="userId">
				<mat-form-field appearance="outline">
					<mat-label>{{ 'Common.Email' | translate }}</mat-label>
					<input matInput formControlName="email">
					<mat-icon matSuffix class="secondary-text">mail</mat-icon>
					<mat-error *ngIf="resetPasswordForm.get('email').hasError('required')">{{ 'Common.EmailRequired' | translate }}</mat-error>
					<mat-error *ngIf="resetPasswordForm.get('email').hasError('email')">{{ 'Common.EmailInvalid' | translate }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>{{ 'Common.Password' | translate }}</mat-label>
					<input matInput #password type="password" formControlName="password">
					<mat-hint align="end">{{password.value.length}} / 8</mat-hint>
					<mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
					<mat-error *ngIf="resetPasswordForm.get('password').hasError('required')">{{ 'Common.PasswordRequired' | translate }}</mat-error>
					<mat-error *ngIf="resetPasswordForm.get('password').hasError('pattern')">{{ 'Common.PasswordInvalid' | translate }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>{{ 'Common.PasswordConfirm' | translate }}</mat-label>
					<input matInput type="password" formControlName="passwordConfirm">
					<mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
					<mat-error *ngIf="resetPasswordForm.get('passwordConfirm').hasError('required')">
						{{ 'Common.PasswordConfirmRequired' | translate }}
					</mat-error>
					<mat-error *ngIf="!resetPasswordForm.get('passwordConfirm').hasError('required') &&
                                       resetPasswordForm.get('passwordConfirm').hasError('passwordsNotMatching')">
						{{ 'Common.PasswordsMustMatch' | translate }}
					</mat-error>
				</mat-form-field>

				<button mat-raised-button class="submit-button" color="accent" aria-label="RESET MY PASSWORD"
					[disabled]="resetPasswordForm.invalid">
					{{ 'Common.ResetMyPassword' | translate }}
				</button>
			</form>

			<div class="login" fxLayout="row" fxLayoutAlign="center center">
				<a class="link" [routerLink]="'/pages/auth/login'">{{ 'Common.BackToLogin' | translate }}</a>
			</div>
			<div class="text-center">
				<br>
				<app-switch-language></app-switch-language>
			</div>

		</div>

	</div>

</div>