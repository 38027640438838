import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import ExportFormsDataRequest from 'app/models/form-submission/export-forms-data-request.model';
import { FormSubmissionService } from 'app/services/form-submission.service';
import ExcelUtility from 'app/utilities/excel-utility';

@Component({
	selector: 'app-export-forms-data',
	templateUrl: './export-forms-data.component.html',
	styleUrls: ['./export-forms-data.component.scss']
})
export class ExportFormsDataComponent implements OnInit {

	exportFormGroup: UntypedFormGroup;
	isLoading = false;

	constructor(
		public matDialogRef: MatDialogRef<ExportFormsDataComponent>,
		private formSubmissionService: FormSubmissionService,
		private formBuilder: UntypedFormBuilder
	) { }

	ngOnInit(): void {
		this.exportFormGroup = this.formBuilder.group({
			startDate: ['', [Validators.required]],
			endDate: ['', [Validators.required]]
		});
	}

	export() {
		this.isLoading = true;
		const exportFormsDataRequest = new ExportFormsDataRequest(this.exportFormGroup.value)
		this.formSubmissionService.exportData(exportFormsDataRequest).subscribe(response => {
			this.isLoading = false;
			const exportFormsDataResponse = response.Result;
			ExcelUtility.WriteObjectToExcel(exportFormsDataResponse, 'forms data', 'forms export.xlsx');
		});
	}

}
