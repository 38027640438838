import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageHelper {
	static accessTokenFieldName = 'accessToken';
	static currentUserFieldName = 'currentUser';

	constructor() { }

	public Get<T>(fieldName: string): T {
		const tokenRaw = this.GetRaw(fieldName);
		if (tokenRaw != null) {
			return JSON.parse(tokenRaw);
		}
		return {} as T;
	}

	public Set<T>(fieldName: string, obj: T): void {
		const rawString = JSON.stringify(obj);
		this.SetRaw(fieldName, rawString);
	}

	public Clear(fieldName: string): void {
		localStorage.removeItem(fieldName);
	}

	private GetRaw(fieldName: string): string {
		return localStorage.getItem(fieldName);
	}

	private SetRaw(fieldName: string, rawString: string): void {
		localStorage.setItem(fieldName, rawString);
	}
}
