import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { FuseSharedModule } from '@fuse/shared.module';

import { LoginComponent } from '../login/login.component';
import { TranslateModule } from '@ngx-translate/core';
import { LuftbornModule } from 'app/shared-modules/luftborn/luftborn.module';

const routes = [
	{
		path: 'auth/login',
		component: LoginComponent
	}
];

@NgModule({
	declarations: [
		LoginComponent
	],
	imports: [
		RouterModule.forChild(routes),

		MatButtonModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,

		FuseSharedModule,
		TranslateModule,
		LuftbornModule,
	]
})
export class LoginModule {
}
