<div id="form" class="page-layout carded fullwidth inner-scroll">
	<!-- TOP BACKGROUND -->
	<div class="top-bg accent"></div>
	<!-- / TOP BACKGROUND -->

	<div class="center">
		<!-- CONTENT HEADER -->
		<div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between start">
			<div class="m-0">
				<button mat-icon-button class="mr-0 mr-sm-16" (click)="navigateIfFormIsSaved()">
					<mat-icon>arrow_back</mat-icon>
				</button>
				<div fxLayout="column" fxLayoutAlign="start start"
					[@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
					<div class="h2" *ngIf="pageType ==='edit'">
						{{formDefinition.name}}
					</div>
					<div class="h2" *ngIf="pageType ==='new'">
						{{formDefinition.name || 'Form.NewForm' | translate }}
					</div>
					<div class="subtitle secondary-text">
						<span>{{ 'Form.FormDetail' | translate }}</span>
					</div>
				</div>
			</div>
			<div class="m-0">
				<button mat-raised-button class="save-form-button" [disabled]="formDefinitionForm.invalid"
					*ngIf="pageType ==='edit'" (click)="saveForm()">
					<span>{{ 'Form.Save' | translate }}</span>
				</button>
			</div>
		</div>
		<!-- / CONTENT HEADER -->
		<div class="content-card">
			<div class="content p-0" fusePerfectScrollbar>
				<mat-tab-group [selectedIndex]="tabIndex" [color]="'primary'" [backgroundColor]="'accent'"
					style="height: 100%" (selectedIndexChange)="changeTabIndex($event)">
					<mat-tab>
						<ng-template mat-tab-label>
							<mat-icon class="secondary-text mat-icon-red"
								*ngIf="basicInformationInvalid"
							>error_outline</mat-icon>
							<span [class.error]="basicInformationInvalid">
								{{ 'Form.BasicInformation' | translate }}
							</span>
						</ng-template>
						<form name="formDefinitionForm" [formGroup]="formDefinitionForm">
							<div class="tab-content p-24 h-100-p">
								<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
									<mat-label>{{ 'Form.FormName' | translate }}</mat-label>
									<input matInput placeholder="{{ 'Form.FormName' | translate }}" name="name"
										formControlName="name" required (blur)="onFormNameBlur()">
									<mat-error *ngIf="formDefinitionForm.hasError('required','name')">
										{{ 'Form.NameRequired' | translate }}!</mat-error>
									<mat-error *ngIf="formDefinitionForm.hasError('notUnique','name')">
										{{ 'Form.NameTaken' | translate }}</mat-error>
								</mat-form-field>

								<div class="mb-4" tabindex="-1">
									<mat-chip class="ml-4 mr-4" tabindex="-1" *ngFor="let fieldName of allFieldsNames"
										(click)="amendDocumentName(fieldName)">
										{{ fieldName }}</mat-chip>
								</div>
								<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
									<mat-label>{{ 'Form.DocumentName' | translate }}</mat-label>
									<input #documentNameInput matInput spellcheck="false"
										placeholder="{{ 'Form.DocumentName' | translate }}" name="documentName"
										formControlName="documentName" required>
									<mat-error *ngIf="formDefinitionForm.hasError('required','documentName')">
										{{ 'Form.DocumentNameRequired' | translate }}!</mat-error>
								</mat-form-field>

								<mat-form-field *ngIf="pageType ==='edit'" appearance="outline" floatLabel="always"
									class="w-100-p">
									<mat-label>{{ 'Form.FormLink' | translate }}</mat-label>
									<input matInput type="text" placeholder="" [value]="formUrl" #formUrlInput disabled>
									<button mat-button matSuffix mat-icon-button aria-label="Copy"
										(click)="copyToClipboard(formUrlInput)">
										<mat-icon>file_copy</mat-icon>
									</button>
								</mat-form-field>

								<mat-form-field *ngIf="isSuperAdmin" appearance="outline" class="w-100-p">
									<mat-label>{{ 'Forms.Company' | translate }}</mat-label>
									<mat-select formControlName="companyId">
										<mat-option *ngFor="let company of companies" [value]="company.id">
											{{ company.name }}
										</mat-option>
									</mat-select>
								</mat-form-field>

								<mat-form-field appearance="outline" class="w-100-p">
									<mat-label>{{ 'Form.Language' | translate }}</mat-label>
									<mat-select formControlName="language" required>
										<mat-option [value]="LanguagesEnum.en">
											{{ 'Form.English' | translate }}
										</mat-option>
										<mat-option [value]="LanguagesEnum.da">
											{{ 'Form.Danish' | translate }}
										</mat-option>
									</mat-select>
									<mat-error *ngIf="formDefinitionForm.controls['language'].hasError('required')">
										{{ 'Form.LanguageRequired' | translate }}
									</mat-error>
								</mat-form-field>

								<mat-form-field appearance="outline" class="w-100-p">
									<mat-label>{{ 'Form.Author' | translate }}</mat-label>
									<mat-select formControlName="author" required [compareWith]="objectComparisonFunction">
									  <mat-option *ngFor="let user of users" [value]="user">
										{{ user.name }}
									  </mat-option>
									</mat-select>
									<mat-error *ngIf="formDefinitionForm.get('author').hasError('required')">
									  {{ 'Form.AuthorRequired' | translate }}
									</mat-error>
								</mat-form-field>

								<mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
									<mat-label>{{ 'Form.CompanyUrl' | translate }}</mat-label>
									<input matInput placeholder="{{ 'Form.CompanyUrl' | translate }}"
										name="companyUrl" formControlName="companyUrl"
										[errorStateMatcher]="matcher" />
									<mat-error *ngIf="formDefinitionForm.controls['companyUrl'].hasError('eSignCred')">
										{{ 'Form.CompanyUrlInvalid' | translate }}
									</mat-error>
								</mat-form-field>
							</div>
						</form>
					</mat-tab>
					<mat-tab>
						<ng-template mat-tab-label>
							<mat-icon class="secondary-text mat-icon-red"
								*ngIf="headerAndFooterInvalid"
							>error_outline</mat-icon>
							<span [class.error]="headerAndFooterInvalid">
								{{ 'Form.HeaderAndFooter' | translate }}
							</span>
						</ng-template>
						<form name="formDefinitionForm" [formGroup]="formDefinitionForm">
							<input hidden formControlName="createdAt">
							<input hidden formControlName="modifiedAt">

							<div class="tab-content p-24" fusePerfectScrollbar>
								<div class="file-upload w-100-p my-12">

									<input type="file" #imageInput (change)="onFileChange($event, 'imageDataUrl')"
										style="display: none" accept=".png,.jpg,.jpeg,.bmp" />
									<button mat-fab color="primary" class="add-file-button" title="choose image"
										(click)="imageInput.click()" aria-label="Add file"
										[@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
										<mat-icon>image</mat-icon>
									</button>
									<div *ngIf="formDefinitionForm.value.imageDataUrl" class="form-logo">
										<img
											[src]="sanitizer.bypassSecurityTrustResourceUrl(formDefinitionForm.value.imageDataUrl)"
											alt="form image">
										<button type="button" class="delete-image-button" title="delete image"
											(click)="deleteLogoImage()">
											<mat-icon>delete_outlined</mat-icon>
										</button>
									</div>
								</div>
								<div>
									<mat-radio-group class="alignment-radio-group" formControlName="imagePosition">
										<mat-radio-button [value]="alignmentPositionEnum.left">
											{{ 'Form.Left' | translate }}
										</mat-radio-button>
										<mat-radio-button [value]="alignmentPositionEnum.center">
											{{ 'Form.Center' | translate }}
										</mat-radio-button>
										<mat-radio-button [value]="alignmentPositionEnum.right">
											{{ 'Form.Right' | translate }}
										</mat-radio-button>
									</mat-radio-group>
								</div>
								<div>
									<mat-form-field appearance="outline" class="w-50-p my-12">
										<mat-label>{{ 'Form.FormHeaderBackgroundColor' | translate }}</mat-label>
										<input matInput [(colorPicker)]="formDefinition.color" [style.backgroundColor]="formDefinition.color"
											[cpHeight]="'auto'" formControlName="color" name="color"
											(colorPickerChange)="colorChanged($event)" />
									</mat-form-field>
								</div>
								<div>
									<mat-form-field appearance="outline" class="w-50-p my-12">
										<mat-label>{{ 'Form.FormHeaderTextColor' | translate }}</mat-label>
										<input matInput [(colorPicker)]="formDefinition.formNameTextColor" [style.backgroundColor]="formDefinition.formNameTextColor"
											[cpHeight]="'auto'" formControlName="formNameTextColor" name="formNameTextColor"
											(colorPickerChange)="formNameTextColorChanged($event)" />
									</mat-form-field>
								</div>
								<div>
									<mat-slide-toggle color="primary" formControlName="hideFormName">
										{{ 'Form.HideFormName' | translate }}
									</mat-slide-toggle>
								</div>
								<div class="my-12">
									<div class="section-head">
										<div class="head-title">{{ 'Form.Header' | translate }}</div>
									</div>
									<app-ngx-quill-wrapper *ngIf="tabIndex==1" [formControl]="formDefinitionForm.controls['header']">
									</app-ngx-quill-wrapper>
								</div>
								<div class="my-12">
									<div class="section-head">
										<div class="head-title">{{ 'Form.Footer' | translate }}</div>
									</div>
									<app-ngx-quill-wrapper *ngIf="tabIndex==1" [formControl]="formDefinitionForm.controls['footer']">
									</app-ngx-quill-wrapper>
								</div>

							</div>
						</form>
					</mat-tab>
					<mat-tab>
						<ng-template mat-tab-label>
							<mat-icon class="secondary-text mat-icon-red"
								*ngIf="formContentInvalid"
							>error_outline</mat-icon>
							<span [class.error]="formContentInvalid">
								{{ 'Form.FormContent' | translate }}
							</span>
						</ng-template>
						<!-- CONTENT -->
						<div class="content" fxLayout="row">
							<fuse-sidebar id="fields-nav" class="sidebar scrollable-container" position="left"
								lockedOpen="gt-sm">
								<div class="field-container">
									<h2 class="headline-3">{{ 'Form.Fields' | translate }}</h2>
									<div cdkDropList cdkDropListSortingDisabled #itemList="cdkDropList"
										[cdkDropListData]="formFields" [cdkDropListConnectedTo]="allListsNames"
										class="example-list fields-list" id="fields-drop-list">
										<div class="item-box" *ngFor="let item of formFields" cdkDrag
											[cdkDragData]="item" (cdkDragStarted)="dragStarted($event)"
											(cdkDragEnded)="dragEnded($event)">
											<div class="field-box ">
												<img [src]="'/assets/icons/fields-icons/'+fieldTypeIconMap[item.type]">
												<span>
													{{ ('Form.FormFields.' + (FieldType[item.type])) | translate }}
												</span>
												<div class="placeholder" *cdkDragPlaceholder></div>
												<div class="p-16" *cdkDragPreview>
													<div fxLayout flexLayout="row">
														<span>
															{{ ('Form.FormFields.' + (FieldType[item.type])) | translate }}
														</span>
														<span style="color: red">*</span>
														<div class="btn-icons">
															<span>
																<img src="/assets/icons/custom-icons/gear.svg">
															</span>
															<span>
																<img src="/assets/icons/custom-icons/trash.svg">
															</span>
														</div>
													</div>
													<input type="text" style="width:50%">
												</div>
											</div>
										</div>
									</div>
									<!-- <h2 class="headline-3">{{ 'Form.AdvancedFields' | translate }}</h2> -->
								</div>
								<!-- / SIDEBAR CONTENT -->
							</fuse-sidebar>
							<div id="form-content-layout" class="center p-24 pr-sm-92 w-100-p" cdkScrollable>
								<!-- CONTENT -->
								<div class="content inner-scroll designer-container p-16">
									<form name="formDefinitionForm" [formGroup]="formDefinitionForm">
										<div class="group-box" formArrayName="fieldGroups"
											*ngFor="let group of formDefinitionForm['controls']['fieldGroups']['controls']; let i = index">
											<div [formGroupName]="i">
												<div class="section-head">
													<div class="head-title" [class.error]="group.invalid">
														<span>
															<div class="btn-icons">
																<span (click)="moveGroupUp(i)" title="Move up">
																	<mat-icon class="mat-icon-order"> keyboard_arrow_up </mat-icon>
																</span>
															</div>
															<div class="btn-icons">
																<span (click)="moveGroupDown(i)" title="Move down">
																	<mat-icon class="mat-icon-order"> keyboard_arrow_down </mat-icon>
																</span>
															</div>
														</span>
														<span class="center">
														{{group.controls['title'].value}}
														</span>
														<div class="btn-icons">
															<span (click)="removeGroup(i)" title="remove">
																<img src="/assets/icons/custom-icons/trash.svg">
															</span>
															<span (click)="duplicateGroup(i)" title="duplicate">
																<mat-icon class="mat-icon-order">file_copy</mat-icon>
															</span>
														</div>
													</div>
													<app-ngx-quill-wrapper *ngIf="tabIndex==2"
														[formControl]="group.controls['description']">
													</app-ngx-quill-wrapper>
												</div>
												<div class="group-content">
													<div fxLayout="column" fxLayoutAlign="start start"
														class="group-content-left">
														<mat-form-field class="w-100-p"
															[class.error]="group.controls['title'].hasError('required')">
															<input matInput placeholder="{{ 'Form.Grouptitle' | translate }}"
																[errorStateMatcher]="matcher" formControlName="title"
																name="title" required>
														</mat-form-field>
														<mat-form-field class="w-100-p">
															<mat-label>{{ 'Form.DependentOn' | translate }}</mat-label>
															<mat-select (selectionChange)="dependentChange($event, i)" class="w-100-p" formControlName="dependentOn"
																#dependent>
																<mat-option [value]="''">
																	--{{ 'Form.NotDependent' | translate }}--
																</mat-option>
																<mat-option *ngFor="let name of allFieldsNames"
																	[value]="name">
																	{{name}}
																</mat-option>
															</mat-select>
														</mat-form-field>
														<mat-form-field class="w-100-p"
															*ngIf='hasOptions(dependent.selected)'>
															<mat-label>{{ 'Form.ChooseAValue' | translate }}</mat-label>
															<mat-select matInput class="w-100-p"
																formControlName="dependentValue">
																<mat-option
																	*ngFor="let option of getOptions(dependent.selected)"
																	[value]="option">{{option}}
																</mat-option>
															</mat-select>
														</mat-form-field>
														<mat-form-field class="w-100-p"
															*ngIf='!hasOptions(dependent.selected)'>
															<mat-label>{{ 'Form.ChooseAValue' | translate }}</mat-label>
															<input matInput class="w-100-p"
																[type]="inputType(dependent.selected)"
																name="MatchedValue" placeholder="{{ 'Form.MatchedValue' | translate }}"
																formControlName="dependentValue">
														</mat-form-field>
													</div>
													<div class="group-content-right" formArrayName="rows">
														<div cdkDropListGroup 
															*ngFor="let row of group.controls['rows'].controls; let j = index"
															[formGroupName]="j">
															<div [id]='getListName()' cdkDropList
																[cdkDropListData]="formDefinition.fieldGroups[i].rows[j].items"
																[cdkDropListConnectedTo]="allListsNames.concat([itemList])"
																(cdkDropListDropped)="designerDrop($event)"
																cdkDropListOrientation="horizontal"
																formArrayName="items"
																class='example-list row-box group-row'>
																<div cdkDrag class="field-box"
																	*ngFor="let item of row.controls['items'].controls ;let k=index"
																	[formGroupName]="k">
																	<div class="field-item"
																		(dblclick)="editFieldItem(i, j, k)">
																		<div class="field-label">
																			<span>
																				{{ (item.value.label) }}
																			</span>
																			<span *ngIf="item.value.required"
																				style="color: red">
																				*
																			</span>
																			<mat-icon class="secondary-text"
																				*ngIf="item.invalid" color="warn">
																				error</mat-icon>
																			<div class="btn-icons">
																				<span (click)="editFieldItem(i, j, k)"
																					title="edit">
																					<img
																						src="/assets/icons/custom-icons/gear.svg">
																				</span>
																				<span [class.disabled]="isSenderField(item.value.name)"
																					(click)="!isSenderField(item.value.name) && removeItemFromRow(i, j, k)"
																					title="remove">
																					<img
																						src="/assets/icons/custom-icons/trash.svg">
																				</span>
																			</div>
																		</div>

																		<div class="field-input"
																			[innerHtml]=' formDefinition.fieldGroups[i].rows[j].items[k] | renderItem | safeHtml'>
																		</div>
																	</div>
																</div>

															</div>
														</div>
														<div [id]='getListName()' cdkDropList [cdkDropListData]='[]'
															[cdkDropListConnectedTo]="[itemList]"
															(cdkDropListDropped)="emptyRowDrop($event,i)"
															class='example-list group-row' style="min-height: 50px;">
														</div>
													</div>
												</div>
												<app-ngx-quill-wrapper 
												[formControl]="group.controls['groupFooter']">
												</app-ngx-quill-wrapper>
											</div>
										</div>
									</form>
								</div>
								<!-- / CONTENT -->
							</div>
						</div>
						<!-- / CONTENT-->
						<button mat-fab class="" id="add-group-button" (click)="addNewGroup()" matTooltip="Add Group"
							aria-label="add group" [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}">
							<mat-icon>add</mat-icon>
						</button>

					</mat-tab>
					<mat-tab>
						<ng-template mat-tab-label>
							<mat-icon class="secondary-text mat-icon-red"
								*ngIf="senderInformationInvalid"
							>error_outline</mat-icon>
							<span [class.error]="senderInformationInvalid">
								{{ 'Form.SenderInformation' | translate }}
							</span>
						</ng-template>
						<div class="tab-content p-24 w-80-p " style="margin: auto">
							<div [formGroup]='formDefinitionForm'>
								<mat-accordion>
									<mat-expansion-panel formGroupName = "senderFields"
										[expanded] = "!senderInfoName || !senderInfoEmail">
										<mat-expansion-panel-header>
											<mat-panel-title >{{ 'Form.SenderInformation' | translate }}
											</mat-panel-title>
											<mat-panel-description>
												<div class="flex-row-space">
													<span
														*ngIf="senderInfoName && senderInfoEmail">
														{{ senderInfoName }}
														({{ senderInfoEmail }})
													</span>
													<span class="text-red"
														*ngIf="!senderInfoName || !senderInfoEmail">
														{{ 'Form.EmptySigner' | translate }}
													</span>
												</div>
											</mat-panel-description>
										</mat-expansion-panel-header>
										<div fxFlex fxLayout="column">
											<div fxFlex fxLayoutAlign="space-between center">
												<div class="w-50-p">{{ 'Forms.Name' | translate }}<span
														class="text-red">*</span></div>
												<mat-form-field appearance="outline" class="w-50-p">
													<mat-label>{{ 'Forms.Name' | translate }}</mat-label>
													<mat-select formControlName="name" required>
														<mat-option [value]="''">
															--{{ 'Form.NotMapped' | translate }}--
														</mat-option>
														<mat-option *ngFor="let name of allFieldsNames" [value]="name">
															{{name}}
														</mat-option>
													</mat-select>
												</mat-form-field>
											</div>
											<div fxFlex fxLayoutAlign="space-between center">
												<div class="w-50-p">{{ 'Form.FormFields.email' | translate }}<span
														class="text-red">*</span></div>
												<mat-form-field appearance="outline" class="w-50-p">
													<mat-label>{{ 'Form.FormFields.email' | translate }}</mat-label>
													<mat-select formControlName="email" required>
														<mat-option [value]="''">
															--{{ 'Form.NotMapped' | translate }}--
														</mat-option>
														<mat-option *ngFor="let name of allFieldNamesValidAsEmail"
															[value]="name">
															{{name}}
														</mat-option>
													</mat-select>
												</mat-form-field>
											</div>
										</div>
									</mat-expansion-panel>
								</mat-accordion>
							</div>
						</div>
					</mat-tab>
					<mat-tab label="{{ 'Form.Notifications' | translate }}">
						<div class="tab-content p-24 w-80-p pt-96 m-auto">
							<form name="formDefinitionForm" [formGroup]="formDefinitionForm">
								<mat-form-field class="width-full">
									<mat-chip-list #emailList>
										<mat-chip
											*ngFor="let notifiedEmail of formDefinitionForm?.controls['notificationEmails']?.value; let i=index;"
											[selectable]="true" [removable]="true" (removed)="removeNotifiedEmail(i)">
											{{ notifiedEmail }}
											<mat-icon matChipRemove>cancel</mat-icon>
										</mat-chip>
										<input type="email" #notifiedEmailInput
											placeholder="{{ 'Form.SecureUploadNotificationEmails' | translate }}"
											[matChipInputFor]="emailList" [matChipInputAddOnBlur]="true"
											(matChipInputTokenEnd)="addNotifiedEmail($event)"
											matTooltip="{{ 'Form.AddNotifiedEmailTooltip' | translate }}">
										<mat-error *ngIf="addNotifiedEmailHasError">
											{{ 'Form.AddNotifiedEmailError' | translate }}</mat-error>
									</mat-chip-list>
								</mat-form-field>
							</form>
						</div>
					</mat-tab>
					<mat-tab label="{{ 'Form.Preview' | translate }}">
						<div class="scrollable-container">
							<form-preview [formDefinition]='formDefinitionForm.value'></form-preview>
						</div>
					</mat-tab>
				</mat-tab-group>
			</div>
		</div>
	</div>

</div>