export const locale = {
	lang: 'en',
	data: {
		'Headers':{
			'Orders': 'Orders',
			'SearchOrder': 'Search for an order',
			'ID': 'ID',
			'SignerName': 'Signer Name',
			'SignerEmail': 'Signer Email',
			'SignerIdentification': 'Signer Identification',
			'SignedAt': 'Signed at',
			'RelatedForm': 'Related Form',
			'ReferenceID': 'Reference ID',
			'OrderDetails': 'Order Details',
			'Files': 'Files',
			'Info': 'info',
			'Close': 'Close',
			'Actions': 'Actions',
			'ExportData': 'Export data'
		},
		'Paginator': {
			'ItemsPerPage':'Items per page',
		},
		'TSvariables': {
			'AnErrorOccurred': 'An error occurred',
			'OK': 'OK',
			'ConfirmDelete': 'Are you sure, you want to delete order',
			'AlreadyDeleted': 'Order has already been deleted',
			'DeletedSuccessfully': 'Deleted successfully',
		},
		'OrderDetails': {
			'Attachments': 'Attachments',
			'NoAttachements': 'No files were attached with this order',
			'Download': 'Download',
			'Preview': 'Preview',
		},
		'ExportFormsData': {
			'ExportFormsData': 'Export forms data',
			'StartDate': 'Start date',
			'EndDate': 'End date',
			'Export': 'Export'
		}
	},
};
