<h3 mat-dialog-title class="custom-dialouge-header">{{ 'Forms.SecureUploadSubmission' | translate }}</h3>
<div mat-dialog-content>
	<mat-tab-group dynamicHeight="false">
		<mat-tab label="{{ 'Forms.Info' | translate }}">
			<mat-list>
				<mat-list-item>
					<div class="line">
						<div>{{ 'Form.SenderName' | translate }}</div>
						<div>{{submission.senderInformation.name}}</div>
					</div>
				</mat-list-item>

				<mat-divider></mat-divider>
				<mat-list-item>
					<div class="line">
						<div>{{ 'Form.SenderEmail' | translate }}</div>
						<div>{{submission.senderInformation.email}}</div>
					</div>
				</mat-list-item>

				<mat-divider></mat-divider>
				<mat-list-item>
					<div class="line">
						<div>{{ 'Forms.SentAt' | translate }}</div>
						<div>{{submission.createdAt | date:'dd/MM/yyyy, HH:mm'}}</div>
					</div>
				</mat-list-item>

				<mat-divider></mat-divider>
				<mat-list-item>
					<div class="line">
						<div>{{ 'Forms.RelatedForm' | translate }}</div>
						<div><a click-stop-propagation [href]="'forms/'+submission.secureUploadFormDefinitionWithoutImage.id" target="_blank"
								class="text-truncate">{{submission.secureUploadFormDefinitionWithoutImage.name}}</a>
						</div>
					</div>
				</mat-list-item>

				<mat-divider></mat-divider>
				<mat-list-item>
					<div class="line">
						<div>{{ 'Forms.SubmissionID' | translate }}</div>
						<div>{{submission.id}}</div>
					</div>
				</mat-list-item>
			</mat-list>
		</mat-tab>
		<mat-tab label="{{ 'Forms.SubmissionDetails' | translate }}">
			<mat-list>
				<mat-list-item>
					<div class="line">
						<div>{{ submission.submissionDocument.fileName }}</div>
						<div class="text-right">
							<a class="pointer" matTooltip="{{ 'Forms.Download' | translate }}"
								(click)="downloadFile(submission.submissionDocument, $event, true, true)">
								<mat-icon>cloud_download</mat-icon>
							</a>
						</div>
					</div>
					<mat-divider></mat-divider>
				</mat-list-item>
			</mat-list>
			<div class="margin-top">
				<mat-spinner class="inline-block m-auto" [diameter]="25" *ngIf="numberOfPDFDownloads > 0"></mat-spinner>
			</div>
		</mat-tab>
		<mat-tab label="{{ 'Forms.Files' | translate }}">
			<mat-list>
				<mat-list-item *ngFor="let file of submission.files">
					<div class="line">
						<div>{{ file.fileName }}</div>
						<div class="text-right">
							<a class="pointer" matTooltip="{{ 'Forms.Download' | translate }}"
								(click)="downloadFile(file, $event, true)">
								<mat-icon>cloud_download</mat-icon>
							</a>
						</div>
					</div>
					<mat-divider></mat-divider>
				</mat-list-item>
			</mat-list>
			<div class="margin-top">
				<mat-spinner class="inline-block m-auto" [diameter]="25" *ngIf="numberOfAttachmentsDownloads > 0">
				</mat-spinner>
			</div>
			<div class="text-center" *ngIf="!attachmentsNames && !attachmentsNamesLoading">
				<br>
				{{ 'OrderDetails.NoAttachements' | translate }}
			</div>
		</mat-tab>

	</mat-tab-group>
</div>
<mat-dialog-actions>
	<button mat-raised-button mat-dialog-close>{{ 'Forms.Close' | translate }}</button>
</mat-dialog-actions>