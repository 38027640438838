<mat-card>
	<mat-card-header>
		<p>
			<a mat-raised-button color="primary" [routerLink]="['add']">
				{{ ('Headers.Add' | translate) + '!'}}
			</a>
		</p>
	</mat-card-header>
	<mat-card-content>
		<mat-form-field>
			<input matInput (searchBox)='applyFilter($event)' placeholder="{{ 'Headers.Filter' | translate}}">
		</mat-form-field>
		<div class="py-5">
			<ngx-datatable #usersTable class="material expandable" [rows]="allUsers"
				[loadingIndicator]="loadingIndicator" [columnMode]="'force'" [headerHeight]="48" [rowHeight]="'auto'"
				[footerHeight]="48" [scrollbarH]="true" [reorderable]="reOrderable" [limit]="searchParameters?.size"
				(sort)="onSort($event)" [count]="totalCount">
				(activate)="onRowActivate($event)">
				<ngx-datatable-column name="{{ 'Headers.Name' | translate}}" prop="name"> </ngx-datatable-column>
				<ngx-datatable-column name="{{ 'Headers.UserName' | translate}}" prop="userName"></ngx-datatable-column>
				<ngx-datatable-column name="{{ 'Headers.Email' | translate}}" prop="email"></ngx-datatable-column>
				<ngx-datatable-column name="{{ 'Headers.Phone' | translate}}" prop="phone"></ngx-datatable-column>
				<ngx-datatable-column name="{{ 'Headers.CompanyName' | translate}}" prop="CompanyName">
				</ngx-datatable-column>
				<ngx-datatable-column name="{{ 'Headers.Actions' | translate}}" prop="">
					<ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
						<a mat-button [routerLink]="[value.id]">
							<mat-icon>edit</mat-icon>
						</a>
						<button mat-button (click)="Delete(value)">
							<mat-icon>delete</mat-icon>
						</button>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-footer>
					<ng-template ngx-datatable-footer-template>
						<div class="page-count">
							{{ pageCountFooter }}
						</div>
						<datatable-pager 
							[pagerLeftArrowIcon]="'datatable-icon-left'"
							[pagerRightArrowIcon]="'datatable-icon-right'"
							[pagerPreviousIcon]="'datatable-icon-prev'"
							[pagerNextIcon]="'datatable-icon-skip'"
							[page]="curPage" 
							[size]="pageSize" 
							[count]="totalCount" 
							[hidden]="false"
							(change)="onPageChange($event)">
						</datatable-pager>
					</ng-template>
				</ngx-datatable-footer>
			</ngx-datatable>
		</div>
	</mat-card-content>
</mat-card>