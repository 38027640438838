export const locale = {
	lang: 'da',
	data: {
		'Headers':{
			'Orders': 'Signeringsordre',
			'SearchOrder': 'Søg signeringsordre',
			'ID': 'ID',
			'SignerName': 'Underskriver navn',
			'SignerEmail': 'Underskriver email',
			'SignerIdentification': 'Underskriver identifikation',
			'SignedAt': 'Underskrevet kl',
			'RelatedForm': 'Relateret blanket',
			'ReferenceID': 'Reference ID',
			'OrderDetails': 'Ordre detaljer',
			'Files': 'Filer',
			'Info': 'Info',
			'Close': 'Luk',
			'Actions': 'Handlinger',
			'ExportData': 'Eksportér data'
		},
		'Paginator': {
			'ItemsPerPage':'Ordre pr. Side',
		},
		'TSvariables': {
			'AnErrorOccurred': 'En fejl opstod',
			'OK': 'OK',
			'ConfirmDelete': 'Er du sikker på, at du vil slette ordren',
			'AlreadyDeleted': 'Bestillingen er allerede blevet slettet',
			'DeletedSuccessfully': 'Slettet med succes',
		},
		'OrderDetails': {
			'Attachments': 'Vedhæftede filer',
			'NoAttachements': 'Der blev ikke vedhæftet nogen filer med denne ordre',
			'Download': 'Hent',
			'Preview': 'Eksempel',
		},
		'ExportFormsData': {
			'ExportFormsData': 'Eksportér formulardata',
			'StartDate': 'Startdato',
			'EndDate': 'Slutdato',
			'Export': 'Eksportér'
		}
	},
};
