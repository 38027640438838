<div class="horizontal-stepper-wrapper">
	<!-- HORIZONTAL STEPPER EXAMPLE -->
	<form [formGroup]="profileFormGroup" class="mat-card mat-elevation-z4 p-24 " fxLayout="column" fxLayoutAlign="start"
		fxFlex="1 0 auto" name="form" (ngSubmit)="submitProfileUpdate()">
		<input type="hidden" formControlName="id" required>

		<mat-form-field appearance="outline" fxFlex="100">
			<mat-label>{{ 'Profile.UserName' | translate }}</mat-label>
			<input matInput formControlName="userName" required>
			<mat-error *ngIf="profileFormGroup.hasError('required','userName')">{{ 'Profile.UserNameRequired' | translate }}!</mat-error>
			<mat-error *ngIf="profileFormGroup.hasError('notUnique','userName')">{{ 'Profile.UserNameTaken' | translate }}</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" fxFlex="100">
			<mat-label>{{ 'Profile.Email' | translate }}</mat-label>
			<input matInput formControlName="email" required>
			<mat-error *ngIf="profileFormGroup.hasError('required','email')">{{ 'Profile.EmailRequired' | translate }}!</mat-error>
			<mat-error *ngIf="profileFormGroup.hasError('notUnique','email')">{{ 'Profile.EmailTaken' | translate }}</mat-error>

		</mat-form-field>

		<mat-form-field appearance="outline" fxFlex="100">
			<mat-label>{{ 'Profile.FirstName' | translate }}</mat-label>
			<input matInput formControlName="name" required>
			<mat-error>{{ 'Profile.FirstNameRequired' | translate }}!</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" fxFlex="100">
			<mat-label>{{ 'Profile.LastName' | translate }}</mat-label>
			<input matInput formControlName="lastName">
		</mat-form-field>
		<hr />
		<mat-form-field appearance="outline" fxFlex="100">
			<mat-label>{{ 'Profile.Phone' | translate }}</mat-label>
			<input matInput formControlName="phone" customTelNumber>
			<mat-error>{{ 'Profile.PhoneRequired' | translate }}!</mat-error>
		</mat-form-field>
		<hr />
		<mat-form-field appearance="outline" fxFlex="100">
			<mat-label>{{ 'Profile.OldPassword' | translate }}</mat-label>
			<input matInput formControlName="passwordOld" type="password" autocomplete="false">
			<mat-error *ngIf="profileFormGroup.hasError('oldPasswordNotValid','passwordOld')">{{ 'Profile.OldPasswordRequired' | translate }}</mat-error>
			<mat-error *ngIf="profileFormGroup.hasError('wrong','passwordOld')">{{ 'Profile.InvalidPassword' | translate }}</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" fxFlex="100">
			<mat-label>{{ 'Profile.NewPassword' | translate }}</mat-label>
			<input matInput #passwordNew formControlName="passwordNew" type="password">
			<mat-hint align="end">{{passwordNew.value.length}} / 8</mat-hint>
			<mat-error *ngIf="profileFormGroup.hasError('pattern','passwordNew')">{{ 'Profile.NewPasswordErr' | translate }}</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" fxFlex="100">
			<mat-label>{{ 'Profile.ConfirmPassword' | translate }}</mat-label>
			<input matInput #confirmPasswordNew formControlName="confirmPasswordNew" type="password">
			<mat-hint align="end">{{confirmPasswordNew.value.length}} / 8</mat-hint>
			<mat-error *ngIf="profileFormGroup.hasError('notSame','confirmPasswordNew')">{{ 'Profile.ConfirmPasswordErr' | translate }}</mat-error>
		</mat-form-field>
		
		<div fxLayout="row" fxLayoutAlign="center center">
			<button mat-raised-button type="submit" color="primary" class="mx-8" [disabled]="profileFormGroup.invalid">
				{{ 'Profile.Save' | translate }}
			</button>
		</div>
	</form>
	<!-- / HORIZONTAL STEPPER EXAMPLE -->