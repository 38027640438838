import { Directive, HostListener, EventEmitter, Output } from '@angular/core';

@Directive({
	selector: '[searchBox]',
})
export class SearchBoxDirective {
	@Output() searchBox: EventEmitter<KeyboardEvent> = new EventEmitter();
	constructor() {}
	@HostListener('keyup', ['$event']) onkeyup($event: KeyboardEvent): void {
		if ($event.code === 'Escape') {
			($event.target as HTMLInputElement).value = '';
			($event.target as HTMLInputElement).blur();
		}
		this.searchBox.next($event);
	}
}
