<h3 mat-dialog-title class="custom-dialouge-header">{{ 'Headers.OrderDetails' | translate }}</h3>
<div mat-dialog-content>
	<mat-tab-group dynamicHeight="false">
		<mat-tab label="{{ 'Headers.Info' | translate }}">
			<mat-list>
				<mat-list-item>
					<div class="line">
						<div>{{ 'Headers.SignerName' | translate }}</div>
						<div>{{order.signerName}}</div>
					</div>
				</mat-list-item>

				<mat-divider></mat-divider>
				<mat-list-item>
					<div class="line">
						<div>{{ 'Headers.SignerEmail' | translate }}</div>
						<div>{{order.signerEmail}}</div>
					</div>
				</mat-list-item>

				<mat-divider></mat-divider>
				<mat-list-item>
					<div class="line">
						<div>{{ 'Headers.SignerIdentification' | translate }}</div>
						<div>{{order.signerIdentification}}</div>
					</div>
				</mat-list-item>

				<mat-divider></mat-divider>
				<mat-list-item>
					<div class="line">
						<div>{{ 'Headers.SignedAt' | translate }}</div>
						<div>{{order.signedDate | date:'dd/MM/yyyy, HH:mm'}}</div>
					</div>
				</mat-list-item>

				<mat-divider></mat-divider>
				<mat-list-item>
					<div class="line">
						<div>{{ 'Headers.RelatedForm' | translate }}</div>
						<div><a click-stop-propagation [href]="'forms/'+order.relatedFormId" target="_blank"
								class="text-truncate">{{order.relatedFormName}}</a>
						</div>
					</div>
				</mat-list-item>

				<mat-divider></mat-divider>
				<mat-list-item>
					<div class="line">
						<div>{{ 'Headers.ReferenceID' | translate }}</div>
						<div>{{order.referenceID}}</div>
					</div>
				</mat-list-item>
			</mat-list>
		</mat-tab>
		<mat-tab label="{{ 'Headers.Files' | translate }}">
			<mat-list>
				<mat-list-item *ngFor="let doc of order.documents">
					<div class="line">
						<div>{{ doc.filename }}</div>
						<div class="text-right">
							<a class="pointer" matTooltip="{{ 'OrderDetails.Preview' | translate }}"
								(click)="downloadPDF(doc, $event, false)">
								<mat-icon>remove_red_eye</mat-icon>
							</a>
							<a class="pointer" matTooltip="{{ 'OrderDetails.Download' | translate }}"
								(click)="downloadPDF(doc, $event, true)">
								<mat-icon>cloud_download</mat-icon>
							</a>
						</div>
					</div>
					<mat-divider></mat-divider>
				</mat-list-item>
			</mat-list>
			<div class="margin-top">
				<mat-spinner class="inline-block m-auto" [diameter]="25" *ngIf="numberOfPDFDownloads > 0"></mat-spinner>
			</div>
		</mat-tab>
		<mat-tab label="{{ 'OrderDetails.Attachments' | translate }}">
			<div *ngIf="attachmentsNamesLoading">
				<br>
				<mat-spinner class="inline-block m-auto" [diameter]="50"></mat-spinner>
			</div>
			<mat-list *ngIf="attachmentsNames">
				<mat-list-item *ngFor="let fileName of attachmentsNames; let i = index">
					<div class="line">
						<div>{{ fileName }}</div>
						<div class="text-right">
							<a class="pointer" matTooltip="{{ 'OrderDetails.Download' | translate }}"
								(click)="downloadAttachment(i, fileName)">
								<mat-icon>cloud_download</mat-icon>
							</a>
						</div>
					</div>
					<mat-divider></mat-divider>
				</mat-list-item>
			</mat-list>
			<div class="margin-top">
				<mat-spinner class="inline-block m-auto" [diameter]="25" *ngIf="numberOfAttachmentsDownloads > 0">
				</mat-spinner>
			</div>
			<div class="text-center" *ngIf="!attachmentsNames && !attachmentsNamesLoading">
				<br>
				{{ 'OrderDetails.NoAttachements' | translate }}
			</div>
		</mat-tab>

	</mat-tab-group>
</div>
<mat-dialog-actions>
	<button mat-raised-button mat-dialog-close>{{ 'Headers.Close' | translate }}</button>
</mat-dialog-actions>