import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

import { FuseSharedModule } from '@fuse/shared.module';

import { MailConfirmComponent } from '../mail-confirm/mail-confirm.component';
import { TranslateModule } from '@ngx-translate/core';
import { LuftbornModule } from 'app/shared-modules/luftborn/luftborn.module';

const routes = [
	{
		path: 'auth/mail-confirm/:email',
		component: MailConfirmComponent,
	},
];

@NgModule({
	declarations: [MailConfirmComponent],
	imports: [
		RouterModule.forChild(routes),
		MatIconModule, FuseSharedModule,
		TranslateModule,
		LuftbornModule,
	],
})
export class MailConfirmModule {}
