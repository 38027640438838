import { Component, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './_i18n/en';
import { locale as danish } from './_i18n/da';
@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

	constructor(
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
	) {
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
	}

	ngOnInit(): void {
	}

}
