<div id="dashboard-project" class="page-layout simple right-sidebar w-100-p" fxLayout="row">
	<!-- CENTER -->
	<div class="center w-100-p" fusePerfectScrollbar>
		<!-- HEADER -->
		<div class="header accent p-24 pb-0" fxLayout="column" fxLayoutAlign="space-between">
			<div fxLayout="row" fxLayoutAlign="space-between start">
				<span class="mat-display-1 my-0 my-sm-24 welcome-message"
					  [@animate]="{value:'*',params:{x:'50px'}}">
					{{ 'Headers.WelcomeBack' | translate }}.
				</span>

				<button mat-icon-button class="sidebar-toggle mr-8" fxHide.gt-md
						(click)="toggleSidebar('project-dashboard-right-sidebar-1')">
					<mat-icon>menu</mat-icon>
				</button>
			</div>
			<div *ngIf="selectedDashboard" fxLayout="row">
				<div class="selected-project">{{selectedDashboard.companyName}}</div>
				<div *ngIf="companies.length">
					<button mat-icon-button class="project-selector" [matMenuTriggerFor]="projectsMenu"
							aria-label="Select project">
						<mat-icon>more_horiz</mat-icon>
					</button>
					<mat-menu #projectsMenu="matMenu">
						<button mat-menu-item *ngFor="let company of companies" (click)="changeCompany(company.id)">
							<span>{{company.name}}</span>
						</button>
					</mat-menu>
				</div>
			</div>
		</div>
		<!-- / HEADER -->
		<!-- CONTENT -->
		<div class="content">
			<mat-tab-group dynamicHeight>
				<mat-tab label="{{ 'Headers.Home' | translate }}">
					<div *ngIf="selectedDashboard;else spinnerBlock">
						<div class="widget-group p-12" fxLayout="row wrap" fxFlex="100" *fuseIfOnDom
							 [@animateStagger]="{value:'50'}">

							<!-- WIDGET 1 -->
							<fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
										 fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

								<!-- Front -->
								<div class="fuse-widget-front">
									<div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="space-between center">
										<div class="h3">
											{{ ('Api.' + (widgets[0].title)) | translate }}
										</div>
										<button mat-icon-button fuseWidgetToggle aria-label="more">
											<mat-icon>more_vert</mat-icon>
										</button>
									</div>

									<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
										<div class="light-blue-fg font-size-72 line-height-72">
											{{widgets[0].data.count}}
										</div>
										<div class="h3 secondary-text font-weight-500">
											{{ ('Api.' + (widgets[0].data.label)) | translate }}
										</div>
									</div>

									<div class="p-16 border-top" fxLayout="row" fxLayoutAlign="start center">
										<span class="h4 secondary-text text-truncate">
											{{ ('Api.' + (widgets[0].data.extra.label)) | translate }}:
										</span>
										<span class="h4 ml-8">
											{{widgets[0].data.extra.count}}
										</span>
									</div>
								</div>
								<!-- / Front -->
								<!-- Back -->
								<div class="fuse-widget-back p-16 pt-32">
									<button mat-icon-button fuseWidgetToggle class="fuse-widget-flip-button"
											aria-label="Flip widget">
										<mat-icon class="s-16">close</mat-icon>
									</button>

									<div>
										{{ ('Api.' + (widgets[0].detail)) | translate }}
									</div>
								</div>
								<!-- / Back -->

							</fuse-widget>
							<!-- / WIDGET 1 -->
							<!-- WIDGET 2 -->
							<fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
										 fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

								<!-- Front -->
								<div class="fuse-widget-front">
									<div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="space-between center">
										<div class="h3">
											{{ ('Api.' + (widgets[1].title)) | translate }}
										</div>

										<button mat-icon-button fuseWidgetToggle class="fuse-widget-flip-button"
												aria-label="more">
											<mat-icon>more_vert</mat-icon>
										</button>
									</div>

									<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
										<div class="red-fg font-size-72 line-height-72">
											{{widgets[1].data.count}}
										</div>
										<div class="h3 secondary-text font-weight-500">
											{{ ('Api.' + (widgets[1].data.label)) | translate }}
										</div>
									</div>

									<div class="p-16 border-top" fxLayout="row" fxLayoutAlign="start center">
										<span class="h4 secondary-text text-truncate">
											{{ ('Api.' + (widgets[1].data.extra.label)) | translate }}:
										</span>
										<span class="h4 ml-8">{{widgets[1].data.extra.count}}</span>
									</div>
								</div>
								<!-- / Front -->
								<!-- Back -->
								<div class="fuse-widget-back p-16 pt-32">
									<button mat-icon-button fuseWidgetToggle class="fuse-widget-flip-button"
											aria-label="Flip widget">
										<mat-icon class="s-16">close</mat-icon>
									</button>

									<div>
										{{ ('Api.' + (widgets[1].detail)) | translate }}
									</div>
								</div>
								<!-- / Back -->

							</fuse-widget>
							<!-- / WIDGET 2 -->
							<!-- WIDGET 3 -->
							<fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
										 fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

								<!-- Front -->
								<div class="fuse-widget-front">
									<div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="space-between center">
										<div class="h3">
											{{ ('Api.' + (widgets[2].title)) | translate }}
										</div>

										<button mat-icon-button fuseWidgetToggle class="fuse-widget-flip-button"
												aria-label="more">
											<mat-icon>more_vert</mat-icon>
										</button>
									</div>

									<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
										<div class="orange-fg font-size-72 line-height-72">
											{{widgets[2].data.count}}
										</div>
										<div class="h3 secondary-text font-weight-500">
											{{ ('Api.' + (widgets[2].data.label)) | translate }}
										</div>
									</div>

									<div class="p-16 border-top" fxLayout="row" fxLayoutAlign="start center">
										<span class="h4 secondary-text text-truncate">
											{{ ('Api.' + (widgets[2].data.extra.label)) | translate }}:
										</span>
										<span class="h4 ml-8">{{widgets[2].data.extra.count}}</span>
									</div>
								</div>
								<!-- / Front -->
								<!-- Back -->
								<div class="fuse-widget-back p-16 pt-32">
									<button mat-icon-button fuseWidgetToggle class="fuse-widget-flip-button"
											aria-label="Flip widget">
										<mat-icon class="s-16">close</mat-icon>
									</button>

									<div>
										{{ ('Api.' + (widgets[2].detail)) | translate }}
									</div>
								</div>
								<!-- / Back -->

							</fuse-widget>
							<!-- / WIDGET 3 -->
							<!-- WIDGET 4 -->
							<fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
										 fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

								<!-- Front -->
								<div class="fuse-widget-front">
									<div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="space-between center">
										<div class="h3">
											{{ ('Api.' + (widgets[3].title)) | translate }}
										</div>

										<button mat-icon-button fuseWidgetToggle class="fuse-widget-flip-button"
												aria-label="more">
											<mat-icon>more_vert</mat-icon>
										</button>
									</div>

									<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
										<div class="blue-grey-fg font-size-72 line-height-72">
											{{widgets[3].data.count}}
										</div>
										<div class="h3 secondary-text font-weight-500">
											{{ ('Api.' + (widgets[3].data.label)) | translate }}
										</div>
									</div>

									<div class="p-16 border-top" fxLayout="row" fxLayoutAlign="start center">
										<span class="h4 secondary-text text-truncate">
											{{ ('Api.' + (widgets[3].data.extra.label)) | translate }}:
										</span>
										<span class="h4 ml-8">{{widgets[3].data.extra.count}}</span>
									</div>
								</div>
								<!-- / Front -->
								<!-- Back -->
								<div class="fuse-widget-back p-16 pt-32">
									<button mat-icon-button fuseWidgetToggle class="fuse-widget-flip-button"
											aria-label="Flip widget">
										<mat-icon class="s-16">close</mat-icon>
									</button>

									<div>
										{{ ('Api.' + (widgets[3].detail)) | translate }}
									</div>
								</div>
								<!-- / Back -->

							</fuse-widget>
							<!-- / WIDGET 4 -->
							<!-- WIDGET 5 -->

							<fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" fxLayout="row" fxFlex="100"
										 class="widget widget5">
								<div class="fuse-widget-front">
									<div class="px-16 border-bottom" fxLayout="row wrap"
										 fxLayoutAlign="space-between center">
										<div fxFlex class="py-16 h3">
											{{ ('Api.' + (graphWidgets[0].title)) | translate }}
										</div>
										<div fxFlex="0 1 auto" class="py-16" fxLayout="row">
											<button mat-button class="px-16"
													*ngFor="let range of graphWidgets[0].ranges | keys"
													(click)="widget5.currentRange = range.key"
													[ngClass]="{'accent' : widget5.currentRange == range.key}">
												{{ ('Api.' + (range.value)) | translate }}
											</button>
										</div>
									</div>

									<div fxLayout="row wrap" fxLayoutAlign="start start">

										<div class="h-420 my-16" fxLayout="row" fxFlex="100" fxFlex.gt-sm="50">
											<ngx-charts-bar-vertical-stacked *fuseIfOnDom [scheme]="widget5.scheme"
																			 [results]="this.graphWidgets[0].mainChart[this.widget5.currentRange]"
																			 [gradient]="widget5.gradient" [xAxis]="widget5.xAxis"
																			 [yAxis]="widget5.yAxis" [legend]="widget5.legend"
																			 [showXAxisLabel]="widget5.showXAxisLabel"
																			 [showYAxisLabel]="widget5.showYAxisLabel" [xAxisLabel]="widget5.xAxisLabel"
																			 [yAxisLabel]="widget5.yAxisLabel" (select)="widget5.onSelect($event)">
											</ngx-charts-bar-vertical-stacked>
										</div>

										<div class="my-16" fxFlex="100" fxLayout="row wrap" fxFlex.gt-sm="50">

											<div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxLayoutAlign="center"
												 *ngFor="let widget of graphWidgets[0].supporting | keys" class="mb-24">

												<div class="px-24">
													<div class="h4 secondary-text">{{widget.value.label}}</div>
													<div class="mat-display-1 m-0">
														{{widget.value.count[widget5.currentRange]}}
													</div>
												</div>

												<div class="h-64">
													<ngx-charts-area-chart *fuseIfOnDom
																		   [results]="widget.value.chart[widget5.currentRange]"
																		   [scheme]="widget5.supporting.scheme"
																		   [gradient]="widget5.supporting.gradient"
																		   [xAxis]="widget5.supporting.xAxis"
																		   [yAxis]="widget5.supporting.yAxis"
																		   [legend]="widget5.supporting.legend"
																		   [showXAxisLabel]="widget5.supporting.showXAxisLabel"
																		   [showYAxisLabel]="widget5.supporting.showYAxisLabel"
																		   [xAxisLabel]="widget5.supporting.xAxisLabel"
																		   [yAxisLabel]="widget5.supporting.yAxisLabel"
																		   [curve]="widget5.supporting.curve">
													</ngx-charts-area-chart>
												</div>
											</div>
										</div>
									</div>
								</div>
							</fuse-widget>

						</div>
					</div>
					<ng-template #spinnerBlock>
						<div class="spinner-wrapper">
							<div class="spinner">
								<div class="inner">
									<div class="gap"></div>
									<div class="left">
										<div class="half-circle"></div>
									</div>
									<div class="right">
										<div class="half-circle"></div>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
					<!-- / WIDGET GROUP -->
				</mat-tab>
			</mat-tab-group>

		</div>
		<!-- / CONTENT -->

	</div>
	<!-- / CENTER -->
</div>