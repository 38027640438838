import * as	XLSX from 'xlsx';
import ObjectUtility from './object-utility';

export default class ExcelUtility {

	public static WriteObjectToExcel(obj: any, sheetName: string, fileName: string): any {
		var flattenedExportFormsDataResponse = obj.map(x => ObjectUtility.flattenObject(x));

		const workBook = XLSX.utils.book_new();
		const workSheet = XLSX.utils.json_to_sheet(flattenedExportFormsDataResponse);
		XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
		XLSX.writeFile(workBook, fileName);
	}
}
