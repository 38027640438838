import { BOMUtility } from './BOMUtility';

export default class DownloadUtility {

	public static downloadBlob(fileData: Blob, fileName: string, download: boolean = true): void {
		const url = window.URL.createObjectURL(fileData);
		if (BOMUtility.isInternetExplorer()) {
			const navigator = window.navigator as any;
			if (download === true){
				navigator.msSaveBlob(fileData, fileName);
				return;
			}
			navigator.msSaveOrOpenBlob(fileData, fileName);
			return;
		}
		const a: HTMLAnchorElement = document.createElement('a');
		a.setAttribute('style', 'display:none;');
		document.body.appendChild(a);
		if (download === true){
			a.download = fileName;
		}
		a.target = '_blank';
		a.href = url;
		a.click();
	}
}
