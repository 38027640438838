import IGroup from 'app/models/IGroup';

Array.prototype.groupBy = function <T>(this: T[], propertySelector: (item: T) => string): IGroup<T>[] {
	const groups: IGroup<T>[] = [];
	for (const element of this) {
		const key = propertySelector(element);
		const group = groups.find(g => g.key === key);
		if (group) {
			group.list.push(element);
		}
		else {
			groups.push({ key: key, list: [element] });
		}
	}
	return groups;
};
