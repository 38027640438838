<h1 md-dialog-title>{{ 'Dialog.Confirm' | translate }}</h1>
<div mat-dialog-content>
	<p>{{data.confirmMessage}}</p>
</div>
<div mat-dialog-actions>
	<button mat-raised-button color="accent" (click)="dialogRef.close(true)" class="mr-25">
		{{ 'Dialog.Confirm' | translate }}
	</button>
	<button mat-raised-button color="warn" (click)="dialogRef.close(false)">
		{{ 'Dialog.Cancel' | translate }}
	</button>
</div>