import { FieldGroup } from './form-definition-types-module';
import { SignerFields } from './signer-fields.model';
import { Languages } from '../Languages';
import { AlignmentPosition } from '../alignment-position-enum';
import { User } from '../user.model';

export default class SecureUploadFormDefinition {
	public id: string;
	public author: User;
	public companyUrl: string;
	public language: Languages;
	public name: string;
	public documentName: string;
	public active = false;
	public imageDataUrl: string | ArrayBuffer;
	public color: string;
	public formNameTextColor: string;
	public hideFormName = false;
	public imagePosition: AlignmentPosition;
	public header: string;
	public footer: string;
	public senderFields: SignerFields;
	public fieldGroups: Array<FieldGroup>;

	public createdAt: Date;
	public modifiedAt: Date;
	public companyId: string;
	public companyName: string;

	public notificationEmails: string[];
	public published = false;
	
	public constructor(init?: Partial<SecureUploadFormDefinition>) {
		if (init) {
			Object.assign(this, init);
			this.senderFields = new SignerFields(this.senderFields);
		} else {
			this.senderFields = new SignerFields();
			this.fieldGroups = new Array<FieldGroup>();
			this.fieldGroups.push(new FieldGroup());
			this.language = Languages.da;
			this.imagePosition = AlignmentPosition.left;
			this.notificationEmails = new Array<string>();
		}
	}
}
