import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivationModel } from 'app/models/activation.model';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/services/app/auth.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { validationContext } from 'app/services/helpers/validation.helper';
import { UserAccountService } from 'app/services/user-account.service';
import { SnackBarHelper } from 'app/services/helpers/snack-bar.helper';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'activate',
	templateUrl: './activate.component.html',
	styleUrls: ['./activate.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations,
})
export class ActivateComponent implements OnInit {
	ActivationModel: ActivationModel;
	activateFormGroup: UntypedFormGroup;

	constructor(
		private _formBuilder: UntypedFormBuilder,
		private _fuseConfigService: FuseConfigService,
		private accountService: UserAccountService,
		private route: ActivatedRoute,
		public snackBarHelper: SnackBarHelper,
		private router: Router,
		private authService: AuthService,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
		private translate: TranslateService,
	) {
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
		this.translate.setTranslation('en',english, true);
		this.translate.setTranslation('da',danish, true);
		// Configure the layout
		this._fuseConfigService.config = {
			layout: {
				navbar: {
					hidden: true,
				},
				toolbar: {
					hidden: true,
				},
				footer: {
					hidden: true,
				},
				sidepanel: {
					hidden: true,
				},
			},
		};
	}

	ngOnInit(): void {
		this.activateFormGroup = this._formBuilder.group(
			{
				userId: ['', Validators.required],
				activationToken: ['', Validators.required],
				password: [
					'',
					[
						Validators.required,
						Validators.pattern(validationContext.passwordPattern),
					],
				],
				confirmPassword: [''],
			},
			{ validator: this.checkPasswords },
		);
		this.GetActivationModel();
	}

	private GetActivationModel(): void {
		const code = this.route.snapshot.paramMap.get('activationCode');
		this.accountService.getActivationModel(code).subscribe(
			res => {
				if (res.Success) {
					this.ActivationModel = res.Result;
					this.activateFormGroup.patchValue(
						this.ActivationModel || {},
					);
					return;
				}
				if (res.ResponseCode === 400) {
					this.snackBarHelper.openSnackBar({
						message: this.translate.instant('Activate.InvalidActivationToken'),
					});
					this.router.navigate(['/']);
				}
			},
			error => {
				console.log(error);
			},
		);
	}

	onSubmit(): void {
		const activationModel = new ActivationModel(
			this.activateFormGroup.value,
		);
		this.accountService.sendActivationModel(activationModel).subscribe(
			res => {
				this.authService.LoginResponseHandler.bind(
					this.authService,
					res.Result,
				)();
			},
			error => {
				console.log(error);
			},
		);
	}

	// source: https://stackoverflow.com/a/51606362/1843445
	checkPasswords(group: UntypedFormGroup): { notSame: boolean } {
		const pass = group.controls.password.value;
		const confirmPass = group.controls.confirmPassword.value;
		return pass === confirmPass ? null : { notSame: true };
	}
}
