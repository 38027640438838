import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserProfile } from 'app/models/user-profile.model';
import {
	validationContext,
	ValidationHelper,
} from '../../../services/helpers/validation.helper';
import { SnackBarHelper } from '../../../services/helpers/snack-bar.helper';
import { UserAccountService } from '../../../services/user-account.service';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';
import { StaticValidation } from 'app/utilities/static-validation';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
	profile: UserProfile;
	profileFormGroup: UntypedFormGroup;

	saved: boolean;

	constructor(
		private _formBuilder: UntypedFormBuilder,
		public snackBar: MatSnackBar,
		private accountService: UserAccountService,
		public snackBarHelper: SnackBarHelper,
		private router: Router,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
		private translate: TranslateService,
	) {
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
		this.translate.setTranslation('en', english, true);
		this.translate.setTranslation('da', danish, true);
	}

	ngOnInit(): void {
		this.accountService.getProfile().subscribe(res => {
			this.profile = new UserProfile(res.Result);
			this.profileFormGroup.patchValue(this.profile);
		});
		this.profileFormGroup = this._formBuilder.group(
			{
				id: [null, Validators.required],
				userName: [null, Validators.required],
				email: [null, [Validators.required, Validators.email]],
				name: [null, Validators.required],
				lastName: [null],
				phone: [null, Validators.pattern(new StaticValidation().phoneRegex)],
				passwordOld: [null],
				passwordNew: [
					null,
					[Validators.pattern(validationContext.passwordPattern)],
				],
				confirmPasswordNew: [null],
			},
			{ validators: [this.checkPasswordsMatch, this.checkPasswords] },
		);
	}
	submitProfileUpdate(): void {
		this.saved = false;
		if (!this.profileFormGroup.valid) {
			return;
		}
		this.profile.UpdateValues(this.profileFormGroup.value);
		this.accountService.updateProfile(this.profile).subscribe(res => {
			if (res.ResponseCode === 200) {
				this.saved = true;
				this.snackBarHelper.openSnackBar({
					message: this.translate.instant('Profile.ProfileUpdatedSuccessfully'),
				});
				return;
			}
			if (res.ResponseCode === 400) {
				ValidationHelper.setFormErrors(
					res.errors,
					this.profileFormGroup,
				);
				this.snackBarHelper.openSnackBar({
					message: this.translate.instant('Profile.UpdateProfileFailed'),
				});
			}
		});
	}
	// source: https://stackoverflow.com/a/51606362/1843445
	checkPasswordsMatch(group: UntypedFormGroup): { notSame: boolean } {
		const pass = group.controls.passwordNew.value;
		const confirmPass = group.controls.confirmPasswordNew.value;
		const valid = pass === confirmPass;
		if (valid) {
			return null;
		}
		group.controls.confirmPasswordNew.setErrors({ notSame: true });
		return { notSame: true };
	}
	checkPasswords(group: UntypedFormGroup): { oldPasswordNotValid: boolean } {
		const passOld = group.controls.passwordOld.value;
		const passNew = group.controls.passwordNew.value;
		const valid = !passNew || (!!passOld && !!passNew);
		if (valid) {
			return null;
		}
		group.controls.passwordOld.setErrors({
			oldPasswordNotValid: true,
		});
		return { oldPasswordNotValid: true };
	}
}
