import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import FormDefinition from 'app/models/form-builder/form-definition.model';
import { DomSanitizer } from '@angular/platform-browser';
import { AlignmentPosition } from 'app/models/alignment-position-enum';

@Component({
	selector: 'form-preview',
	templateUrl: './form-preview.component.html',
	styleUrls: ['./form-preview.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class FormPreviewComponent implements OnInit {
	@Input() formDefinition: FormDefinition;

	constructor(
		public sanitizer: DomSanitizer
	) { }

	imageAlignClasses() {
		let classes = {
			left: this.formDefinition.imagePosition === AlignmentPosition.left,
			center: this.formDefinition.imagePosition === AlignmentPosition.center,
			right: this.formDefinition.imagePosition === AlignmentPosition.right,
		};
		return classes;
	}

	ngOnInit(): void { }
}
