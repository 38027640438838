import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router,
	CanActivateChild,
} from '@angular/router';
import { AuthService } from '../services/app/auth.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
	private loginUrl = 'auth/login';

	constructor(private authService: AuthService, private router: Router) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const secureUploadRoutes = ['secure-upload', 'secure-upload-inbox'];
		if (secureUploadRoutes.some((route) => state.url.includes(route))) {
			return this.checkIfSecureUploadAllowed();
		}
		
		const url: string = state.url || '/';
		return this.checkLogin(url);
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.canActivate(route, state);
	}

	checkLogin(url: string): boolean {
		if (this.authService.isLoggedIn()) {
			return true;
		}

		// Store the attempted URL for redirecting
		this.authService.redirectUrl = url;

		// Navigate to the login page with extras
		this.router.navigate([this.loginUrl]);
		return false;
	}

	private checkIfSecureUploadAllowed(): boolean {
		const user = this.authService.getCurrentUser();
		if (user.company.secureUploadEnabled) {
			return true;
		}

		this.router.navigate(['/']);
		return false;
	}
}
