import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

import { FuseSharedModule } from '@fuse/shared.module';

import { AccountComponent } from './account.component';
import { ProfileComponent } from './profile/profile.component';
import { ActivateComponent } from './activate/activate.component';

import { AuthGuard } from 'app/guards/auth.guard';

import { MatSharedModule } from 'app/shared-modules/mat-shared/mat-shared.module';
import { LuftbornModule } from 'app/shared-modules/luftborn/luftborn.module';
import { DirectiveModule } from 'app/directive/directive.module';
const routes = [
	{
		path: 'account',
		component: AccountComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: 'profile', component: ProfileComponent },
			{ path: '**', component: ProfileComponent },
		],
	},
	{ path: 'activate/:activationCode', component: ActivateComponent },
];

@NgModule({
	declarations: [
		ActivateComponent,
		ProfileComponent,AccountComponent
	],
	imports: [
		RouterModule.forChild(routes),
		TranslateModule,
		FuseSharedModule,
		MatSharedModule,
		BrowserAnimationsModule,
		LuftbornModule,
		FlexLayoutModule,
		DirectiveModule,
	],
	exports: [AccountComponent],
})
export class UserAccountService {}