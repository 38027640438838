export class UserProfile {
	public id: string;
	public name: string;
	public lastName: string;
	public userName: string;
	public email: string;
	public phone: string;
	public passwordOld: string;
	public passwordNew: string;
	public confirmPasswordNew: string;

	public constructor(init?: Partial<UserProfile>) {
		Object.assign(this, init);
	}

	public UpdateValues(values?: Partial<UserProfile>): void {
		Object.keys(values).forEach(key => {
			if (this.hasOwnProperty(key)) {
				this[key] = values[key];
			}
		});
	}
}
