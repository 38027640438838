import { NgModule, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyCompanyComponent } from './my company/my-company.component';
import { AuthGuard } from 'app/guards/auth.guard';
import { RouterModule } from '@angular/router';
import { MatSharedModule } from 'app/shared-modules/mat-shared/mat-shared.module';
import { CompanyComponent } from './company.component';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { CompaniesComponent } from './companies/companies.component';
import { ListComponent } from './list/list.component';
import { EditComponent } from './edit/edit.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorTranslation } from 'app/utilities/PaginatorTranslation/PaginatorTranslation';
import { DirectiveModule } from 'app/directive/directive.module';

const routes = [
	{
		path: 'company',
		component: CompanyComponent,
		canActivate: [AuthGuard],
		children: [{ path: '**', component: MyCompanyComponent }],
	},
	{
		path: 'companies',
		component: CompaniesComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: 'add', component: EditComponent },
			{ path: ':id', component: EditComponent },
			{ path: '**', component: ListComponent },
		],
	},
];

@NgModule({
	declarations: [
		MyCompanyComponent,
		CompanyComponent,
		CompaniesComponent,
		ListComponent,
		EditComponent,
	],
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		MatSharedModule,
		TranslateModule,
		FuseSharedModule,
		DirectiveModule,
	],
	providers: [
		{ provide: MatPaginatorIntl, useClass: forwardRef(() => PaginatorTranslation) }
	],
})
export class CompanyModule { }
