import {
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { MakeRequestService } from './app/make-request.service';
import { ResultModel } from '../models/result-model';
import SearchParameters from 'app/models/search-parameters.model';
import PaginatedModel from 'app/models/paginated-order.model';
import SecureUploadFormSubmission from 'app/models/form-builder/secure-upload-form-submission.model';
import ExportFormsDataRequest from 'app/models/form-submission/export-forms-data-request.model';
import ExportInboxDataResponse from 'app/models/form-builder/export-inbox-data-response.model';

@Injectable({
	providedIn: 'root',
})
export class SecureUploadInboxService {
	searchParameters: SearchParameters = new SearchParameters();
	baseModuleUrl = 'SecureUpload';
	onInboxChanged: BehaviorSubject<SecureUploadFormSubmission[]>;
	unreadCount: number = 0;
	onUnreadCountChanged: BehaviorSubject<number>;
	searchParametersChanged: Subject<SearchParameters> = new Subject<SearchParameters>();
	public inbox: SecureUploadFormSubmission[] = [];
	public totalInboxItemsCount: number = 0;
	public dataIsLoading: Subject<boolean> = new Subject<boolean>();
	
	constructor(
		private makeRequest: MakeRequestService,
	) {
		this.onInboxChanged = new BehaviorSubject([]);
		this.onUnreadCountChanged = new BehaviorSubject(0);

		this.searchParametersChanged.subscribe((searchParameters: SearchParameters) => {
			this.searchParameters = searchParameters;
			this.dataIsLoading.next(true);
			this.getAll(this.searchParameters).subscribe((response: any) => {
				this.setInboxValues(response);
				this.dataIsLoading.next(false);
			});
		});
	}

	public get(id: string): Observable<ResultModel<SecureUploadFormSubmission>> {
		return this.makeRequest.get<SecureUploadFormSubmission>(
			`${this.baseModuleUrl}/GetSubmission/${id}`,
		);
	}

	public getAll(SearchParameters: SearchParameters): Observable<ResultModel<PaginatedModel<SecureUploadFormSubmission>>> {
		const { page, size, searchValue, sortValue, sortAscending } = SearchParameters;

		return this.makeRequest.get<PaginatedModel<SecureUploadFormSubmission>>(
			`${this.baseModuleUrl}/Inbox?Page=${page}&Size=${size}&SearchValue=${searchValue}&SortValue=${sortValue}&SortAscending=${sortAscending}`,
			null,
			() => this.dataIsLoading.next(false),
		);
	}

	public downloadFile(submissionId: string, fileName: string, downloadSubmissionDocument: boolean = false): Observable<Blob>{
		submissionId = encodeURIComponent(submissionId);
		fileName = encodeURIComponent(fileName);
		return this.makeRequest.getBlob(`${this.baseModuleUrl}/DownloadFile?submissionId=${submissionId}&fileName=${fileName}&downloadSubmissionDocument=${downloadSubmissionDocument}`);
	}

	public readInboxItem(submissionId: string): Observable<ResultModel<SecureUploadFormSubmission>> {
		return this.makeRequest.post<SecureUploadFormSubmission>(
			`${this.baseModuleUrl}/Read/${submissionId}`,
			null,
		);
	}

	public getUnreadCount(): Observable<ResultModel<number>> {
		return this.makeRequest.get<number>(
			`${this.baseModuleUrl}/UnreadCount`,
		);
	}

	public resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<any> | Promise<any> | any {
		return new Promise((resolve, reject) => {
			this.dataIsLoading.next(true);
			this.getAll(this.searchParameters).subscribe((response: any) => {
				this.setInboxValues(response);
				resolve(response);
			}, reject);
		});
	}

	private setInboxValues(response: any): void {
		this.inbox = response.Result.list;
		this.totalInboxItemsCount = response.Result.total;
		this.onInboxChanged.next(this.inbox);
	}

	public exportData(exportRequest: ExportFormsDataRequest): Observable<ResultModel<ExportInboxDataResponse[]>> {
		return this.makeRequest.post<ExportInboxDataResponse[]>(
			`${this.baseModuleUrl}/ExportInboxData`, exportRequest
		);
	}
}
