<div id="secure-upload-inbox" class="page-layout carded fullwidth inner-scroll">

	<!-- TOP BACKGROUND -->
	<div class="top-bg accent"></div>
	<!-- / TOP BACKGROUND -->

	<!-- CENTER -->
	<div class="center">

		<!-- HEADER -->
		<div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
			fxLayoutAlign.gt-sm="space-between center">

			<!-- APP TITLE -->
			<div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
				<mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
					mail
				</mat-icon>
				<span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
					{{ 'Forms.SecureUploadInbox' | translate }}
				</span>
			</div>
			<!-- / APP TITLE -->
			<!-- SEARCH -->
			<div class="search-wrapper mx-32 mx-md-0">
				<div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
					<mat-icon>search</mat-icon>
					<input #filter placeholder="{{ 'Forms.SearchInbox' | translate }}">
				</div>
			</div>
			<!-- / SEARCH -->
			<!-- Export -->
			<div class="mx-32 mx-md-0">
				<button
					mat-button class="fuse-white"
					matTooltip="{{ 'Forms.ExportFormsData' | translate }}"
					(click)="openExportDataDialogue()">
					{{ 'Forms.ExportData' | translate }}
				</button>
			</div>
			<!-- / Export -->

		</div>
		<!-- / HEADER -->

		<!-- CONTENT CARD -->
		<div class="content-card">
			<div *ngIf="isLoading" class="spinner-container">
				<mat-spinner></mat-spinner>
			</div>
			<mat-table class="inbox-table" #table [dataSource]="dataSource" matSort [@animateStagger]="{value:'50'}"
				fusePerfectScrollbar>

				<!-- ID Column -->
				<ng-container matColumnDef="id">
					<mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Headers.ID' | translate }}</mat-header-cell>
					<mat-cell *matCellDef="let item">
						<p class="text-truncate">{{item.id}}</p>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="senderName">
					<mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Form.SenderName' | translate }}</mat-header-cell>
					<mat-cell *matCellDef="let item">
						<p class="text-truncate">{{item.senderInformation.name}}</p>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="senderEmail">
					<mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Form.SenderEmail' | translate }}</mat-header-cell>
					<mat-cell *matCellDef="let item">
						<p class="text-truncate">{{item.senderInformation.email}}</p>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="sentDate">
					<mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Forms.SentAt' | translate }}</mat-header-cell>
					<mat-cell *matCellDef="let item">
						<p class="text-truncate">{{item.createdAt | date: 'dd/MM/yyyy, HH:mm'}}</p>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="relatedFormName">
					<mat-header-cell *matHeaderCellDef>{{ 'Forms.RelatedForm' | translate }}</mat-header-cell>
					<mat-cell *matCellDef="let item">
						<a click-stop-propagation [href]="'secure-upload/'+item.secureUploadFormDefinitionWithoutImage.id" target="_blank"
							class="text-truncate">{{item.secureUploadFormDefinitionWithoutImage.name}}</a>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="submissionID">
					<mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Forms.SubmissionID' | translate }}</mat-header-cell>
					<mat-cell *matCellDef="let item">
						<p class="text-truncate">{{item.id}}</p>
					</mat-cell>
				</ng-container>

				<!-- Actions Column -->
				<ng-container matColumnDef="actions">
					<mat-header-cell class="center-content" *matHeaderCellDef>{{ 'Forms.Actions' | translate }}</mat-header-cell>
					<mat-cell class="center-content" *matCellDef="let item">
						<button mat-button (click)="openDialog(item)">
							<mat-icon>info</mat-icon>
						</button>
					</mat-cell>
				</ng-container>


				<mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

				<mat-row *matRowDef="let item; columns: displayedColumns;" class="product" matRipple [ngClass]="{ 'bold': !item.modifiedAt }"
					(click)="selection.toggle(item)"></mat-row>

			</mat-table>

			<mat-paginator #paginator [length]="dataSource.totalDataCount" [pageIndex]="0" [pageSize]="10"
				[pageSizeOptions]="[5, 10, 25, 100]">
			</mat-paginator>

		</div>
		<!-- / CONTENT CARD -->
	</div>
	<!-- / CENTER -->
</div>