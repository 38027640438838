export const locale = {
	lang: 'da',
	data: {
		'Dialog': {
			'Confirm': 'Bekræft',
			'Cancel': 'Annuller',
		},
		'SnackBar':{
			'Dismiss': 'Afvis'
		}
	}
};
