import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'app/services/language.service';

@Component({
  selector: 'app-switch-language',
  templateUrl: './switch-language.component.html',
  styleUrls: ['./switch-language.component.scss']
})
export class SwitchLanguageComponent implements OnInit {

  public changeLanguageString: string;
  constructor(
      private translate: TranslateService,
      private languageService: LanguageService,
    ) { }

  ngOnInit() {
    this.setChangeLanguageString();
    this.translate.onLangChange.subscribe(() => {
      this.setChangeLanguageString();
    });
  }

  setChangeLanguageString(){
    if (this.translate.currentLang === 'en'){
      this.changeLanguageString = 'Skift til Dansk';
      return;
    }
    this.changeLanguageString = 'Change to English';
  }

  switchLanguage(){
    if (this.translate.currentLang === 'en'){
      this.languageService.setLanguage({id: 'da'});
      return;
    }
		this.languageService.setLanguage({id: 'en'});
  }

}
