import { NgModule, forwardRef } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { MatSharedModule } from 'app/shared-modules/mat-shared/mat-shared.module';
import { OrderService } from 'app/services/order.service';
import { OrdersComponent } from './orders/orders.component';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { LuftbornModule } from 'app/shared-modules/luftborn/luftborn.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PaginatorTranslation } from 'app/utilities/PaginatorTranslation/PaginatorTranslation';
import { OrderDetailsComponent } from './order-details/OrderDetailsComponent';
import { ExportFormsDataComponent } from './export-forms-data/export-forms-data.component';

const routes: Routes = [
	{
		path: 'orders',
		component: OrdersComponent,
		resolve: {
			data: OrderService,
		},
	},
];

@NgModule({
    declarations: [
        OrdersComponent,
        OrderDetailsComponent,
        ExportFormsDataComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        NgxChartsModule,
        TranslateModule,
        BrowserModule,
        FuseSharedModule,
        FuseWidgetModule,
        MatSharedModule,
        FormsModule,
        LuftbornModule,
        MatDialogModule,
        MatDividerModule,
        MatTabsModule,
        MatTooltipModule
    ],
    providers: [
        { provide: MatPaginatorIntl, useClass: forwardRef(() => PaginatorTranslation) },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } }
    ]
})
export class OrderModule { }
