export const locale = {
	lang: 'en',
	data: {
		'SAMPLE': {
			'HELLO': 'Hello, World!'
		},
		'Headers':{
			'Home': 'Home',
			'WelcomeBack': 'Welcome back',
		},
		'Api': {
			'Today': 'Today',

			'#Users': '# Users',
			'NumberOfAllUsersForThisCompnay.': 'Number of all users for this company.',
			'User': 'user',

			'#Forms': '# Forms',
			'NumberOfAllFormsForThisCompnay.': 'Number of all forms for this company.',
			'Form': 'form',

			'#Orders': '# Orders',
			'#CompletedOrders': '# Completed Orders',
			'NumberOfAllOrdersForThisCompnay.': 'Number of all orders for this company.',
			'NumberOfAllCompletedOrdersForThisCompnay.': 'Number of all completed orders for this company.',
			'Order': 'order',
			'CompletedOrder': 'completed orders',

			'OrdersDetails': 'Orders details',
			'ThisMonth': 'This Month',
			'LastMonth': 'Last Month',
			'2Months': '2 Months',
		}
	}
};
