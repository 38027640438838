import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';

import Quill from 'quill';

// to make styling inline instead of classes
// https://github.com/quilljs/quill/issues/1451
for (const quillImportKey in Quill.imports) {
	const quillImport = Quill.import(quillImportKey);
	Quill.register(quillImport, true);
}

import { IndentStyle } from './Indentation/Indent';
Quill.register(IndentStyle, true)

// https://github.com/KillerCodeMonkey/ngx-quill/issues/698
const font = Quill.import('formats/font');
font.whitelist = ['arial', 'roboto', 'serif', 'sansserif', 'monospace', 'calibri', 'verdana', 'helvetica'];
Quill.register(font, true);

// https://stackoverflow.com/questions/38623716/how-to-add-custom-font-sizes-to-quilljs-editor#answer-43808486
var Size = Quill.import('attributors/style/size');
Size.whitelist = ['12px', '14px', '16px', '18px'];
Quill.register(Size, true);

@Component({
	selector: 'app-ngx-quill-wrapper',
	templateUrl: './ngx-quill-wrapper.component.html',
	styleUrls: ['./ngx-quill-wrapper.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => NgxQuillWrapperComponent),
			multi: true
		}
	]
})
export class NgxQuillWrapperComponent implements AfterViewInit, ControlValueAccessor {

	@ViewChild('quilleditor') quilleditor: QuillEditorComponent;

	@Input() value;
	@Output() private valueChange = new EventEmitter();

	onChange;
	onTouch;
	touched = false;


	constructor() { }

	ngAfterViewInit() {
		// workaround as "this.quilleditor" inside "writeValue" for component inside ngfor is undefined (hence not properly initialized)
		this.writeValue(this.value);

		this.quilleditor.onContentChanged.subscribe((e) => {
			this.value = e.html;
			this.valueChange.emit(this.value);
			this.onChange(this.value);
		})
		this.quilleditor.onFocus.subscribe((e) => {
			if (!this.touched) {
				this.onTouch();
			}
		})
	}

	writeValue(value: any): void {
		this.value = value;
		if (!this.quilleditor || !value) {
			return;
		}
		this.quilleditor.writeValue(value);
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
	}

}
