<div>
	<mat-toolbar matDialogTitle class="mat-accent m-0">
		<mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
			<span class="title dialog-title">{{ 'Forms.ExportFormsData' | translate }}</span>
			<button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
				<mat-icon>close</mat-icon>
			</button>
		</mat-toolbar-row>
	</mat-toolbar>
	<div mat-dialog-content class="p-24 pb-0 m-0">
		<form [formGroup]="exportFormGroup" >
			<mat-form-field>
				<input matInput #startPickerInput [matDatepicker]="startPicker" formControlName="startDate"
					placeholder="{{ 'Forms.StartDate' | translate }}">
				<mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
				<mat-datepicker #startPicker></mat-datepicker>
			</mat-form-field>
			<mat-form-field>
				<input matInput #endPickerInput [matDatepicker]="endPicker" formControlName="endDate"
					placeholder="{{ 'Forms.EndDate' | translate }}">
				<mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
				<mat-datepicker #endPicker></mat-datepicker>
			</mat-form-field>

			<div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
				<button mat-raised-button color="primary" class="m-auto" (click)="export()" [disabled]="exportFormGroup.invalid || isLoading"
					aria-label="Export">
					<span class="submit-button-content">
						{{ 'Forms.Export' | translate }}
						<mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
					</span>
				</button>
			</div>
		</form>
	</div>
</div>