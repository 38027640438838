import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MakeRequestService } from './app/make-request.service';

import { ActivationModel } from '../models/activation.model';
import { ResultModel } from '../models/result-model';
import { LoginResponseModel } from '../models/login-response.model';
import { UserProfile } from '../models/user-profile.model';
import { ResetPasswordModel } from '../models/user/reset-password.model';


@Injectable({
	providedIn: 'root',
})
export class UserAccountService {
	baseModuleUrl = 'account/';

	constructor(
		private makeRequest: MakeRequestService
	) {}
	
	public getActivationModel(
		activationCode: string,
	): Observable<ResultModel<ActivationModel>> {
		return this.makeRequest.get<ActivationModel>(
			`${
				this.baseModuleUrl
			}ActivateUser?activationCode=${activationCode}`,
		);
	}
	public sendActivationModel(
		activationModel: ActivationModel,
	): Observable<ResultModel<LoginResponseModel>> {
		return this.makeRequest.post<LoginResponseModel>(
			`${this.baseModuleUrl}ActivateUser?`,
			activationModel,
		);
	}

	public getProfile(): Observable<ResultModel<UserProfile>> {
		return this.makeRequest.get<UserProfile>(
			`${this.baseModuleUrl}profile`,
		);
	}
	public updateProfile(
		profile: UserProfile,
	): Observable<ResultModel<UserProfile>> {
		return this.makeRequest.post<UserProfile>(
			`${this.baseModuleUrl}profile`,
			profile,
		);
	}

	public getResetModel(
		token: string,
	): Observable<ResultModel<ResetPasswordModel>> {
		return this.makeRequest.get<ResetPasswordModel>(
			`${this.baseModuleUrl}ResetPassword?resetToken=${token}`,
		);
	}
	public sendResetModel(
		resetModel: ResetPasswordModel,
	): Observable<ResultModel<ResetPasswordModel>> {
		return this.makeRequest.post<ResetPasswordModel>(
			`${this.baseModuleUrl}ResetPassword`,
			resetModel,
		);
	}
}
