import { FieldGroup } from './form-definition-types-module';
import { SignerFields } from './signer-fields.model';
import { Languages } from '../Languages';
import { AlignmentPosition } from '../alignment-position-enum';
import { StaticSigner } from './static-signer.model';

export default class FormDefinition {
	public id: string;
	public creatorId: string;
	public senderEmail: string;
	public companyUrl: string;
	public language: Languages;
	public name: string;
	public documentName: string;
	public active = false;
	public imageDataUrl: string | ArrayBuffer;
	public color: string;
	public formNameTextColor: string;
	public hideFormName = false;
	public imagePosition: AlignmentPosition;
	public header: string;
	public footer: string;
	public signersFields: SignerFields[];
	public staticSigners: StaticSigner[];
	public fieldGroups: Array<FieldGroup>;

	public createdAt: Date;
	public modifiedAt: Date;
	public companyId: string;
	public companyName: string;

	public notificationEmails: string[];
	public notificationEmailsWithDocumentAttached: string[];
	public notifySignersUponOrderCompletion = false;
	public attachDocumentToNotifiedSigners = false;
	public published = false;
	
	public constructor(init?: Partial<FormDefinition>) {
		if (init) {
			Object.assign(this, init);
			this.signersFields = init.signersFields.map(s => new SignerFields(s));
			this.staticSigners = init.staticSigners?.map(s => new StaticSigner(s)) ?? [];
		} else {
			this.signersFields = new Array<SignerFields>();
			this.signersFields.push(new SignerFields());
			this.fieldGroups = new Array<FieldGroup>();
			this.fieldGroups.push(new FieldGroup());
			this.staticSigners = new Array<StaticSigner>();
			this.language = Languages.da;
			this.imagePosition = AlignmentPosition.left;
			this.notificationEmails = new Array<string>();
			this.notificationEmailsWithDocumentAttached = new Array<string>();
		}
	}
}
