<div id="forms" class="page-layout carded fullwidth inner-scroll">

	<!-- TOP BACKGROUND -->
	<div class="top-bg accent"></div>
	<!-- / TOP BACKGROUND -->

	<!-- CENTER -->
	<div class="center">

		<!-- HEADER -->
		<div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
			fxLayoutAlign.gt-sm="space-between center">

			<!-- APP TITLE -->
			<div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
				<mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
					shopping_basket
				</mat-icon>
				<span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
					{{ 'Forms.Forms' | translate }}
				</span>
			</div>
			<!-- / APP TITLE -->

			<!-- SEARCH -->
			<div class="search-wrapper mx-32 mx-md-0">
				<div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
					<mat-icon>search</mat-icon>
					<input #filter placeholder="{{ 'Forms.SearchForForm' | translate }}">
				</div>
			</div>
			<!-- / SEARCH -->

			<div class="d-flex">
				<!-- Select Company (for SuperAdmins only) -->
				<div class="mr-16">
					<mat-form-field *ngIf="isSuperAdmin && companiesLoaded">
						<mat-label>{{ 'Forms.Company' | translate }}</mat-label>
						<mat-select [value]="currentOperatedCompany.id"
							(selectionChange)="selectedCompanyChange($event.value)">
							<mat-option *ngFor="let company of companies" [value]="company.id">
								{{ company.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<!-- /Select Company (for SuperAdmins only) -->

				<div class="d-flex forms-actions">
					<!-- IMPORT BUTTON -->
					<button mat-raised-button (click)="Import()"
						class="import-form-button fuse-white mt-24 mt-md-0">
						<span>{{ 'Forms.ImportForm' | translate }}</span>
					</button>
					<input type="file" #fileInput style="display:none" (change)="onFileSelected($event)" accept=".json">
					<!-- / IMPORT BUTTON -->
					<!-- ADD BUTTON -->
					<button mat-raised-button [routerLink]="'/forms/new'"
						class="add-form-button fuse-white mt-24 mt-md-0">
						<span>{{ 'Forms.AddNewForm' | translate }}</span>
					</button>
					<!-- / ADD BUTTON -->
				</div>
			</div>

		</div>
		<!-- / HEADER -->

		<!-- CONTENT CARD -->
		<div class="content-card">
			<div *ngIf="formsLoading" class="overlay">
				<mat-spinner></mat-spinner>
			</div>
			<mat-table class="forms-table" #table [dataSource]="dataSource" matSort [@animateStagger]="{value:'50'}"
				fusePerfectScrollbar>

				<!-- ID Column -->
				<ng-container matColumnDef="id">
					<mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
					<mat-cell *matCellDef="let form">
						<p class="text-truncate">{{form.id}}</p>
					</mat-cell>
				</ng-container>

				<!-- Image Column -->
				<ng-container matColumnDef="image">
					<mat-header-cell *matHeaderCellDef></mat-header-cell>
					<mat-cell *matCellDef="let form">
						<img class="form-image" *ngIf="form.featuredImageId" [alt]="form.name"
							[src]="form.images[form.featuredImageId].url" />
						<img *ngIf="!form.featuredImageId"
							[src]="'assets/images/ecommerce/product-image-placeholder.png'">
					</mat-cell>
				</ng-container>

				<!-- Name Column -->
				<ng-container matColumnDef="name">
					<mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Forms.Name' | translate }}</mat-header-cell>
					<mat-cell *matCellDef="let form">
						<p class="text-truncate">{{form.name}}</p>
					</mat-cell>
				</ng-container>

				<!-- creatorid Column -->
				<ng-container matColumnDef="creator">
					<mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Forms.Creator' | translate }}
					</mat-header-cell>
					<mat-cell *matCellDef="let form" mat-column-creator>
						<p class="text-truncate">{{form.creatorId}}</p>
					</mat-cell>
				</ng-container>

				<!-- createdAt date Column -->
				<ng-container matColumnDef="createdAt">
					<mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Forms.CreatedAt' | translate }}
					</mat-header-cell>
					<mat-cell *matCellDef="let form" mat-column-createdAt>
						<p class="text-truncate">{{form.createdAt  | date:'dd/MM/yyyy, HH:mm'}}</p>
					</mat-cell>
				</ng-container>
				<!-- Link Column -->
				<ng-container matColumnDef="link">
					<mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Forms.Link' | translate }}</mat-header-cell>
					<mat-cell *matCellDef="let form" mat-column-link>
						<a *ngIf="!!form.published" click-stop-propagation [href]="getFormUrl(form)" target="_blank"
							class="text-truncate" data-test="openForm">{{ 'Forms.GoToForm' | translate }}</a>
					</mat-cell>
				</ng-container>
				<!-- Publish Column -->
				<ng-container matColumnDef="publish">
					<mat-header-cell *matHeaderCellDef>{{ 'Forms.Published' | translate }}</mat-header-cell>
					<mat-cell *matCellDef="let form" mat-column-publish>
						<mat-slide-toggle app-checked-noop color="accent" (click)="Publish(form.id, form.published)"
															[checked]="form.published" data-test="publishForm">
						</mat-slide-toggle>
					</mat-cell>
				</ng-container>
				<!-- Actions Column -->
				<ng-container matColumnDef="actions">
					<mat-header-cell *matHeaderCellDef>{{ 'Forms.Actions' | translate }}</mat-header-cell>
					<mat-cell *matCellDef="let form" mat-column-actions>
						<a mat-button [routerLink]="getEncodedFormName(form)">
							<mat-icon>edit</mat-icon>
						</a>
						<button mat-button (click)="Duplicate(form.id)">
							<mat-icon>file_copy</mat-icon>
						</button>
						<button mat-button (click)="Export(form.id)">
							<mat-icon>save_alt</mat-icon>
						</button>
						<button mat-button (click)="Delete(form)">
							<mat-icon>delete</mat-icon>
						</button>
					</mat-cell>
				</ng-container>
				<!-- <ng-container matColumnDef="active">
					<mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
						{{ 'Forms.Active' | translate }}</mat-header-cell>
					<mat-cell *matCellDef="let form" fxHide fxShow.gt-xs>
						<mat-icon *ngIf="form.active" class="active-icon green-600 s-16">check</mat-icon>
						<mat-icon *ngIf="!form.active" class="active-icon red-500 s-16">close</mat-icon>
					</mat-cell>
				</ng-container> -->

				<mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

				<mat-row *matRowDef="let form; columns: displayedColumns;" class="product" matRipple>
				</mat-row>

			</mat-table>
			<mat-paginator #paginator [length]="dataSource.totalDataCount" [pageIndex]="0" [pageSize]="10"
				[pageSizeOptions]="[5, 10, 25, 100]">
			</mat-paginator>
		</div>
		<!-- / CONTENT CARD -->
	</div>
	<!-- / CENTER -->
</div>