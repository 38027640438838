import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
	AbstractControl,
	UntypedFormBuilder,
	UntypedFormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute } from '@angular/router';
import { UserAccountService } from 'app/services/user-account.service';
import { AuthService } from 'app/services/app/auth.service';
import { ResetPasswordModel } from 'app/models/user/reset-password.model';
import { validationContext } from 'app/services/helpers/validation.helper';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';
import { AppSettings } from 'app/app.settings';

@Component({
	selector: 'reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations,
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
	resetPasswordForm: UntypedFormGroup;
	resetModel: ResetPasswordModel;

	get appSettings() {
		return AppSettings;
	}

	// Private
	private _unsubscribeAll: Subject<any>;

	constructor(
		private _fuseConfigService: FuseConfigService,
		private _formBuilder: UntypedFormBuilder,
		private accountService: UserAccountService,
		private route: ActivatedRoute,
		private authService: AuthService,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
	) {
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
		
		// Configure the layout
		this._fuseConfigService.config = {
			layout: {
				navbar: {
					hidden: true,
				},
				toolbar: {
					hidden: true,
				},
				footer: {
					hidden: true,
				},
				sidepanel: {
					hidden: true,
				},
			},
		};
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void {
		const token = this.route.snapshot.paramMap.get('token');
		this.accountService.getResetModel(token).subscribe(
			res => {
				if (res.Success) {
					this.resetModel = new ResetPasswordModel(res.Result);
					this.resetPasswordForm.patchValue(this.resetModel);
				}
			},
			error => { },
		);
		this.resetPasswordForm = this._formBuilder.group({
			userId: [{ value: '', disabled: true }, Validators.required],
			email: [
				{ value: '', disabled: true },
				[Validators.required, Validators.email],
			],
			password: [
				'',
				[
					Validators.pattern(validationContext.passwordPattern),
					Validators.required,
				],
			],
			passwordConfirm: [
				'',
				[Validators.required, confirmPasswordValidator],
			],
		});

		this.resetPasswordForm
			.get('password')
			.valueChanges.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(() => {
				this.resetPasswordForm
					.get('passwordConfirm')
					.updateValueAndValidity();
			});
	}
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
	submit(): void {
		this.resetModel.UpdateValues(this.resetPasswordForm.value);
		this.accountService.sendResetModel(this.resetModel).subscribe(
			res => {
				if (res.Success) {
					this.authService.LoginResponseHandler.bind(
						this.authService,
						res.Result,
					)();
				}
			},
			error => {
			},
		);
	}
}

export const confirmPasswordValidator: ValidatorFn = (
	control: AbstractControl,
): ValidationErrors | null => {
	if (!control.parent || !control) {
		return null;
	}

	const password = control.parent.get('password');
	const passwordConfirm = control.parent.get('passwordConfirm');

	if (!password || !passwordConfirm) {
		return null;
	}

	if (passwordConfirm.value === '') {
		return null;
	}

	if (password.value === passwordConfirm.value) {
		return null;
	}

	return { passwordsNotMatching: true };
};
