export const locale = {
	lang: 'da',
	data: {
		'Headers':{
			'Home': 'Hjem',
			'WelcomeBack': 'Velkommen tilbage',
		},
		'Api': {
			'Today': 'I dag',

			'#Users': '# Brugere',
			'NumberOfAllUsersForThisCompnay.': 'Antal brugere i denne virksomhed.',
			'User': 'bruger',

			'#Forms': '# Blanketter',
			'NumberOfAllFormsForThisCompnay.': 'Antal blanketter i denne virksomhed.',
			'Form': 'form',

			'#Orders': '# Oprettede blanketter',
			'#CompletedOrders': '# Underskrevede blanketter',
			'NumberOfAllOrdersForThisCompnay.': 'Antal af oprettede ordre i hele virksomheden.',
			'NumberOfAllCompletedOrdersForThisCompnay.': 'Antal underskrevede ordre i hele virksomheden.',
			'Order': 'Blanketordre',
			'CompletedOrder': 'Underskrevede blanketter',

			'OrdersDetails': 'Ordre detaljer',
			'ThisMonth': 'Denne måned',
			'LastMonth': 'Sidste måned',
			'2Months': '2 måneder',
		}
	}
};
