<mat-toolbar>

	<div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>

		<a href="https:luftborn.com" target="_blank" mat-button class="orange" fxFlex="0 0 auto" fxLayout="row"
			fxLayoutAlign="start center">
			<mat-icon class="s-16 mr-sm-4">developer_mode</mat-icon>
			<span>Developed by LUFTBORN</span>
		</a>

		<!-- <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
			<a mat-button routerLink="/documentation/getting-started/introduction">Documentation</a>
			<span>&bull;</span>
			<a mat-button routerLink="/documentation/changelog">Changelog</a>
		</div> -->

	</div>

</mat-toolbar>