<form class="mat-card mat-elevation-z4 p-24 " fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="form"
	[formGroup]="companyFormGroup" #companyForm="ngForm" (ngSubmit)="onSubmit(companyForm)">
	<input type="hidden" formControlName="id">
	<div class="h2 mb-24">{{ 'MyCompany.EditCompanyInfo' | translate }}</div>
	<div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
		<mat-form-field appearance="outline" fxFlex="50" class="pr-4">
			<mat-label>{{ 'MyCompany.Name' | translate }}</mat-label>
			<input matInput formControlName="name" required>
			<mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
			<mat-error *ngIf="companyFormGroup.hasError('required','name')">{{ 'MyCompany.NameRequired' | translate }}!
			</mat-error>
			<mat-error *ngIf="companyFormGroup.hasError('notUnique','name')">{{ 'MyCompany.NameTaken' | translate }}
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" fxFlex="50" class="pl-4">
			<mat-label>{{ 'MyCompany.Website' | translate }}</mat-label>
			<input matInput formControlName="website">
			<mat-icon matSuffix class="secondary-text">web</mat-icon>
			<mat-error>{{ 'MyCompany.WebsiteRequired' | translate }}!</mat-error>
		</mat-form-field>

	</div>
	<div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
		<mat-form-field appearance="outline" fxFlex="50" class="pr-4">
			<mat-label>{{ 'MyCompany.Phone' | translate }}</mat-label>
			<input matInput formControlName="phone" customTelNumber>
			<mat-icon matSuffix class="secondary-text">location_phone</mat-icon>
			<mat-error>{{ 'MyCompany.PhoneRequired' | translate }}!</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline" fxFlex="50" class="pl-4">
			<mat-label>{{ 'MyCompany.Email' | translate }}</mat-label>
			<input matInput formControlName="email">
			<mat-icon matSuffix class="secondary-text">location_mail</mat-icon>
			<mat-error *ngIf="companyFormGroup.hasError('notUnique','email')">{{ 'MyCompany.EmailTaken' | translate }}
			</mat-error>
		</mat-form-field>
	</div>
	<div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
		<mat-form-field appearance="outline" fxFlex="50" class="pr-4">
			<mat-label>{{ 'MyCompany.ApiKey' | translate }}</mat-label>
			<input matInput formControlName="apiKey" required>
			<mat-icon matSuffix class="secondary-text">api</mat-icon>
			<mat-error *ngIf="companyFormGroup.hasError('eSignCred','apiKey')">
				{{ 'MyCompany.ApiKeyInvalid' | translate }}</mat-error>
			<mat-error *ngIf="companyFormGroup.hasError('required','apiKey')">
				{{ 'MyCompany.ApiKeyRequired' | translate }}!</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline" fxFlex="50" class="pr-4">
			<mat-label>{{ 'MyCompany.Language' | translate }}</mat-label>
			<mat-select formControlName="langCode">
				<mat-option [value]="LanguagesEnum.en">
					{{ 'MyCompany.English' | translate }}
				</mat-option>
				<mat-option [value]="LanguagesEnum.da">
					{{ 'MyCompany.Danish' | translate }}
				</mat-option>
			</mat-select>
			<mat-icon matSuffix class="secondary-text">language</mat-icon>
		</mat-form-field>
	</div>
	<div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
		<mat-form-field appearance="outline" fxFlex="100" class="pr-4">
			<mat-label>{{ 'MyCompany.Address' | translate }}</mat-label>
			<input matInput formControlName="address">
			<mat-icon matSuffix class="secondary-text">location_address</mat-icon>
			<mat-error>{{ 'MyCompany.AddressRequired' | translate }}!</mat-error>
		</mat-form-field>
	</div>
	<div fxLayout="row-box" fxLayoutAlign="start center" fxFlex="1 0 auto">
		<mat-slide-toggle color="primary" formControlName="secureUploadEnabled">
			{{ 'MyCompany.EnableSecureUpload' | translate }}
		</mat-slide-toggle>
	</div>
	<div fxLayout="row" fxLayoutAlign="center center">
		<button mat-raised-button type="submit" color="primary">
			{{ 'MyCompany.SaveChanges' | translate }}
		</button>
	</div>
</form>