import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MakeRequestService } from './make-request.service';
import { LoginModel } from '../../models/login.model';
import { LoginResponseModel } from '../../models/login-response.model';
import { LocalStorageHelper } from '../helpers/local-storage.helper';
import { Observable } from 'rxjs';
import { ResultModel } from 'app/models/result-model';
import { AccessTokenModel } from 'app/models/access-token.model';
import { User } from 'app/models/user.model';
import { Company } from 'app/models/company.model';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	public redirectUrl = '/';
	baseModuleUrl = 'Auth/';
	constructor(
		private makeRequest: MakeRequestService,
		private router: Router,
		private localStorageHelper: LocalStorageHelper,
	) { }

	public Login(cred: LoginModel, callback: (res: ResultModel<any>) => void): void {
		this.makeRequest
			.post<LoginResponseModel>(`${this.baseModuleUrl}login`, cred)
			.subscribe(res => {
				this.LoginResponseHandler.bind(this, res.Result)();
				callback(res);
			});
	}

	public ExternalLogin(token: string, callback: (res: ResultModel<any>) => void): void {
		this.makeRequest
			.post<LoginResponseModel>(`${this.baseModuleUrl}ExternalLogin/${token}`, {})
			.subscribe(res => {
				this.LoginResponseHandler.bind(this, res.Result)();
				if (callback) {
					callback(res);
				}
			});
	}

	public Logout(): void {
		this.makeRequest
			.post<any>(`${this.baseModuleUrl}logout`, {})
			.subscribe(this.logoutHandler.bind(this));
	}

	public ForgetPassword(email: string): Observable<ResultModel<any>> {
		return this.makeRequest.getWithoutRetries<any>(
			`${this.baseModuleUrl}forgetPassword/${email}`,
		);
	}

	public isLoggedIn(): boolean {
		const token = this.localStorageHelper.Get<AccessTokenModel>(
			LocalStorageHelper.accessTokenFieldName,
		);
		if (token != null) {
			const expireDate = new Date(token.expireDate);
			return expireDate >= new Date();
		} else {
			return false;
		}
	}

	public LoginResponseHandler(response: LoginResponseModel): void {
		if (response && response.success) {
			this.localStorageHelper.Set(
				LocalStorageHelper.accessTokenFieldName,
				response.accessToken,
			);
			this.localStorageHelper.Set(
				LocalStorageHelper.currentUserFieldName,
				response.user,
			);
			this.router.navigate([this.redirectUrl]);
		}
	}

	private logoutHandler(response: any): void {
		if (this.isLoggedIn()) {
			this.localStorageHelper.Clear(
				LocalStorageHelper.accessTokenFieldName,
			);
			this.localStorageHelper.Clear(
				LocalStorageHelper.currentUserFieldName,
			);
		}
		this.router.navigate(['auth/login']);
	}

	public getCurrentUser(): User {
		const currentUser = this.localStorageHelper.Get<User>(
			LocalStorageHelper.currentUserFieldName,
		);
		return currentUser
	}
	public getCurrentUserCompany(): Company {
		const currentUser = this.getCurrentUser();
		const currentUserCompany = currentUser.company;
		return currentUserCompany;
	}

	public isCurrentUserSuperAdmin(): boolean {
		const currentUser = this.getCurrentUser();
		if (!currentUser) {
			return false;
		}
		return currentUser.type === 'SuperAdmin';
	}
}
