import { NgModule, forwardRef, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { MatSharedModule } from 'app/shared-modules/mat-shared/mat-shared.module';
import { FormsComponent } from './forms/forms.component';
import { FormService } from 'app/services/form.service';
import { FormComponent } from './form/form.component';
import { FormsModule } from '@angular/forms';
import { EditFieldItemComponent } from './edit-field-item/edit-field-item.component';
import { SanitizeHtmlPipe } from 'app/pipes/safe-html.pipe';
import { FormPreviewComponent } from './form-preview/form-preview.component';
import { RenderFieldItem } from 'app/pipes/render-field-item.pipe';
import { BrowserModule } from '@angular/platform-browser';
import { EditDependencyComponent } from './edit-dependency/edit-dependency.component';
import { LuftbornModule } from 'app/shared-modules/luftborn/luftborn.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorTranslation } from 'app/utilities/PaginatorTranslation/PaginatorTranslation';
import { EditOptionComponent } from './edit-option/edit-option.component';
import { WyswygEditorModule } from 'app/shared-modules/wyswyg-editor/wyswyg-editor.module';
import { SecureUploadFormComponent } from './secure-upload-form/secure-upload-form.component';
import { SecureUploadFormsComponent } from './secure-upload-forms/secure-upload-forms.component';
import { SecureUploadService } from 'app/services/secure-upload.service';
import { SecureUploadInboxComponent } from './secure-upload-inbox/secure-upload-inbox.component';
import { SecureUploadInboxService } from 'app/services/secure-upload-inbox.service';
import { SecureUploadSubmissionDetailsComponent } from './secure-upload-inbox/secure-upload-submission-details/secure-upload-submission-details.component';
import { ExportInboxDataComponent } from './secure-upload-inbox/export-inbox-data/export-inbox-data.component';
import { AuthGuard } from 'app/guards/auth.guard';

const routes: Routes = [
	{
		path: 'forms',
		component: FormsComponent,
		resolve: {
			data: FormService,
		},
	},
	{
		path: 'forms/:id',
		component: FormComponent,
		resolve: {
			data: FormService,
		},
	},
	{
		path: 'forms/:id/:name',
		component: FormComponent,
		resolve: {
			data: FormService,
		},
	},
	{
		path: 'secure-upload',
		component: SecureUploadFormsComponent,
		canActivate: [AuthGuard],
		resolve: {
			data: SecureUploadService,
		},
	},
	{
		path: 'secure-upload/:id',
		component: SecureUploadFormComponent,
		canActivate: [AuthGuard],
		resolve: {
			data: SecureUploadService,
		},
	},
	{
		path: 'secure-upload-inbox',
		component: SecureUploadInboxComponent,
		canActivate: [AuthGuard],
		resolve: {
			data: SecureUploadInboxService,
		},
	}
];

@NgModule({
	declarations: [
		FormsComponent,
		FormComponent,
		SecureUploadInboxComponent,
		SecureUploadFormsComponent,
		SecureUploadFormComponent,
		EditFieldItemComponent,
		SanitizeHtmlPipe,
		FormPreviewComponent,
		RenderFieldItem,
		EditDependencyComponent,
		EditOptionComponent,
		SecureUploadSubmissionDetailsComponent,
		ExportInboxDataComponent
	],
	imports: [
		RouterModule.forChild(routes),
		NgxChartsModule,
		TranslateModule,
		BrowserModule,
		FuseSharedModule,
		FuseWidgetModule,
		MatSharedModule,
		FormsModule,
		LuftbornModule,
		WyswygEditorModule
	],
	providers: [
		{ provide: MatPaginatorIntl, useClass: forwardRef(() => PaginatorTranslation) }
	],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	]
})
export class FormBuilderModule { }
