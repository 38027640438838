export class ResetPasswordModel {
	public userId: string;
	public email: string;
	public password: string;
	public confirmPassword: string;

	public constructor(init?: Partial<ResetPasswordModel>) {
		Object.assign(this, init);
	}

	public UpdateValues(values?: Partial<ResetPasswordModel>): void {
		Object.keys(values).forEach(key => {
			if (this.hasOwnProperty(key)) {
				this[key] = values[key];
			}
		});
	}
}
