import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MakeRequestService } from './app/make-request.service';
import { Company } from '../models/company.model';
import { ResultModel } from '../models/result-model';
import { LocalStorageHelper } from './helpers/local-storage.helper';
import { AuthService } from './app/auth.service';

@Injectable({
	providedIn: 'root',
})
export class CompanyService {
	baseModuleUrl = 'company/';
	currentCompanyLocalStorageName = 'currentCompanyBeingOperatedBySuperAdmin';

	constructor(
		private makeRequest: MakeRequestService,
		private router: Router,
		private localStorageHelper: LocalStorageHelper,
		private authService: AuthService,
	) { }

	public get(id: string): Observable<ResultModel<Company>> {
		return this.makeRequest.get<Company>(`${this.baseModuleUrl}${id}`);
	}
	public getMyCompany(): Observable<ResultModel<Company>> {
		return this.makeRequest.get<Company>(`${this.baseModuleUrl}GetMyCompany`);
	}

	public getAll(): Observable<ResultModel<Array<Company>>> {
		return this.makeRequest.get<Company[]>(`${this.baseModuleUrl}`);
	}

	public Update(company: Company): Observable<ResultModel<Company>> {
		return this.makeRequest.put<Company>(
			`${this.baseModuleUrl}${company.id}`,
			company,
		);
	}

	public Add(company: Company): Observable<ResultModel<Company>> {
		return this.makeRequest.post<Company>(`${this.baseModuleUrl}`, company);
	}

	public Delete(id: string): Observable<ResultModel<Company>> {
		return this.makeRequest.delete<Company>(`${this.baseModuleUrl}${id}`);
	}

	set currentOperatedCompany(company: Company) {
		if (!this.authService.isCurrentUserSuperAdmin()) {
			return;
		}
		this.localStorageHelper.Set(this.currentCompanyLocalStorageName, company);
	}
	get currentOperatedCompany(): Company {
		if (!this.authService.isCurrentUserSuperAdmin()) {
			return null;
		}
		let currentCompany = this.localStorageHelper.Get<Company>(this.currentCompanyLocalStorageName);

		if (!currentCompany || !currentCompany.id) {
			currentCompany = this.authService.getCurrentUserCompany();
		}
		return currentCompany;
	}
}
