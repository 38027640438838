export default class UrlUtility {

	static beautifyUrlComponent(urlComponent: string): string {
		if (!urlComponent) {
			return;
		}
		urlComponent = urlComponent.toLowerCase();
		urlComponent = urlComponent.replace(new RegExp(' ', 'g'), '-');
		urlComponent = urlComponent.replace(new RegExp('/', 'g'), '-');
		return urlComponent;
	}
}
