import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MakeRequestService } from './app/make-request.service';
import { ResultModel } from '../models/result-model';
import ExportFormsDataRequest from 'app/models/form-submission/export-forms-data-request.model';
import ExportFormsDataResponse from 'app/models/form-submission/export-forms-data-response.model';

@Injectable({
	providedIn: 'root',
})
export class FormSubmissionService {
	baseModuleUrl = 'formsubmission/';
	constructor(private makeRequest: MakeRequestService) { }

	public getFormSubmissionFileNames(
		formSubmissionId: string
	): Observable<ResultModel<{ [key: string]: string }>> {
		const urlPart = 'uploadedfilenames?formSubmissionId=';
		formSubmissionId = encodeURIComponent(formSubmissionId);
		return this.makeRequest.getWithoutRetries<{ [key: string]: string }>(
			`${this.baseModuleUrl}${urlPart}${formSubmissionId}`
		);
	}

	public downloadFile(
		formSubmissionId: string,
		fileIndex: number
	): Observable<Blob> {
		formSubmissionId = encodeURIComponent(formSubmissionId);
		return this.makeRequest.getBlob(
			`${this.baseModuleUrl}DownloadFile?formSubmissionId=${formSubmissionId}&fileIndex=${fileIndex}`
		);
	}

	public exportData(exportRequest: ExportFormsDataRequest): Observable<ResultModel<ExportFormsDataResponse[]>> {
		return this.makeRequest.post<ExportFormsDataResponse[]>(
			`${this.baseModuleUrl}ExportData`, exportRequest
		);
	}
}
