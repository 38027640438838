export const locale = {
	lang: 'da',
	data: {
		NAV: {
			APPLICATIONS: 'Funktioner',
			USER: {
				TITLE: 'Bruger',
			},
			COMPANY: {
				TITLE: 'Virksomhed',
				MyCOMPANY: {
					TITLE: 'Min virksomhed',
				},
				COMPANIES: {
					TITLE: 'Virksomheder',
				},
			},
			LOGOUT: 'Log ud',
			FORMS: 'Blanketter',
			ORDERS: 'Ordrer',
			Dashboard: 'Oversigt',
			SECUREUPLOAD: 'Sikker upload',
		},
	},
};
