<quill-editor #quilleditor sanatize="true" placeholder="" [styles]="{height: '100px', fontSize: '12px'}" [preserveWhitespace]="true">
	<div quill-editor-toolbar>
		<span class="ql-formats">
			<select class="ql-font">
				<option value="sansserif" selected>Sans Serif</option>
				<option value="serif">Serif</option>
				<option value="monospace">Monospace</option>
				<option value="roboto">Roboto</option>
				<option value="arial">Arial</option>
				<option value="calibri">Calibri</option>
				<option value="verdana">Verdana</option>
				<option value="helvetica">Helvetica</option>
			</select>
			<select class="ql-size">
				<option value="12px" selected>12px</option>
				<option value="14px">14px</option>
				<option value="16px">16px</option>
				<option value="18px">18px</option>
			</select>
		</span>
		<span class="ql-formats">
			<button class="ql-bold"></button>
			<button class="ql-italic"></button>
			<button class="ql-underline"></button>
			<button class="ql-strike"></button>
		</span>
		<span class="ql-formats">
			<select class="ql-color"></select>
			<select class="ql-background"></select>
		</span>
		<span class="ql-formats">
			<button class="ql-list" value="ordered"></button>
			<button class="ql-list" value="bullet"></button>
			<select class="ql-align">
				<option selected></option>
				<option value="center"></option>
				<option value="right"></option>
				<option value="justify"></option>
			</select>
			<button class="ql-indent" value="-1" type="button" tabindex="-1"></button>
			<button class="ql-indent" value="+1" type="button" tabindex="-1"></button>
		</span>
		<span class="ql-formats">
			<button class="ql-link"></button>
			<button class="ql-image"></button>
		</span>
	</div>
</quill-editor>