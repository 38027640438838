import { Component, OnInit } from '@angular/core';
import {
	Validators,
	UntypedFormBuilder,
	UntypedFormGroup,
	FormGroupDirective,
} from '@angular/forms';
import { CompanyService } from 'app/services/company.service';
import { Company } from 'app/models/company.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ResultModel } from 'app/models/result-model';
import { ValidationHelper } from 'app/services/helpers/validation.helper';
import { SnackBarHelper } from '../../../services/helpers/snack-bar.helper';

//component.ts
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';
import { Languages } from 'app/models/Languages';
import { StaticValidation } from 'app/utilities/static-validation';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'my-company',
	templateUrl: './my-company.component.html',
	styleUrls: ['./my-company.component.scss'],
})
export class MyCompanyComponent implements OnInit {
	public LanguagesEnum = Languages;
	companyFormGroup: UntypedFormGroup;
	configSuccess: MatSnackBarConfig = {
		panelClass: 'style-success',
		duration: 3000,
		horizontalPosition: 'center',
		verticalPosition: 'top',
	};
	constructor(
		public snackBar: MatSnackBar,
		private companyService: CompanyService,
		private formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		public snackBarHelper: SnackBarHelper,
		private translate: TranslateService,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
	) {
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
		this.translate.setTranslation('en', english, true);
		this.translate.setTranslation('da', danish, true);
	}

	private HandleEditResponse(res: ResultModel<any>): void {
		if (res.Success) {
			this.snackBarHelper.openSnackBar({
				message: `${this.translate.instant('TSvariables.Company')} (${
					this.companyFormGroup.value['name']
					}) ${this.translate.instant('TSvariables.EditedSuccessfully')}!`,
			});

			this.router.navigate(['company']);
			return;
		}
		if (res.ResponseCode === 400) {
			ValidationHelper.setFormErrors(res.errors, this.companyFormGroup);
			this.snackBarHelper.openSnackBar({
				message: `${this.translate.instant('TSvariables.SomethingWentWrong')}!`,
			});
			return;
		}
	}
	ngOnInit(): void {
		this.companyFormGroup = this.formBuilder.group({
			id: [''],
			name: ['', [Validators.required]],
			website: [''],
			email: ['', [Validators.required, Validators.email]],
			phone: ['', Validators.pattern(new StaticValidation().phoneRegex)],
			address: [''],
			langCode: [''],
			apiKey: ['', Validators.required],
			secureUploadEnabled: [false],
		});
		this.companyService.getMyCompany().subscribe(res => {
			this.companyFormGroup.patchValue(res.Result);
		});
	}

	onSubmit(form: FormGroupDirective): void {
		if (!this.companyFormGroup.valid) {
			return;
		}
		const updatedCompany = new Company(this.companyFormGroup.value);
		this.companyService.Update(updatedCompany).subscribe(res => {
			this.HandleEditResponse(res);
		});
	}
}
