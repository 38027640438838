import {
	Component,
	ElementRef,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/shared-modules/mat-shared/confirm-dialog/confirm-dialog.component';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';
import { takeUntil } from 'rxjs/operators';
import { OrderService } from 'app/services/order.service';
import { environment } from 'environments/environment';
import OrderModel from 'app/models/order.model';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';
import { OrderDetailsComponent } from "../order-details/OrderDetailsComponent";
import { TranslateService } from '@ngx-translate/core';
import { SnackBarHelper } from 'app/services/helpers/snack-bar.helper';
import { ExportFormsDataComponent } from '../export-forms-data/export-forms-data.component';
import { CompanyService } from 'app/services/company.service';
import { Company } from 'app/models/company.model';
import { AuthService } from 'app/services/app/auth.service';

@Component({
	selector: 'orders-list',
	templateUrl: './orders.component.html',
	styleUrls: ['./orders.component.scss'],
	animations: fuseAnimations,
	encapsulation: ViewEncapsulation.None,
})
export class OrdersComponent implements OnInit {
	dataSource: OrdersDataSource | null;
	displayedColumns = ['signerName', 'signerEmail', 'signedDate', 'relatedFormName', 'referenceID', 'actions'];
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	isSuperAdmin: boolean;
	isLoading: boolean = false;
	companies: Company[];
	companiesLoaded: boolean;
	currentOperatedCompany: Company;

	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	@ViewChild('filter', { static: true })
	filter: ElementRef;

	// Private
	private _unsubscribeAll: Subject<any>;

	constructor(
		private ordersService: OrderService,
		private companyService: CompanyService,
		private dialog: MatDialog,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
		private translate: TranslateService,
		public snackBarHelper: SnackBarHelper,
		private authService: AuthService,
	) {
		// Set the private defaults
		this._unsubscribeAll = new Subject();
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
	}
	ngOnInit(): void {
		this.isSuperAdmin = this.authService.isCurrentUserSuperAdmin();
		if (this.isSuperAdmin) {
			this.initCompanies();
		}
		
		this.dataSource = new OrdersDataSource(
			this.ordersService,
			this.paginator,
			this.sort,
		);

		this.ordersService.dataIsLoading.subscribe(isLoading => {
			this.isLoading = isLoading;
		});

		fromEvent(this.filter.nativeElement, 'keyup')
			.pipe(
				takeUntil(this._unsubscribeAll),
				debounceTime(150),
				distinctUntilChanged(),
			)
			.subscribe(() => {
				if (!this.dataSource) {
					return;
				}
				this.ordersService.searchParameters.searchValue = this.filter.nativeElement.value;
				this.ordersService.searchParameters.page = 1;
				this.paginator.pageIndex = 0;
				this.ordersService.searchParametersChanged.next(this.ordersService.searchParameters);
			});
	}
	
	initCompanies(): void {
		this.currentOperatedCompany = this.companyService.currentOperatedCompany;

		this.companyService.getAll()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(res => {
				this.companies = res.Result;
				const currentOperatedCompanyExists = this.companies.findIndex(
					company => company.id === this.currentOperatedCompany.id
				) !== -1;
				if (!currentOperatedCompanyExists) {
					this.currentOperatedCompany = this.authService.getCurrentUserCompany();
				}
				this.companiesLoaded = true;
			});
	}

	openDialog(order: OrderModel): void {
		const dialogRef: MatDialogRef<OrderDetailsComponent, OrderModel> = this.dialog.open(OrderDetailsComponent, {
			minWidth: '700px',
			minHeight: '500px',
			data: order
		});
	}

	Refresh() {
		//to refresh the table with the new data (workaround - consider seeking a better alternative)
		this.ordersService.searchParameters.searchValue = this.filter.nativeElement.value;
		this.ordersService.searchParametersChanged.next(this.ordersService.searchParameters);
	}

	Delete(order: OrderModel) {
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: {
				confirmMessage: `${this.translate.instant('TSvariables.ConfirmDelete')} ${order.referenceID}?`,
			},
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.dataSource.Delete(order).then(
					res => {
						this.Refresh();
						this.snackBarHelper.openSnackBar({
							message: this.translate.instant('TSvariables.DeletedSuccessfully'),
						});
					},
					rej => {
						if (rej === 404) {
							this.snackBarHelper.openSnackBar({
								message: this.translate.instant('TSvariables.AlreadyDeleted'),
							});
						}
						else {
							this.snackBarHelper.openSnackBar({
								message: this.translate.instant('TSvariables.AnErrorOccurred'),
							});
						}
					})
					.catch(err => this.snackBarHelper.openSnackBar({
						message: this.translate.instant('TSvariables.AnErrorOccurred'),
					})
				);
			}
		});
	}

	openExportDataDialogue() {
		this.dialog.open(ExportFormsDataComponent);
	}

	selectedCompanyChange(companyId: string): void {
		const filteredCompanies = this.companies.filter(company => company.id === companyId);
		if (!filteredCompanies || !filteredCompanies[0]) {
			return;
		}
		const newlySelectedCompany = filteredCompanies[0];
		this.companyService.currentOperatedCompany = newlySelectedCompany;
		this.currentOperatedCompany = newlySelectedCompany;
		this.ordersService.searchParameters.page = 1;
		this.ordersService.searchParametersChanged.next(this.ordersService.searchParameters);
	}
}

export class OrdersDataSource extends DataSource<any> {
	private _filterChange = new BehaviorSubject('');
	private _filteredDataChange = new BehaviorSubject<OrderModel[]>([]);
	public totalDataCount: number = 0;
	public isLoading: boolean = false;

	constructor(
		private ordersService: OrderService,
		private _matPaginator: MatPaginator,
		private _matSort: MatSort,
	) {
		super();
		this.filteredData = this.ordersService.orders;
		this.totalDataCount = this.ordersService.totalOrdersCount;
		this._matPaginator.pageIndex = this.ordersService.searchParameters.page - 1;
		this._matPaginator.pageSize = this.ordersService.searchParameters.size;
		this.ordersService.searchParametersChanged.next(this.ordersService.searchParameters);
	}

	connect(): Observable<any[]> {
		const displayDataChanges = [
			this.ordersService.onOrdersChanged,
			this._matPaginator.page,
			this._filterChange,
			this._matSort.sortChange,
		];

		this._matPaginator.page.subscribe(() => {
			this.ordersService.searchParameters.page = this._matPaginator.pageIndex + 1;
			this.ordersService.searchParameters.size = this._matPaginator.pageSize;
			this.ordersService.searchParametersChanged.next(this.ordersService.searchParameters);
		});

		this._matSort.sortChange.subscribe(() => {
			this.ordersService.searchParameters.sortValue = this._matSort.active;
			this.ordersService.searchParameters.sortAscending = this._matSort.direction === 'asc';
			this.ordersService.searchParametersChanged.next(this.ordersService.searchParameters);
		});

		return merge(...displayDataChanges).pipe(
			map(() => {
				// The data should already be filtered and sorted by the backend, no need to do it again here
				this.filteredData = this.ordersService.orders;
				this.totalDataCount = this.ordersService.totalOrdersCount;
				return this.filteredData;
			}),
		);
	}

	get filteredData(): OrderModel[] {
		return this._filteredDataChange.value;
	}

	set filteredData(value: OrderModel[]) {
		this._filteredDataChange.next(value);
	}

	// Filter
	get filter(): string {
		return this._filterChange.value;
	}

	set filter(filter: string) {
		this._filterChange.next(filter);
	}

	Delete(order: OrderModel): Promise<number> {
		return new Promise((resolve, reject) => {
			this.ordersService.Delete(encodeURIComponent(order.id))
				.subscribe(res => {
					if (res.Success) {
						const orderIndex = this.ordersService.orders.indexOf(order);
						if (orderIndex !== -1) {
							this.ordersService.orders.splice(orderIndex, 1);
							resolve(res.ResponseCode);
						}
					}
					reject(res.ResponseCode);
				});
		});
	}

	disconnect(): void { }
}
