import { SignatureTypes } from './SignatureTypes';

export class StaticSigner {
	public name: string;
	public email: string;
	public identification: string;
	public title: string;
	public onBehalfOf: string;
	public signatureType: SignatureTypes;
	public isStaticSigner: boolean;


	constructor(init?: Partial<StaticSigner>) {
		if (init) {
			Object.assign(this, init);
			this.isStaticSigner = true;
			return;
		}
		this.signatureType = SignatureTypes.MitId;
		this.isStaticSigner = true;
	}
}