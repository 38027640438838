export const locale = {
	lang: 'da',
	data: {
		'Headers': {
			'Name': 'Navn',
			'UserName': 'Brugernavn',
			'Email': 'E-mail',
			'Phone': 'telefon',
			'CompanyName': 'Virksomhedsnavn',
			'Actions': 'Handlinger',
			'Add': 'Tilføj ny bruger',
			'Type': 'Type',
			'Company': 'Selskab',
			'Filter': 'Filtrer',
			'Users': 'Brugere',
		},
		'Buttons': {
			'Back': 'Tilbage',
			'Validate': 'Godkend',
			'Save': 'Gem ændringer',
		},
		'Errors':{
			'Required': 'krævet!',
			'UserNameTaken': 'Brugernavn taget af en anden bruger',
			'EmailTaken': 'e-mail taget af en anden bruger',
			'EmailInvalid': 'e-mail er ikke gyldig!',
			'PhoneInvalid': 'telefon er ikke gyldig!',
		},
		'TSvariables': {
			'AddnewUser': 'Tilføj ny bruger',
			'EditUser': 'Rediger bruger',
			'Info': 'Info',
			'User': 'Bruger',
			'EditedSuccessfully': 'Redigeret med succes',
			'SomethingWentWrong': 'Der gik noget galt, prøv venligst igen',
			'AddedSuccessfully': 'Tilføjet med succes',
			'ValidUserData': 'Gyldige brugerdata',
			'InvalidUserData': 'Ugyldige brugerdata',
		}
	}
};
