import { Component, OnInit } from '@angular/core';
import {
	Validators,
	UntypedFormBuilder,
	UntypedFormGroup,
	FormGroupDirective,
} from '@angular/forms';
import { UserService } from 'app/services/user.service';
import { User } from 'app/models/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ResultModel } from 'app/models/result-model';
import { CompanyService } from 'app/services/company.service';
import { Company } from 'app/models/company.model';
import { LocalStorageHelper } from 'app/services/helpers/local-storage.helper';
import { AccessTokenModel } from 'app/models/access-token.model';
import { SnackBarHelper } from '../../../services/helpers/snack-bar.helper';
import { ValidationHelper } from 'app/services/helpers/validation.helper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../_i18n/en';
import { locale as danish } from '../_i18n/da';
import { TranslateService } from '@ngx-translate/core';
import { StaticValidation } from 'app/utilities/static-validation';

@Component({
	selector: 'app-edit-user',
	templateUrl: './edit.component.html',
	styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit {
	public userFormGroup: UntypedFormGroup;
	public title = '';
	public companies: Company[];

	CurrentUserRole: string;
	constructor(
		public snackBarHelper: SnackBarHelper,
		private userService: UserService,
		private companyService: CompanyService,
		private formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private localStorageHelper: LocalStorageHelper,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
		private translate: TranslateService,
	) {
		this._fuseTranslationLoaderService.loadTranslations(english, danish);
		this.translate.setTranslation('en', english, true);
		this.translate.setTranslation('da', danish, true);
	}

	private HandleEditResponse(res: ResultModel<any>): void {
		if (res.Success) {
			this.snackBarHelper.openSnackBar({
				message: `${this.translate.instant('TSvariables.User')} (${
					this.userFormGroup.value['name']
					}) ${this.translate.instant('TSvariables.EditedSuccessfully')}!`,
			});
			this.router.navigate(['user']);
			return;
		}
		if (res.ResponseCode === 400) {
			ValidationHelper.setFormErrors(res.errors, this.userFormGroup);
			this.snackBarHelper.openSnackBar({
				message: `${this.translate.instant('TSvariables.SomethingWentWrong')}!`,
			});
			return;
		}
	}

	private HandleAddResponse(
		res: ResultModel<any>,
		form: FormGroupDirective,
	): void {
		if (res.Success) {
			this.snackBarHelper.openSnackBar({
				message: `${this.translate.instant('TSvariables.User')} (${
					this.userFormGroup.value['name']
					}) ${this.translate.instant('TSvariables.AddedSuccessfully')}!`,
			});
			this.userFormGroup.reset();
			form.resetForm();
			return;
		}
		if (res.ResponseCode === 400) {
			ValidationHelper.setFormErrors(res.errors, this.userFormGroup);
			this.snackBarHelper.openSnackBar({
				message: `${this.translate.instant('TSvariables.SomethingWentWrong')}!`,
			});
			return;
		}
	}

	public ngOnInit(): void {
		this.CurrentUserRole = this.localStorageHelper.Get<AccessTokenModel>(
			LocalStorageHelper.accessTokenFieldName,
		).type;
		const id = this.route.snapshot.paramMap.get('id');
		this.userFormGroup = this.formBuilder.group({
			id: [''],
			companyId: [''],
			name: ['', [Validators.required]],
			userName: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			phone: ['', Validators.pattern(new StaticValidation().phoneRegex)],
			type: ['', Validators.required]
		});
		if (this.CurrentUserRole === 'SuperAdmin') {
			this.companyService.getAll().subscribe(res => {
				this.companies = res.Result;
			});
		}

		this.setTitle(id);

		//This is a workaround as without it, when changing the language, the variables doesn't get updated with the new translation
		this.translate.onLangChange.subscribe((event) => {
			this.setTitle(id);
		});

	}

	private setTitle(id: string): void {
		if (id) {
			this.userService.get(id).subscribe(res => {
				this.userFormGroup.patchValue(res.Result);
				this.title = `${this.translate.instant('TSvariables.EditUser')} (${
					this.userFormGroup.value['name']
					}) ${this.translate.instant('TSvariables.Info')}`;
			});
		} else {
			this.title = this.translate.instant('TSvariables.AddnewUser');
			this.userFormGroup.patchValue({});
		}
	}

	public validateUser(): void {
		const updatedUser = new User(this.userFormGroup.value);
		this.userService.Validate(updatedUser).subscribe(res => {
			if (res.Success) {
				this.snackBarHelper.openSnackBar({
					message: this.translate.instant('TSvariables.ValidUserData'),
				});
			}
			if (res.ResponseCode === 400) {
				ValidationHelper.setFormErrors(res.errors, this.userFormGroup);
				this.snackBarHelper.openSnackBar({
					message: `${this.translate.instant('TSvariables.InvalidUserData')}!`,
				});
			}
		});
	}

	public onSubmit(form: FormGroupDirective): void {
		if (!this.userFormGroup.valid) {
			return;
		}

		const updatedUser = new User(this.userFormGroup.value);
		if (!updatedUser.id) {
			this.userService.Add(updatedUser).subscribe(res => {
				this.HandleAddResponse(res, form);
			});
		} else {
			this.userService.Update(updatedUser).subscribe(res => {
				this.HandleEditResponse(res);
			});
		}
	}
}
