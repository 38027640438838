import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute } from '@angular/router';
import { ResultModel } from 'app/models/result-model';
import { AuthService } from 'app/services/app/auth.service';
import { SnackBarHelper } from 'app/services/helpers/snack-bar.helper';

@Component({
	selector: 'ext-login',
	templateUrl: './ext-login.component.html',
	styleUrls: ['./ext-login.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations
})

export class ExtLoginComponent implements OnInit {
	constructor(
		private _fuseConfigService: FuseConfigService,
		private route: ActivatedRoute,
		private authService: AuthService, public snackBarHelper: SnackBarHelper) {
		this._fuseConfigService.config = {
			layout: {
				navbar: {
					hidden: true,
				},
				toolbar: {
					hidden: true,
				},
				footer: {
					hidden: true,
				},
				sidepanel: {
					hidden: true,
				},
			},
		};
	}


	ngOnInit(): void {
		const token = this.route.snapshot.paramMap.get('token');
		const callback = (res: ResultModel<any>) => {
			if (!res.Success) {
				document.getElementById('error').innerText = res.error;
			}
		};
		this.authService.ExternalLogin(token, callback);
	}
}
