<div id="register" fxLayout="column">

    <div id="register-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="register-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img src="assets/images/logos/logo.png">
            </div>

            <div class="title">{{ 'Common.CreateAccount' | translate }}</div>

            <form name="registerForm" [formGroup]="registerForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'Common.Name' | translate }}</mat-label>
                    <input matInput formControlName="name">
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error>{{ 'Common.NameRequired' | translate }}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'Common.Email' | translate }}</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="registerForm.get('email').hasError('required')">{{ 'Common.EmailRequired' | translate }}</mat-error>
                    <mat-error *ngIf="registerForm.get('email').hasError('email')">{{ 'Common.EmailInvalid' | translate }}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'Common.Password' | translate }}</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        {{ 'Common.PasswordRequired' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'Common.PasswordConfirm' | translate }}</mat-label>
                    <input matInput type="password" formControlName="passwordConfirm">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="registerForm.get('passwordConfirm').hasError('required')">
                        {{ 'Common.PasswordConfirmRequired' | translate }}
                    </mat-error>
                    <mat-error *ngIf="!registerForm.get('passwordConfirm').hasError('required') &&
                                       registerForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                        {{ 'Common.PasswordsMustMatch' | translate }}
                    </mat-error>
                </mat-form-field>

                <div class="terms" fxLayout="row" fxLayoutAlign="center center">
                    <mat-checkbox name="terms" aria-label="I read and accept" required>
                            <span>{{ 'Common.IReadAndAccept' | translate }}</span>
                            <a href="#">{{ 'Common.TermsAndConditions' | translate }}</a>
                    </mat-checkbox>
                </div>

                <button mat-raised-button color="accent" class="submit-button" aria-label="CREATE AN ACCOUNT"
                    [disabled]="registerForm.invalid">
                    {{ 'Common.CreateAccount' | translate }}
                </button>

            </form>

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">{{ 'Common.AlreadyHaveAnAccount' | translate }}?</span>
                <a class="link" [routerLink]="'/pages/auth/login'">{{ 'Common.Login' | translate }}</a>
            </div>
            <div class="text-center">
                <br>
                <app-switch-language></app-switch-language>
            </div>

        </div>

    </div>

</div>