import { NgModule } from '@angular/core';
import { IndexComponent } from './index/index.component';
import { DashboardComponent } from './dashboard.component';
import { AuthGuard } from 'app/guards/auth.guard';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatSharedModule } from 'app/shared-modules/mat-shared/mat-shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LuftbornModule } from 'app/shared-modules/luftborn/luftborn.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FuseSidebarModule, FuseWidgetModule } from '@fuse/components';
import { DashboardService } from 'app/services/dashboard.service';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';

const routes = [
	{
		path: 'dashboard',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: 'index', component: IndexComponent },
			{ path: '**', component: IndexComponent },
		]
	}
];

@NgModule({
	declarations: [IndexComponent, DashboardComponent],
	imports: [
		RouterModule.forChild(routes),
		TranslateModule,
		FuseSharedModule,
		MatSharedModule,
		BrowserAnimationsModule,
		LuftbornModule,
		FlexLayoutModule,
		RouterModule.forChild(routes),
		MatButtonModule,
		MatDividerModule,
		MatFormFieldModule,
		MatIconModule,
		MatMenuModule,
		MatSelectModule,
		MatTableModule,
		MatTabsModule,
		NgxChartsModule,
		FuseSharedModule,
		FuseSidebarModule,
		FuseWidgetModule
	],
	exports: [DashboardComponent],
	providers: [
		DashboardService
	],
})

export class DashboardModule { }
