import { FieldGroupRow } from './field-group-row.model';

export class FieldGroup {
	public title: string;
	public description: string;
	public groupFooter: string;
	public dependentOn: string;
	public dependentValue: string;
	public rows: Array<FieldGroupRow>;
	constructor() {
		this.rows = new Array<FieldGroupRow>();
		this.rows.push(new FieldGroupRow());
	}
}
