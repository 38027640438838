import { Company } from './company.model';

export class User {
	public id: string;
	public name: string;
	public companyName: string;
	public userName: string;
	public type: string;
	public email: string;
	public phone: string;
	public senderEmail: string;
	public profilePictureUrl: string;
	public company: Company;

	public constructor(init?: Partial<User>) {
		Object.assign(this, init);
	}
}
